import { Avatar, Text, Flex, useColorMode, HStack, Icon, IconButton, Menu } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { formatDateTime } from '../../config/helpers/dateHelper';
import APP_ICONS from '../../config/constants/icons';
import { colorKeys, getColor } from '../../config/constants/appColors';

const FirstPersonMessage = ({ item, colorMode }) => {
    return (
        <Flex w="100%" justify="flex-end" align={"center"} >
            <Flex
                bg={getColor(colorKeys.lightGray, colorMode)}
                color={getColor(colorKeys.primaryText, colorMode)}
                minW="100px"
                maxW="350px"
                my="1"
                p="3"
                flexDir={"column"}
                rounded={"md"}
                role='group'
            >
                <Text>{item.message}</Text>
                <HStack spacing={4} justify={"end"}>
                    <Text fontSize="xs" color={getColor(colorKeys.primaryText, colorMode)}>
                        {formatDateTime(item.createdBy?.dateTime)}
                    </Text>
                    <Icon as={APP_ICONS.DOUBLE_CHECK} color={"blue.500"} />
                </HStack>
            </Flex>
        </Flex>
    )
}

const SecondPersonMessage = ({ item, colorMode }) => {
    return (
        <Flex w="100%" align={"center"}>
            <Avatar
                name={item?.createdBy?.name}
                src={item?.createdBy?.profilePicture}
                bg="blue.300"
            ></Avatar>
            <Flex
                rounded="md"
                ml={4}
                bg={getColor(colorKeys.layoutHeaderBackground, colorMode)}
                color={getColor(colorKeys.white, colorMode)}
                minW="100px"
                maxW="350px"
                my="1"
                p="3"
            >
                <Text>{item.message}</Text>
            </Flex>
        </Flex>
    )
}


const Messages = ({ messages }) => {
    const user = useSelector((state) => state.user?.user)
    const { colorMode } = useColorMode();
    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView());
        return <div ref={elementRef} />;
    };

    return (
        <Flex w="100%" h="calc(100vh - 250px)" overflowY="scroll" flexDirection="column" p="3">
            {messages?.map((item, index) => {
                if (item.createdBy?.id === user?.id) {
                    return (
                        <FirstPersonMessage key={index} item={item} colorMode={colorMode} />
                    );
                } else {
                    return (
                        <SecondPersonMessage key={index} item={item} colorMode={colorMode} />
                    );
                }
            })}
            <AlwaysScrollToBottom />
        </Flex>
    )
}

export default Messages