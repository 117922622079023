import { Badge } from '@chakra-ui/react'
import React from 'react'
import { STATUS } from '../../../config/constants/enums'

const StatusBadge = ({ value }) => {
    const status = value === STATUS.ACTIVE ? "Active" : "Inactive"
    const color = value === STATUS.ACTIVE ? "green" : "red"
    return (
        <Badge colorScheme={color}>{status}</Badge>
    )
}

export default StatusBadge