import React from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../../components/Forms/FormDrawer'
import { SimpleGrid } from '@chakra-ui/react'
import FormInput from '../../../../components/Forms/FormInput'
import { useCreateCourse, useUpdateCourse } from '../../../../config/query/courseQuery'
import { useQualifications } from "../../../../config/query/qualificationQuery"
import FormSwitch from '../../../../components/Forms/FormSwitch'
import FormSearchSelect from '../../../../components/Forms/FormSearchSelect'
import { STATUS } from '../../../../config/constants/enums'
import { useQueryClient } from '@tanstack/react-query'

const CourseForm = ({ disclosure, data }) => {
    const queryClient = useQueryClient()
    const createCourseQuery = useCreateCourse()
    const updateCourseQuery = useUpdateCourse(data?.id)

    const qualificationsQuery = useQualifications({
        pageNo: 1,
        pageSize: 20,
    })

    const {
        handleSubmit,
        control,
        reset: resetForm,
        formState: { errors },
    } = useForm({
        values: { ...data, status: (data?.id && data?.status === STATUS.INACTIVE) ? false : true },
    })

    const onSubmit = (values) => {
        let formMutate = data?.id ? updateCourseQuery.mutateAsync : createCourseQuery.mutateAsync
        formMutate({
            ...values,
            status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
        }).then(() => {
            resetForm({ status: true })
            queryClient.invalidateQueries(["courses"])
            disclosure.onClose()
        }).catch((error) => console.error(error))
    }

    return (
        <FormDrawer
            title={data?.id ? "Edit Course" : "Add Course"}
            disclosure={disclosure}
            isSubmitting={createCourseQuery.isLoading || updateCourseQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            containerProps={{ maxW: "50rem" }}
        >
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                <FormInput
                    label={"Name"}
                    control={control}
                    errors={errors}
                    id="name"
                    required={true}
                    placeholder="Enter course name"
                    rules={{
                        minLength: {
                            value: 3,
                            message: "Name must be at least 3 characters"
                        }
                    }}
                />

                <FormSearchSelect
                    id="qualificationId"
                    label="Qualification"
                    placeholder={'Select Qualification'}
                    required={true}
                    errors={errors}
                    control={control}
                    options={qualificationsQuery.data?.results?.map((qualification) => {
                        return {
                            label: qualification.name,
                            value: qualification.id
                        }
                    })}
                />

                <FormSwitch
                    label={"Status"}
                    control={control}
                    errors={errors}
                    id="status"
                />

            </SimpleGrid>

        </FormDrawer>
    )
}

export default CourseForm