import { Box, Flex, Heading } from '@chakra-ui/react'
import React from 'react'
import QuizArchiveList from './QuizArchiveList'

const QuizArchive = () => {

  return (
    <Box>
      <Flex justify="start" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">Quiz Archive</Heading>
      </Flex>
      <QuizArchiveList />
    </Box>
  )
}

export default QuizArchive