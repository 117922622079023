import React, { useEffect } from 'react'
import {
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContent,
  Flex,
  HStack,
  IconButton,
  Icon,
  useDisclosure,
  Tooltip
} from '@chakra-ui/react'
import FormButton from '../FormButton'
import APP_ICONS from '../../../config/constants/icons'

const FormDrawer = ({ title, disclosure, size = "2xl", id = "drawer-form", containerProps, children, isSubmitting, onSubmit, reset, maxW = "70rem", hideFooter, headerButtons, saveButtonText = "Save" }) => {
  const { isOpen, onClose } = disclosure
  const expandDisclosure = useDisclosure()

  useEffect(() => {
    if (!isOpen) {
      reset && reset()
    }
  }, [isOpen])

  return (
    <Modal
      size={expandDisclosure.isOpen ? "full" : size}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset='none'
    >
      <ModalOverlay />
      <ModalContent maxH={{ base: "unset", md: "calc(100vh - 50px)" }} overflow={"auto"} {...containerProps} rounded="sm" maxW={expandDisclosure.isOpen ? "full" : maxW}>
        <Box as="form" onSubmit={onSubmit}>

          <Flex align="center" justify="space-between">
            <ModalHeader minW="50%">{title}</ModalHeader>
            <HStack spacing={5} px={4}>
              <Tooltip label={expandDisclosure.isOpen ? "Collapse" : "Expand"} aria-label="A tooltip">
                <IconButton onClick={expandDisclosure.onToggle} variant={"ghost"} size={"sm"} aria-label="close" icon={<Icon as={APP_ICONS.EXPAND} />} />
              </Tooltip>
              <Tooltip label={"Close"} aria-label="A tooltip">
                <IconButton onClick={onClose} variant={"ghost"} size={"sm"} aria-label="Close" icon={<Icon as={APP_ICONS.CLOSE} />} />
              </Tooltip>
            </HStack>
          </Flex>

          <ModalBody>
            {children}
          </ModalBody>


          {!hideFooter && (
            <ModalFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <FormButton
                type='submit'
                form={id}
                isLoading={isSubmitting}
                onClick={onSubmit}>{saveButtonText}</FormButton>
            </ModalFooter>
          )}

        </Box>
      </ModalContent>
    </Modal>

  )
}

export default FormDrawer
