import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../components/Forms/FormDrawer'
import { SimpleGrid, Wrap, WrapItem, useColorMode } from '@chakra-ui/react'
import FormInput from '../../../components/Forms/FormInput'
import FormSwitch from '../../../components/Forms/FormSwitch'
import { useCreateChapter, useUpdateChapter } from '../../../config/query/chapterQuery'
import FormSearchSelect from '../../../components/Forms/FormSearchSelect'
import { useCohortOptions } from '../../../config/query/cohortQuery'
import { makeSelectList } from '../../../config/helpers/selectListHelper'
import { STATUS } from '../../../config/constants/enums'
import { useCohortVariantOptions } from '../../../config/query/cohortVariantsQuery'
import FormCheckbox from '../../../components/Forms/FormCheckbox'

const ChapterForm = ({ disclosure, data, cohortId }) => {

    const queryClient = useQueryClient()
    const cohortOptions = useCohortOptions({})

    const createChapterQuery = useCreateChapter()
    const updateChapterQuery = useUpdateChapter(data?.id)

    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        values: {
            ...data,
            status: (data?.id && data?.status === STATUS.INACTIVE) ? false : true,
        },
    })

    const cohortVariantsQuery = useCohortVariantOptions({
        cohorts: cohortId ? cohortId : watch("cohortId")
    })

    const onSubmit = (values) => {
        let formMutate = data?.id ? updateChapterQuery.mutateAsync : createChapterQuery.mutateAsync

        formMutate({
            ...values,
            status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
            cohortId: cohortId ? cohortId : values.cohortId
        })
            .then((response) => {
                reset()
                disclosure.onClose()
                queryClient.invalidateQueries(["chapters"])
            })
            .catch((error) => {
                console.error(`Error while creating chapter: ${error}`)
            })
    }

    console.log(data,watch("cohortVariantIds"),"khjahkjh")

    return (
        <FormDrawer
            title={data ? "Edit Chapter" : "Add Chapter"}
            disclosure={disclosure}
            isSubmitting={createChapterQuery.isLoading || updateChapterQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            containerProps={{ maxW: "70rem" }}
            reset={reset}

        >
            <SimpleGrid columns={{ base: 1, md: cohortId ? 1 : 2 }} spacing={5}>

                <FormInput
                    label={"Name"}
                    control={control}
                    errors={errors}
                    id="name"
                    required={true}
                    placeholder="Enter chapter name"
                    rules={{
                        minLength: {
                            value: 3,
                            message: "Name must be at least 3 characters"
                        }
                    }}
                />

                {!cohortId && (
                    <FormSearchSelect
                        id="cohortId"
                        label="Cohort"
                        placeholder={'Select Cohort'}
                        required={true}
                        errors={errors}
                        control={control}
                        options={makeSelectList(cohortOptions?.data)}
                    />
                )}

                <FormCheckbox
                    control={control}
                    id={"cohortVariantIds"}
                    defaultChecked={data?.cohortVariantIds}
                    label={"Select Variants"}
                    options={makeSelectList(cohortVariantsQuery.data)}
                    errors={errors}
                />

            </SimpleGrid>

            <Wrap mt={5}>

                <WrapItem>
                    <FormSwitch
                        label={"Is Public"}
                        control={control}
                        errors={errors}
                        id="isPublic"
                    />

                </WrapItem>

                <WrapItem>
                    <FormSwitch
                        label={"Is Status"}
                        control={control}
                        errors={errors}
                        id="status"
                    />
                </WrapItem>

            </Wrap>


        </FormDrawer >
    )
}

export default ChapterForm

