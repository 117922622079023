import { Box, Flex, Heading } from '@chakra-ui/react'
import React from 'react'
import ResultsList from './ResultsList'

const Results = () => {
  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">Results</Heading>
      </Flex>
      <ResultsList />
    </Box>
  )
}

export default Results