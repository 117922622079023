import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { appendQueryParams } from "../helpers/queryHelper";;

export const useQuizzes = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["quizs", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.EXAMS.base}?${appendQueryParams({params})}`,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useQuiz = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["quiz", id],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.EXAMS.base}/${id}`,
                token,
                toastError: true
            })
            return data
        },
    })
}

export const useQuizOptions = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["quizOptions", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.EXAMS.getOptions}?${appendQueryParams({params})}`,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useQuizzesFacet = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["quizsFacet"],
        queryFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.EXAMS.getFacet,
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useQuizQuestions = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["quizQuestions", id],
        queryFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.EXAMS.getQuestions.replace(":id", id),
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useQuizUpsertQuestions = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Put({
                path: API_CONSTANTS.EXAMS.upsertQuestions.replace(":id", id),
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return data
        }
    })
}

export const useCreateQuiz = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.EXAMS.dataKeys)
            const { data } = await Post({
                path: API_CONSTANTS.EXAMS.base,
                token,
                body
            })
            return data
        },
    })
}

export const useUpdateQuiz = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.EXAMS.dataKeys)
            const { data } = await Put({
                path: `${API_CONSTANTS.EXAMS.base}/${id}`,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}

export const useDeleteQuiz = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.EXAMS.base}/${id}`,
                token,
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}

export const useUpdateQuizStatus = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async ({ id, status }) => {
            const { data } = await Put({
                path: API_CONSTANTS.EXAMS.updateStatus.replace(":id", id),
                token,
                body: { status },
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}