import APP_ICONS from "./icons"

export const ALLOWED_FILE_TYPES = {
    DOCUMENT: ["docx", "doc", "pdf"],
    IMAGE: ["png", "jpg", "jpeg", "gif", "bmp"],
    VIDEO: ["mp4", "avi", "webm", "ogg", "vimeovideo"],
    AUDIO: ["mp3", "wav", "ogg"],
    FOLDER: ["folder"],
    ANY: ["jpg", "jpeg", "png", "gif", "mp4", "webm", "ogg", "docx", "doc", "pdf", "folder", "xml"],
}

export const ALLOWED_FILE_EXTENSIONS = {
    DOCUMENT: [".docx", ".doc", ".pdf"],
    IMAGE: [".png", ".jpg", ".jpeg", ".gif", ".bmp"],
    VIDEO: [".mp4", ".avi", ".webm", ".ogg"],
    AUDIO: [".mp3", ".wav", ".ogg"],
    ANY: [".jpg", ".jpeg", ".png", ".gif", ".mp4", ".webm", ".ogg", ".docx", ".doc", ".pdf", ".xml"],
}

export const MULTI_FILE_OBJECT = {
    type: 1,
    name: "",
    url: ""
}

export const getFileExtention = (file, withDot = true) => {
    let ext = file.name.split('.').pop()
    ext = ext.toLowerCase();
    if (withDot) {
        ext = "." + ext;
    }
    return ext;
}


export const getFileGroup = (fileExtension) => {
    if (ALLOWED_FILE_TYPES.IMAGE.includes(fileExtension)) {
        return "image";
    } else if (ALLOWED_FILE_TYPES.DOCUMENT.includes(fileExtension)) {
        return "document";
    } else if (ALLOWED_FILE_TYPES.VIDEO.includes(fileExtension)) {
        return "video";
    } else if (ALLOWED_FILE_TYPES.AUDIO.includes(fileExtension)) {
        return "audio";
    }
    else if (fileExtension === "folder") {
        return fileExtension;
    }
    else {
        return "unknown";
    }
}

export const getFileIcon = (fileGroup) => {
    let icon = APP_ICONS.UNKNOWN_FILE
    switch (fileGroup) {
        case 'image':
            icon = APP_ICONS.IMAGE_FILE
            break;
        case 'document':
            icon = APP_ICONS.UNKNOWN_FILE
            break;
        case 'video':
            icon = APP_ICONS.VIDEO_FILE
            break;
        case 'audio':
            icon = APP_ICONS.AUDIO_FILE
            break;
        case 'folder':
            icon = APP_ICONS.FOLDER
            break;
        default:
            break;
    }

    return icon
}

export const getExtensionsByFileGroup = (fileGroup, commaSeperated = true) => {
    switch (fileGroup) {
        case 'image':
            return commaSeperated ? ALLOWED_FILE_TYPES.IMAGE.join(', ') : ALLOWED_FILE_TYPES.IMAGE;
        case 'document':
            return commaSeperated ? ALLOWED_FILE_TYPES.DOCUMENT.join(', ') : ALLOWED_FILE_TYPES.DOCUMENT;
        case 'video':
            return commaSeperated ? ALLOWED_FILE_TYPES.VIDEO.join(', ') : ALLOWED_FILE_TYPES.VIDEO;
        case 'audio':
            return commaSeperated ? ALLOWED_FILE_TYPES.AUDIO.join(', ') : ALLOWED_FILE_TYPES.AUDIO;
        case 'folder':
            return commaSeperated ? ALLOWED_FILE_TYPES.FOLDER.join(', ') : ALLOWED_FILE_TYPES.FOLDER;
        default:
            return commaSeperated ? ALLOWED_FILE_TYPES.ANY.join(', ') : ALLOWED_FILE_TYPES.ANY;
    }
}