import React, { useState } from 'react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import { useDeleteEmailTemplate, useEmailTemplates, useEmailTemplatesFacet } from '../../../../config/query/emailTemplatesQuery'
import { formatDateTime, formatDateTimeFromNow } from '../../../../config/helpers/dateHelper'
import APP_ICONS from '../../../../config/constants/icons'
import { Badge } from '@chakra-ui/react'
import StatusBadge from '../../../../components/BasicUI/Badges/StatusBadge'
import Chronology from '../../../../components/BasicUI/DataBoxes/Chronology'
import { getFilters } from '../../../../config/helpers/filterHelper'
import TableInfoPopover from '../../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../../components/BasicUI/LabelValuePair'

const EmailTemplateList = ({ onEditModal }) => {

    const [query, setQuery] = useState({
        pageNo: 1,
        pageSize: 20,
    })

    const emailTemplatesQuery = useEmailTemplates(query)
    const emailTemplatesFacetQuery = useEmailTemplatesFacet()
    const deleteEmailTemplatesQuery = useDeleteEmailTemplate()

    const handleDelete = (id) => {
        deleteEmailTemplatesQuery.mutateAsync(id)
            .then((response) => {
                emailTemplatesQuery.refetch()
                console.log(`Email template deleted successfully: ${response}`)
            })
            .catch((error) => {
                console.log(`Error while deleting email template: ${error}`)
            })
    }


    return (
        <CustomTable
            containerProps={{ mt: 5 }}
            searchPlaceholder="Search email templates"
            filters={getFilters(emailTemplatesFacetQuery?.data)}
            head={[
                {
                    title: "Name",
                    extractor: "name",
                    align: "left",
                    component: (item) => (
                        <TableInfoPopover
                            data={item}
                            onEdit={(item) => onEditModal(item)}
                            onDelete={(id) => handleDelete(id)}
                            triggerOnClick={() => onEditModal(item)}
                        >
                            <LabelValuePair label="Created" value={formatDateTime(item.createdBy?.dateTime)} />
                            <LabelValuePair label="Last Updated" value={formatDateTime(item.updateBy?.dateTime)} />
                        </TableInfoPopover>
                    )
                },
                {
                    title: "Last Modified",
                    extractor: "lastModified",
                    component: (item) => <Chronology data={item} />
                },
                { title: "Actions", extractor: "actions" }
            ]}
            data={emailTemplatesQuery?.data?.results?.map((item) => {
                return {
                    ...item,
                    lastModified: item.updatedBy?.dateTime ? formatDateTimeFromNow(item.updatedBy?.dateTime) : formatDateTimeFromNow(item.createdBy?.dateTime),
                    actions: [
                        {
                            title: "Edit",
                            action: () => onEditModal(item),
                            icon: APP_ICONS.EDIT,
                            isEdit: true
                        },
                        {
                            title: "Delete",
                            action: handleDelete,
                            icon: APP_ICONS.BIN,
                            isDelete: true
                        }
                    ]
                }
            })}
            loading={emailTemplatesQuery?.isLoading}
            totalResults={emailTemplatesQuery?.data?.totalResults}
            totalPages={emailTemplatesQuery?.data?.totalPages}
            pageNo={query.pageNo}
            pageSize={query.pageSize}
            onQueryChange={(updatedQuery) => setQuery({ ...query, ...updatedQuery })}
            query={query}
            onRefresh={() => emailTemplatesQuery.refetch()}
            isRefreshing={emailTemplatesQuery?.isFetching}
        />
    )
}

export default EmailTemplateList