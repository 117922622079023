import React from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Input, Flex, Box, chakra, useColorMode, Progress, FormControl, FormLabel } from '@chakra-ui/react'
import FormDrawer from '../Forms/FormDrawer'
import FormFileUpload from '../Forms/FormFileUpload'
import { getColor, colorKeys } from '../../config/constants/appColors'
import { useCreateVideo } from '../../config/query/mediaQuery'
import { useQueryClient } from '@tanstack/react-query'

const VideoForm = ({ disclosure, parentId = null }) => {
    const { colorMode } = useColorMode()
    const queryClient = useQueryClient()
    const [fileData, setFileData] = React.useState({
        binary: null,
        link: ""
    })
    const [progress, setProgress] = React.useState(0)
    const createVideoQuery = useCreateVideo(setProgress)

    const onFileChange = (file) => {
        setFileData({
            binary: file,
            link: ""
        })
    }

    const onSubmit = () => {
        createVideoQuery.mutateAsync({
            link: fileData?.link,
            file: fileData?.binary,
            name: fileData?.binary?.name,
            parentId
        }).then((res) => {
            queryClient.invalidateQueries("media")
            disclosure?.onClose()
        }).catch((err) => {
            console.log(err, "error while uploading media")
        })
    }

    return (
        <FormDrawer
            title={"Upload Video"}
            disclosure={disclosure}
            isSubmitting={createVideoQuery.isLoading}
            onSubmit={onSubmit}
            containerProps={{ size: "sm" }}
            saveButtonText={"Upload"}
            onClose={disclosure.onClose}
            reset={() => setFileData({
                binary: null,
                link: ""
            })}
        >
            <Tabs isFitted defaultIndex={0}>
                <TabList>
                    <Tab>From Computer</Tab>
                    <Tab>From URL </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        {createVideoQuery.isLoading && (
                            <Progress my={5} hasStripe value={progress} />
                        )}
                        <FormFileUpload
                            id={"videoFormFile"}
                            onChange={onFileChange}
                            component={
                                <Box
                                    backgroundColor={getColor(colorKeys.lightBackgroundFill, colorMode)}
                                    w="100%"
                                    border={"2px dashed #E2E8F0"}
                                    padding="10px"
                                    h="125px"
                                >
                                    <Flex
                                        flexDir={"column"}
                                        justifyContent="center"
                                        cursor={"pointer"}
                                        h="full"
                                    >

                                        <Box p="15px" textAlign={"center"}>
                                            <chakra.p fontWeight={"normal"}>Click here to open media library</chakra.p>
                                            <chakra.p fontWeight={"normal"} whiteSpace={"break-spaces"} color={getColor(colorKeys.gray, colorMode)}>
                                                {fileData?.binary ? `${fileData?.binary?.name} selected` : "Pro Tip: You can select multiple files at once"}
                                            </chakra.p>
                                        </Box>
                                    </Flex>
                                </Box>
                            }
                            componentProps={{ variant: "ghost", w: "full", textAlign: "left", h: "fit-content" }}
                            fileType={"VIDEO"}
                        />
                    </TabPanel>
                    <TabPanel>
                        {createVideoQuery.isLoading && (
                            <Progress my={5} hasStripe value={progress} />
                        )}
                        <FormControl>
                            <FormLabel>
                                URL
                            </FormLabel>
                            <Input
                                placeholder="Enter URL"
                                onChange={(e) => setFileData({
                                    binary: null,
                                    link: e.target.value
                                })}
                            />
                        </FormControl>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </FormDrawer>
    )
}

export default VideoForm