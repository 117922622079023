import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { appendQueryParams } from "../helpers/queryHelper";;

export const useChats = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["chats", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.CHATS.base}?${appendQueryParams({params})}`,
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useChat = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["chat", id],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.CHATS.base}/${id}`,
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true,
        enabled: Boolean(id)
    })
}

export const useChatsFacet = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["chatsFacet"],
        queryFn: async () => {
            const {data} = await Get({
                path: API_CONSTANTS.CHATS.getFacet,
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useChatsOptions = (params = {}) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["chatsOptions", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.CHATS.getOptions}?${appendQueryParams({params})}`,
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useCreateChat = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.CHATS.dataKeys)
            const { data } = await Post({
                path: API_CONSTANTS.CHATS.base,
                token,
                body
            })
            return data
        },
    })
}

export const useUpdateChat = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.CHATS.dataKeys)
            const { data } = await Put({
                path: `${API_CONSTANTS.CHATS.base}/${id}`,
                token,
                body
            })
            return data
        },
    })
}

export const useDeleteChat = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.CHATS.base}/${id}`,
                token,
                toastError: true,
                toastMessage:true
            })
            return data
        },
    })
}