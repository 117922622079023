import { Box, useDisclosure, useColorMode } from '@chakra-ui/react';
import React from 'react'
import Sider from './Sider';
import Header from './Header';
import { Navigate, Outlet, useLocation, useParams } from 'react-router';
import { getColor, colorKeys } from '../../../config/constants/appColors';
import { useSelector } from 'react-redux';
import { DEFAULT_HIDDEN_SIDEBAR_PAGES, DEFAULT_BOX_BG_ROUTES } from '../../../config/constants';
import { checkIfSidebarShouldBeHidden } from '../../../config/constants/layoutHelpers';
import { useEffect } from 'react';

const AdminLayout = () => {
  const sidebar = useDisclosure();
  const [isSiderHidden, setIsSiderHidden] = React.useState(false)
  const { showSubsider } = useSelector((state) => state.setting)
  const { pathname } = useLocation()
  const token = useSelector((state) => state.user?.token)
  const { colorMode } = useColorMode()
  const params = useParams()

  useEffect(() => {
    setIsSiderHidden(checkIfSidebarShouldBeHidden(pathname, params))
  }, [pathname, params])


  if (!token) {
    return <Navigate to="/auth/login" replace={true} />
  }

  return (
    <Box
      as="section"
      minH="100vh"
    >
      <Sider disclosure={sidebar} />
      <Box
        ml={{
          base: 0,
          md: "80px",
        }}
        overflowX="hidden" //temp solution to hide horizontal scroll on main content component
        transition=".3s ease"
      >
        <Header disclosure={sidebar} />
        <Box
          as="main"
          p="4"
          mt="60px"
          ml={{
            base: 0,
            md: !showSubsider
              ? isSiderHidden
                ? "0"
                : "240px"
              : "0px",
          }}
          bg={DEFAULT_BOX_BG_ROUTES.includes(pathname)
            ? getColor(colorKeys.lightBackgroundFill, colorMode)
            : getColor(colorKeys.layoutBoxBackground, colorMode)}
          minH="calc(100vh - 60px)"
          maxW={{
            base: "100%",
            md: !showSubsider
              ? isSiderHidden
                ? "calc(100vw - 80px)"
                : "calc(100vw - 330px)"
              : "calc(100vw - 80px)",
          }}

        >
          <Outlet />
        </Box>
      </Box>
    </Box >
  )
}

export default AdminLayout