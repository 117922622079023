import React from 'react'
import { Box, Wrap, Text, WrapItem, Badge, HStack, IconButton, Icon, Flex } from '@chakra-ui/react'
import APP_ICONS from '../../../config/constants/icons'
import DeletePopover from '../DeletePopover'

const QuestionBox = ({ question, questionProps, answerProps, answersWrapperProps, containerProps, renderActions = true, onRemove, onEdit }) => {
    return (
        <Box border="1px solid #eaeaea" p="4" rounded={"sm"} {...containerProps}>
            <Flex justify="space-between" align="center">
                <Text fontSize="16px" {...questionProps}>{question?.name}</Text>
                {renderActions && (
                    <HStack spacing={2}>
                        <IconButton onClick={onEdit} colorScheme='green' size="xs" icon={<Icon as={APP_ICONS.EDIT} />} />
                        <DeletePopover
                            onConfirm={onRemove}
                            confirmScheme='red'
                            subject='delete'
                            type='question'
                        >
                            <IconButton colorScheme='red' size={"xs"} icon={<Icon as={APP_ICONS.BIN} />} />
                        </DeletePopover>
                    </HStack>
                )}
            </Flex>
            <Wrap spacing={5} mt={1} {...answersWrapperProps}>
                {question?.options?.map((item, index) =>
                    <WrapItem key={index}>
                        <Badge fontSize="12px" colorScheme={item.isCorrect ? 'green' : 'red'} {...answerProps}>{item.optionLabel}</Badge>
                    </WrapItem>
                )}

            </Wrap>
        </Box>
    )
}

export default QuestionBox