import { FormControl, chakra, FormErrorMessage, FormLabel, Icon, Input, InputGroup, InputRightElement, useColorMode } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import APP_ICONS from '../../../config/constants/icons'
import { getColor, colorKeys } from '../../../config/constants/appColors'
import { formatDate, formatDateTime } from '../../../config/helpers/dateHelper'
import { accessValue } from '../../../config/helpers/stringHelper'

const FormInput = ({ label, placeholder, id, required = false, errors = {}, control, rules, containerStyles, type, leftAddon, size = "md", secure, labelProps, labelComponent, inputProps, leftElement, rightElement, customError }) => {
  const [show, setShow] = useState(false)
  const { colorMode } = useColorMode()
  if (required) {
    required = `${label} is required`
  }

  return (
    <Controller
      control={control}
      name={id}
      rules={{
        required: required,
        ...rules
      }}
      render={({ field: { onChange, onBlur, value, ref, ...rest } }) => {
        switch (type) {
          case "date":
            if (value) {
              value = formatDate(value, true)
            }
            break
          case "datetime-local":
            if (value) {
              value = formatDateTime(value, true)
            }
            break
          default:
            break
        }
        return (
          <FormControl isInvalid={customError || errors[id] || accessValue(errors, `${id}.message`)} size={size} {...containerStyles}>
            <FormLabel htmlFor={id} {...labelProps}>
              {labelComponent ? labelComponent : (
                <>
                  {label}
                  {required && <chakra.span color={getColor(colorKeys.danger, colorMode)}>*</chakra.span>}
                </>
              )}

            </FormLabel>
            <InputGroup>
              {leftAddon}
              {leftElement}
              <Input
                value={type === "date" ? formatDate(value, true).toLocaleString() : value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                placeholder={placeholder}
                id={id}
                type={secure ? (show ? "text" : "password") : type}
                size={size}
                {...inputProps}
                {...rest}
              />
              {rightElement ? rightElement : secure ? (
                <InputRightElement h="full">
                  <Icon
                    as={show ? APP_ICONS.EYE : APP_ICONS.EYE_OUT}
                    boxSize={5}
                    onClick={() => setShow(!show)}
                  />
                </InputRightElement>
              ) : (
                <InputRightElement display={errors[id] ? "flex" : "none"} h="full">
                  <Icon as={APP_ICONS.WARNING} color={getColor(colorKeys.danger, colorMode)} boxSize={5} />
                </InputRightElement>
              )}
            </InputGroup>
            <FormErrorMessage>
              {/* {errors[id] && errors[id].message} */}
              {customError || accessValue(errors, `${id}.message`)}
            </FormErrorMessage>
          </FormControl>
        )
      }}
    />
  )
}

export default FormInput