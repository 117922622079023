import { DEFAULT_HIDDEN_SIDEBAR_PAGES } from "."

export const checkIfSidebarShouldBeHidden = (pathname, params) => {
    const routeList = Object.keys(DEFAULT_HIDDEN_SIDEBAR_PAGES).map((page, index) => {
        let value = DEFAULT_HIDDEN_SIDEBAR_PAGES[page]
        if (value.includes(":")) {
            let newRoute = value
            Object.keys(params).forEach((key) => {
                newRoute = newRoute.replace(`:${key}`, params[key])
            })
            return newRoute
        }
        return value
    })
    return routeList.includes(pathname)
}