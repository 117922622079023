import { Editable, EditableInput, EditablePreview, Flex, Icon, IconButton, Spinner, Tooltip, VStack, HStack, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { getFileGroup, getFileIcon } from '../../config/constants/fileHelper'
import { useUpdateMedia, useDeleteMedia } from '../../config/query/mediaQuery'
import APP_ICONS from '../../config/constants/icons'
import DeletePopover from '../BasicUI/DeletePopover'
import { useQueryClient } from '@tanstack/react-query'
import ModalPlayer from '../VideoComponents/ModalPlayer'

const focusStyles = {
    border: "1px solid #3182ce",
    borderRadius: "5px",
    boxShadow: "0 0 0 3px #3182ce",
    outline: "none"
}

const loadingStyles = {
    opacity: 0.5,
    cursor: "not-allowed",
    w: "100%",
    justifyContent: "center",
    alignItems: "center",
}

const MediaItem = ({ media, isSelected, ...rest }) => {
    const queryClient = useQueryClient()
    const [name, setName] = useState(media.name || "Untitled")
    const fileGroup = getFileGroup(media.extension)
    const icon = getFileIcon(fileGroup)
    const watchDisclosure = useDisclosure()
    const updateMediaQuery = useUpdateMedia(media.id)
    const deleteMediaQuery = useDeleteMedia(media.id)

    const handleUpdateMedia = (value) => {
        console.log({ value, name: media.name })
        if (value === media.name) return
        if (!value) {
            setName("Untitled")
            updateMediaQuery.mutate({
                name: "Untitled",
            })
            return
        }
        updateMediaQuery.mutate({
            name: value,
        })
    }

    const handleDeleteMedia = () => {
        deleteMediaQuery
            .mutateAsync()
            .then(() => {
                queryClient.invalidateQueries("media")
            })
            .catch((error) => console.error(error))
    }

    const Thumbnail = () => {
        if (fileGroup === "image" || media.extension === "vimeovideo") {
            return (
                <HStack align="start" spacing={2} style={deleteMediaQuery.isLoading ? loadingStyles : {}} justify={"end"} background={`url(${media?.thumbnailUrl || media?.blobFileUrl}) `} backgroundSize={"cover"} backgroundPosition={"center"} w="180px" h="180px" >
                    {deleteMediaQuery.isLoading && <Spinner thickness='4px' size="lg" />}
                    <IconButton onClick={watchDisclosure.onOpen} display={"none"} _groupHover={{ display: "initial" }} size="sm" colorScheme='blue' variant={"solid"} aria-label={"delete"} icon={<Icon as={fileGroup === "image" ? APP_ICONS.EYE : APP_ICONS.PLAY} boxSize="6" />} />
                    <DeletePopover onConfirm={handleDeleteMedia}>
                        <IconButton display={"none"} _groupHover={{ display: "initial" }} size="sm" colorScheme='red' variant={"solid"} aria-label={"delete"} icon={<Icon as={APP_ICONS.BIN} />} />
                    </DeletePopover>
                </HStack>
            )
        }
        else {
            return <Flex pos="relative" top="0" justify={"end"} w="180px" h="180px">
                <DeletePopover key={media?.id} onConfirm={handleDeleteMedia}>
                    <IconButton display={"none"} _groupHover={{ display: "initial" }} pos="absolute" size="sm" colorScheme='red' variant={"solid"} aria-label={"delete"} icon={<Icon as={APP_ICONS.BIN} />} />
                </DeletePopover>
                <Icon as={icon} w="180px" h="180px" />
            </Flex>
        }
    }

    return (
        <React.Fragment>
            <VStack
                spacing={2}
                align={"center"}
                flexDir={"column"}
                w="200px"
                p="12px 5px"
                cursor={"pointer"}
                _hover={{
                    ...focusStyles
                }}
                _focus={{
                    ...focusStyles
                }}
                // border={isSelected ? "1px solid #3182ce" : "1px solid #e2e8f0"}
                borderRadius={"5px"}
                // boxShadow={isSelected ? "0 0 0 3px #3182ce" : "none"}
                role="group"
                {...rest}
            >
                <Thumbnail />
                <Tooltip label="Click to rename" shouldWrapChildren={true}>
                    <Editable
                        value={name}
                        onChange={(value) => setName(value)}
                        onSubmit={(value) => handleUpdateMedia(value)}
                    >
                        <EditablePreview />
                        <EditableInput />
                    </Editable>
                </Tooltip>
            </VStack>
            <ModalPlayer type={fileGroup === "image" ? "IMAGE" : "VIDEO"} media={media} isOpen={watchDisclosure.isOpen} onClose={watchDisclosure.onClose} title={name} data={media} />
        </React.Fragment>
    )
}

export default MediaItem