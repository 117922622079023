import React, { memo } from 'react'
import { Link } from 'react-router-dom';
import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, Image, useColorMode } from '@chakra-ui/react';
import NavItem from './NavItem';
import { getColor, colorKeys } from "../../../config/constants/appColors"
import { siderMenu } from '../../../config/constants/menus';
import { useSelector } from 'react-redux';
import { USER_TYPES } from '../../../config/constants/enums';

function getPermission(permissionsArray, permissionsObject) {
    for (const permissionsItem of permissionsArray) {
        if (permissionsObject[permissionsItem]) {
            return true;
        }
    }
    return false;
}


const Sider = ({ disclosure }) => {
    const { colorMode } = useColorMode()
    const user = useSelector((state) => state.user.user)
    const permissionsMap = useSelector((state) => state.user.permissionsMap)

    const SidebarContent = (props) => (
        <Box
            as="nav"
            pos="fixed"
            top="0"
            left="0"
            zIndex="sticky"
            h="full"
            pb="10"
            overflowX="hidden"
            overflowY="auto"
            bg={getColor(colorKeys.dark, colorMode)}
            w="80px"
            {...props}
        >
            <Flex px="4" h={"60px"} align="center" bg={getColor(colorKeys.layoutHeaderBackground, colorMode)}>
                <Link to="/admin">
                    <Image
                        h="42px"
                        w="42px"
                        src="https://assets.easy-lms.com/ae061a63ecce6a0e67d6d9bf2c5ee596b626a85d/a/main/images/deadlyduck/logos/easy-lms-logo--knowly.svg"
                    />
                </Link>
            </Flex>
            <Flex
                mt={4}
                direction="column"
                as="nav"
                fontSize="sm"
                color="primaryText"
                aria-label="Main Navigation"
            >
                {siderMenu.map((item, index) => {
                    if (item.permissionKey) {
                        let hasPermission = false
                        // item.permissionKey.forEach((permission) => {
                        //     console.log(permissionsMap[permission],permission,"permissionsMap[permission]")
                        //     if (permissionsMap[permission]) {
                        //         hasPermission = true
                        //     }
                        // })

                        // not working
                        // hasPermission = permissionsMap[item.permissionKey.some((permission) => permissionsMap[permission])]
                        hasPermission = getPermission(item.permissionKey, permissionsMap)


                        console.log(hasPermission, "asdjasdasdasdsad")

                        if (hasPermission || user?.roleId === USER_TYPES.Admin || !item.permissionKey) {
                            return (
                                <NavItem
                                    key={index}
                                    icon={item.icon}
                                    title={item.title}
                                    link={item.link}
                                    subsider={item.subsider}
                                />
                            )
                        }
                    }
                    else {
                        return (
                            <NavItem
                                key={index}
                                icon={item.icon}
                                title={item.title}
                                link={item.link}
                                subsider={item.subsider}
                            />
                        )
                    }
                })}
            </Flex>

            {/* <Flex justifyContent={"center"}>
                <Box w="70%" borderTopWidth={"0.2px"} borderColor={getColor(colorKeys.smoke, colorMode)} />
            </Flex>

            <Flex
                alignItems={"center"}
                justifyContent="space-between"
                mt={4}
                direction="column"
                as="nav"
                fontSize="sm"
                color="primaryText"
                aria-label="Main Navigation"
            >
                <NavItem
                    icon={APP_ICONS.EXAMS}
                    title={"Analyze"}
                    link={"/admin/components"}
                />
            </Flex> */}

        </Box>
    );
    return (
        <React.Fragment>
            <SidebarContent
                display={{
                    base: "none",
                    md: "unset",
                }}
            />

            {/* <SubSider sidebarOptions={quizSubSiderOptions} containerStyles={{ zIndex: 99999 }} /> */}

            <Drawer
                isOpen={disclosure.isOpen}
                onClose={disclosure.onClose}
                placement="left"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <SidebarContent w="full" borderRight="none" />
                </DrawerContent>
            </Drawer>
        </React.Fragment>
    )
}

export default memo(Sider)