import { Button, useDisclosure } from "@chakra-ui/react";
import MediaLibrary from "./index.js";
import { ALLOWED_FILE_TYPES } from "../../config/constants/fileHelper";
import { useController } from "react-hook-form";

export const MediaTrigger = ({ value, acceptedFileTypes = '', component, componentProps, type, control, id, required, label = "This field" }) => {
    const mediaModalDisclosure = useDisclosure()
    if (type) {
        acceptedFileTypes = ALLOWED_FILE_TYPES[type].join(', ');
    }

    if (required) {
        required = `${label} is required`
    }

    const { field } = useController({
        name: id,
        control,
        rules: { required },
        defaultValue: value
    })

    return (
        <>
            <Button w="full" p="0" bg="transparent !important" h="auto" onClick={() => mediaModalDisclosure.onOpen()} {...componentProps}>{component || "Choose File"}</Button>
            <MediaLibrary
                isOpen={mediaModalDisclosure.isOpen}
                value={value}
                onClose={mediaModalDisclosure.onClose}
                onChange={(file) => field.onChange(file)}
                fileType={type}
            />
        </>
    );
}


export default MediaTrigger;