import { Box, Heading, Icon, Text, VStack, useColorMode, chakra } from '@chakra-ui/react'
import React from 'react'
import APP_ICONS from '../../../config/constants/icons'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import PrimaryButton from '../Buttons/PrimaryButton'
import { Link } from 'react-router-dom'

const SuccessBox = ({ icon = APP_ICONS.DONE, label = "Success!", children, link, containerProps }) => {
    const { colorMode } = useColorMode()
    return (
        <VStack spacing={3} textAlign={"center"} align="stretch" {...containerProps}>
            <Icon m="auto" color={getColor(colorKeys.success, colorMode)} as={icon} fontSize={"60px"} />
            <Heading fontSize="24px">{label}</Heading>
            <Box>
                {children}
            </Box>
            {link && <Box>
                <PrimaryButton as={Link} to={link} m="auto" minW="180px">Go to Quiz</PrimaryButton>
            </Box>}
        </VStack>
    )
}

export default SuccessBox