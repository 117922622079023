import APP_ICONS from "./icons"

const quizSubSiderOptions = [
    {
        icon: APP_ICONS.QUIZ,
        title: "Manage Quizzes",
        link: "/admin/exams",
        children: [
            {
                icon: APP_ICONS.QUIZ,
                title: "Quizzes",
                link: "/admin/exams",
            },
            {
                icon: APP_ICONS.QUESTIONS,
                title: "Questions",
                link: "/admin/exams/quizzes/questions"
            },
        ]
    },

    // {
    //     icon: APP_ICONS.QUIZ,
    //     title: "Manage Descriptive Exams",
    //     link: "/admin/exams",
    //     children: [
    //         {
    //             icon: APP_ICONS.EXAMS_LIST,
    //             title: "Descriptive Exams",
    //             link: "/admin/exams/descriptive/results"
    //         },
    //         {
    //             icon: APP_ICONS.CATEGORIES_TAG,
    //             title: "Categories",
    //             link: "/admin/exams/categories"
    //         },
    //         {
    //             icon: APP_ICONS.ARCHIVE,
    //             title: "Archive",
    //             link: "/admin/exams/archive"
    //         }
    //     ]
    // },


]

export default quizSubSiderOptions