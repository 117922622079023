import APP_ICONS from "./icons"

const usersSubsiderOptions = [
    {
        icon: APP_ICONS.EXAMS_LIST,
        title: "Users",
        link: "/admin/users"
    },
    {
        icon: APP_ICONS.CATEGORIES_TAG,
        title: "Roles",
        link: "/admin/users/roles"
    },
]

export default usersSubsiderOptions