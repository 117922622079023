import React, { useState } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Flex,
    useColorMode,
    Text,
    HStack,
    IconButton,
    Icon,
    Box,
    Heading,
    useDisclosure,
    VStack,
    Tooltip,
    Image,
    Wrap,
    WrapItem,
} from '@chakra-ui/react'
import { colorKeys, getColor } from '../../../../../../config/constants/appColors'
import APP_ICONS from '../../../../../../config/constants/icons'
import { useParams } from 'react-router'
import { useChapters, useDeleteChapter } from '../../../../../../config/query/chapterQuery'
import PrimaryButton from '../../../../../../components/BasicUI/Buttons/PrimaryButton'
import ChapterForm from '../../../ChapterForm'
import sortOrders from '../../../../../../config/constants/sortOrders'
import LectureCard from '../../../../../../components/DataCards/LectureCard'
import NotesCard from '../../../../../../components/DataCards/NotesCard'
import QuizCard from '../../../../../../components/DataCards/QuizCard'
import { useContents, useDeleteContent } from '../../../../../../config/query/contentQuery'
import ContentForm from './ContentForm'
import { COHORT_CONTENT_TYPES } from '../../../../../../config/constants/enums'
import IMAGES from '../../../../../../config/constants/images'
import ChapterCard from '../../../../../../components/DataCards/ChapterCard'
import LectureForm from '../../../../Lectures/LectureForm'
import QuizForm from '../../../../Quizzes/QuizForm'
import NotesForm from '../../../Notes/NotesForm'

const Content = () => {
    const { colorMode } = useColorMode()
    const { cohortId } = useParams()

    const chapterFormDisclosure = useDisclosure()
    const contentFormDisclosure = useDisclosure()

    const [selectedChapter, setSelectedChapter] = useState(null)
    const [selectedContent, setSelectedContent] = useState(null)
    const [activeChapters, setActiveChapters] = useState([])

    const chaptersQuery = useChapters({
        cohorts: cohortId,
        sortOrder: sortOrders.ASC,
        sortBy: "order"
    })
    const contentQuery = useContents({
        chapters: activeChapters?.map((item) => chaptersQuery.data?.results?.at(item)?.id).join(",")
    })
    const deleteContentQuery = useDeleteContent()

    const onCreateChapter = () => {
        setSelectedChapter(null)
        chapterFormDisclosure.onOpen()
    }

    const onAddContent = (e, item) => {
        e.stopPropagation()
        contentFormDisclosure.onOpen()
        setSelectedChapter(item)
    }

    const onEditChapter = (e, item) => {
        e.stopPropagation()
        chapterFormDisclosure.onOpen()
        setSelectedChapter(item)
    }

    const onDeleteContent = (id) => {
        deleteContentQuery
            .mutateAsync(id)
            .then(() => contentQuery.refetch())
            .catch((err) => console.log(err))
    }

    return (
        <Box>
            <Flex justify="space-between" align={"center"} mb={2}>
                <Heading as="h2" fontSize={26} fontWeight="bold">Chapters</Heading>
                <PrimaryButton
                    leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
                    onClick={onCreateChapter}
                >
                    Create New Chapter
                </PrimaryButton>
            </Flex>

            {chaptersQuery.data?.results?.length === 0 && !chaptersQuery.isLoading && (
                <Flex justifyContent="center" alignItems="center" h="100%" flexDirection="column">
                    <Image src={IMAGES.NO_RESULTS} maxW="250px" mt={10} />
                    <Text color={getColor(colorKeys.secondaryText, colorMode)} mt={0}>There are no chapters available for this cohort</Text>
                </Flex>
            )}

            <Wrap>
                {chaptersQuery.data?.results?.map((item) =>
                    <WrapItem w="full" key={item?.id}>
                        <ChapterCard
                            onEditChapter={onEditChapter}
                            onAddContent={onAddContent}
                            onDeleteContent={onDeleteContent}
                            chapter={item}
                        />
                    </WrapItem>
                )}
            </Wrap>

            <ChapterForm data={selectedChapter} disclosure={chapterFormDisclosure} cohortId={cohortId} />
            <NotesForm data={selectedChapter} disclosure={contentFormDisclosure} cohortId={cohortId} />
            <LectureForm chapterId={selectedChapter?.id} disclosure={contentFormDisclosure} cohortId={cohortId} data={selectedContent} />
            <QuizForm chapterId={selectedChapter?.id} disclosure={contentFormDisclosure} cohortId={cohortId} data={selectedContent} />
        </Box>
    )
}

export default Content