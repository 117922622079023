import { BsArchive, BsPlay, BsFolderPlus, BsPlus, BsChevronLeft, BsSun, BsChevronDown, BsMoon, BsChevronRight, BsFillCaretDownFill, BsChevronUp, BsFileEarmarkBarGraph, BsFileText, BsGear, BsListUl, BsTag, BsTrash, BsArrowUpShort, BsArrowDownShort, BsCaretDown, BsCaretDownFill, BsCaretUp, BsCaretUpFill, BsCheckLg, BsUpload, BsArrowsAngleExpand, BsChatSquareText, BsGrid, BsCheck2Circle, BsStars, BsQuestionSquare, BsSquare, BsCheckSquare, BsXLg, BsJournalBookmark } from "react-icons/bs"
import { HiOutlineBriefcase } from "react-icons/hi"
import { AiOutlineEdit, AiOutlineEye, AiOutlineEyeInvisible, AiOutlineInfoCircle, AiOutlineMail, AiOutlineMore, AiOutlineSend, AiOutlineUser } from "react-icons/ai"
import { GrRefresh } from "react-icons/gr"
import { FiCheckSquare, FiFilter, FiFolderPlus, FiLock, FiMenu, FiSearch, FiUsers } from "react-icons/fi"
import { BiRefresh } from "react-icons/bi"
import { MdOndemandVideo, MdOutlinePermMedia, MdOutlineQuiz, MdQuiz, MdRestore } from "react-icons/md"
import ZoomMeetingIcon from "../../components/Icons/ZoomMeetingIcon"
import { FcFile, FcImageFile, FcVideoFile, FcAudioFile, FcFolder, FcDocument } from "react-icons/fc"
import { CloseIcon } from "@chakra-ui/icons"
import { RiCheckDoubleFill } from "react-icons/ri"
import { FaNetworkWired } from "react-icons/fa"

const APP_ICONS = {
    MENU: FiMenu,
    EXAMS: BsFileText,
    EXAMS_LIST: BsListUl,
    COURSES: HiOutlineBriefcase,
    CATEGORIES_TAG: BsTag,
    ARCHIVE: BsArchive,
    LECTURES: MdOndemandVideo,
    USERS: FiUsers,
    EMAILS: AiOutlineMail,
    REPORTS: BsFileEarmarkBarGraph,
    BIN: BsTrash,
    CHATS: BsChatSquareText,
    SETTINGS: BsGear,
    MEDIA_LIB: MdOutlinePermMedia,
    SINGLE_USER: AiOutlineUser,
    FILTER: FiFilter,
    MORE: AiOutlineMore,
    DASHBOARD: BsGrid,
    NOTES:BsJournalBookmark,

    //Exam Subsider
    QUIZ: FiCheckSquare,
    QUESTIONS: BsQuestionSquare,

    WARNING: AiOutlineInfoCircle,

    //actions
    EDIT: AiOutlineEdit,
    REFRESH: BiRefresh,
    ADD: BsPlus,
    CHECK: BsCheckLg,
    UPLOAD: BsUpload,
    EXPAND: BsArrowsAngleExpand,
    SEARCH: FiSearch,
    SEND: AiOutlineSend,
    RESTORE: MdRestore,
    EYE: AiOutlineEye,
    EYE_OUT: AiOutlineEyeInvisible,
    ADD_FOLDER: FiFolderPlus,
    PLAY: BsPlay,
    DONE: BsCheck2Circle,
    MAGIC: BsStars,
    LOCK: FiLock,
    COHORT_VARIANTS:FaNetworkWired,

    //Navigation Icons
    LeftChevron: BsChevronLeft,
    RightChevron: BsChevronRight,
    UpChevron: BsChevronUp,
    DownChevron: BsChevronDown,
    UpArrow: BsArrowUpShort,
    DownArrow: BsArrowDownShort,
    CaretDown: BsCaretDown,
    CaretDownFill: BsCaretDownFill,
    CaretUp: BsCaretUp,
    CaretUpFill: BsCaretUpFill,
    CLOSE: CloseIcon,
    DOUBLE_CHECK: RiCheckDoubleFill,
    UNCHECKED: BsSquare,
    CHECKED: BsCheckSquare,
    CLOSE_ALT: BsXLg,

    DropDownIcon: BsFillCaretDownFill,

    //Color Modes
    LighMode: BsSun,
    DarkMode: BsMoon,


    //FILE TYPES
    UNKNOWN_FILE: FcFile,
    IMAGE_FILE: FcImageFile,
    VIDEO_FILE: FcVideoFile,
    AUDIO_FILE: FcAudioFile,
    FOLDER: FcFolder,
    DOCUMENT: FcDocument,
}

export default APP_ICONS