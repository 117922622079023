import { Box, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import RoleList from "./RoleList"
import RoleForm from "./RoleForm"
import APP_ICONS from '../../../../config/constants/icons'

const Roles = () => {
  const formDisclosure = useDisclosure()
  const [selectedRole, setSelectedRole] = React.useState(null)

  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">Roles</Heading>
        <PrimaryButton onClick={() => {
          formDisclosure.onOpen()
          setSelectedRole(null)
        }}
          leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
        >
          Create New Role
        </PrimaryButton>
      </Flex>
      <RoleList
        onEditModal={(data) => {
          setSelectedRole(data)
          formDisclosure.onOpen()
        }}
      />
      <RoleForm disclosure={formDisclosure} data={selectedRole} />
    </Box>
  )
}

export default Roles