import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Text } from '@chakra-ui/react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import { useCohorts, useCohortsFacet, useDeleteCohort } from '../../../../config/query/cohortQuery'
import { formatDate, formatDateTime, formatDateTimeFromNow } from '../../../../config/helpers/dateHelper'
import APP_ICONS from '../../../../config/constants/icons'
import { getFilters } from '../../../../config/helpers/filterHelper'
import TableInfoPopover from '../../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../../components/BasicUI/LabelValuePair'
import StatusBadge from '../../../../components/BasicUI/Badges/StatusBadge'
import Chronology from '../../../../components/BasicUI/DataBoxes/Chronology'
import AvatarStack from '../../../../components/BasicUI/DataBoxes/AvatarStack'

const CohortsList = ({ onEditModal }) => {
    const navigate = useNavigate()
    const [query, setQuery] = useState({
        pageNo: 1,
        pageSize: 20,
    })

    const cohortsQuery = useCohorts(query)
    const cohortFacetQuery = useCohortsFacet()
    const deleteCohortQuery = useDeleteCohort()

    const handleDelete = (id) => {
        deleteCohortQuery.mutateAsync(id)
            .then((response) => {
                cohortsQuery.refetch()
                console.log(`Cohort deleted successfully: ${response}`)
            })
            .catch((error) => {
                console.log(`Error while deleting cohort: ${error}`)
            })
    }

    const onOpen = (id) => {
        navigate(`/admin/syllabus/cohorts/${id}`)
    }

    return (
        <CustomTable
            containerProps={{ mt: 5 }}
            searchPlaceholder="Search cohorts"
            filters={getFilters(cohortFacetQuery?.data)}
            head={[
                {
                    title: "Name",
                    extractor: "formattedName",
                    align: "left",
                    component: (item) => (
                        <TableInfoPopover
                            data={item}
                            onEdit={(item) => onEditModal(item)}
                            onDelete={(id) => handleDelete(id)}
                            triggerOnClick={() => onOpen(item?.id)}
                            titleKey='formattedName'
                        >
                            <LabelValuePair label="Description" value={item.description} />
                            <LabelValuePair label="Course" value={item.courseName} />
                            <LabelValuePair label="Qualification" value={item.qualificationName} />
                            <LabelValuePair label="Availability" value={item.availablityDateTime} />
                            {/* <LabelValuePair label="Price" value={`PKR ${item.price?.toFixed(2)}`} /> */}
                            {/* <LabelValuePair label="Discount" value={`PKR ${item.discount?.toFixed(2)}`} /> */}
                            <LabelValuePair
                                label="Status"
                                value={true}
                                valueComponent={
                                    <StatusBadge value={item.status} />
                                }
                            />
                            <LabelValuePair label="Created" value={formatDateTime(item.createdBy?.dateTime)} />
                            <LabelValuePair label="Last Updated" value={formatDateTime(item.updateBy?.dateTime)} />
                        </TableInfoPopover>
                    )
                },
                // { title: "Price (with discount)", extractor: "priceValue" },
                { title: "Status", extractor: "status", component: (item) => <StatusBadge value={item.status} /> },
                { title: "teachers", extractor: "teachers", align: "center", component: (item) => item.teachers?.length ? <AvatarStack data={item.teachers} /> : <Text fontSize="14px">N/A</Text> },
                {
                    title: "Last Modified",
                    extractor: "lastModified",
                    component: (item) => <Chronology data={item} />
                },
                { title: "Actions", extractor: "actions" }
            ]}
            data={cohortsQuery?.data?.results?.map((item) => {
                return {
                    ...item,
                    // priceValue: item.price ? `PKR ${(item.price - item.discount)?.toFixed(2)}` : "Free",
                    availablityDateTime: `${formatDate(item.startDate)} - ${formatDate(item.endDate)}`,
                    lastModified: item.updatedBy?.dateTime ? formatDateTimeFromNow(item.updatedBy?.dateTime) : formatDateTimeFromNow(item.createdBy?.dateTime),
                    actions: [
                        {
                            title: "Edit",
                            action: () => onEditModal(item),
                            icon: APP_ICONS.EDIT,
                            isEdit: true
                        },
                        {
                            title: "Delete",
                            action: handleDelete,
                            icon: APP_ICONS.BIN,
                            isDelete: true
                        }
                    ]
                }
            })}
            loading={cohortsQuery?.isLoading}
            totalResults={cohortsQuery?.data?.totalResults}
            totalPages={cohortsQuery?.data?.totalPages}
            pageNo={query.pageNo}
            pageSize={query.pageSize}
            onQueryChange={(updatedQuery) => setQuery({ ...query, ...updatedQuery })}
            query={query}
            onRefresh={() => cohortsQuery.refetch()}
            isRefreshing={cohortsQuery?.isFetching}
        />
    )
}

export default CohortsList