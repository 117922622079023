import { Box, chakra, VStack, Heading, Flex, IconButton, Icon, useDisclosure, Tooltip, Card, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { useForm } from 'react-hook-form'
import FormButton from '../../../../components/Forms/FormButton'
import FormInput from '../../../../components/Forms/FormInput'
import { EMAIL_REGEX } from '../../../../config/constants/regexConstants'
import FormSwitch from '../../../../components/Forms/FormSwitch'
import { useZoomSettings, useUpdateZoomSettings } from '../../../../config/query/settingQuery'
import FormFieldsSkeleton from '../../../../components/Skeletons/FormFieldsSkeleton'
import FormTextarea from '../../../../components/Forms/FormTextarea'
import APP_ICONS from '../../../../config/constants/icons'
import LabelValuePair from '../../../../components/BasicUI/LabelValuePair'
import ZoomForm from './ZoomForm'


const ZoomSettings = () => {
    const editDisclosure = useDisclosure()
    const [selectedZoom, setSelectedZoom] = React.useState(null)

    const zoomSettingsQuery = useZoomSettings()

    const onEditModal = (index) => {
        setSelectedZoom(index)
        editDisclosure.onToggle()
    }

    return (
        <VStack spacing={5} align={"stretch"}>
            <Heading fontSize={"20px"}>Zoom Settings</Heading>
            <SimpleGrid columns={{ base: 1, md: 4, }} spacing={5}>
                {zoomSettingsQuery.data?.map((item, index) => {
                    return (
                        <Card key={index} p={"10px"}>
                            <Flex align={"center"} justify={"space-between"} maxW="500px">
                                <Heading fontSize={"16px"}>{item.accountName}</Heading>
                                <Tooltip label={"Edit"} aria-label="A tooltip">
                                    <IconButton
                                        icon={<Icon
                                            boxSize={"1.25em"}
                                            as={APP_ICONS.EDIT}
                                        />}
                                        onClick={() => onEditModal(index)}
                                    />
                                </Tooltip>
                            </Flex>

                            <LabelValuePair label="Email" value={"someone@example.com"} />
                        </Card>

                    )
                })}
            </SimpleGrid>
            <ZoomForm disclosure={editDisclosure} index={selectedZoom} list={zoomSettingsQuery.data} />
        </VStack>
    )
}

export default ZoomSettings