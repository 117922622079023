import React, { useState } from 'react'
import { Avatar, Badge, Flex, HStack, chakra } from '@chakra-ui/react'
import CustomTable from '../../../../../components/BasicUI/CustomTable'
import { getFilters } from '../../../../../config/helpers/filterHelper'
import TableInfoPopover from '../../../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../../../components/BasicUI/LabelValuePair'
import StatusBadge from '../../../../../components/BasicUI/Badges/StatusBadge'
import { formatDateTime, formatDateTimeFromNow } from '../../../../../config/helpers/dateHelper'
import Chronology from '../../../../../components/BasicUI/DataBoxes/Chronology'
import APP_ICONS from '../../../../../config/constants/icons'
import { useDeleteQuizResult, useQuizResultFacet, useQuizResults } from '../../../../../config/query/quizResultsQuery'
import { useNavigate } from 'react-router'
import { RESULT_STATUS } from '../../../../../config/constants/enums'

const QuizResults = () => {

    const navigate = useNavigate()
    const [query, setQuery] = useState({
        pageNo: 1,
        pageSize: 20,
    })

    const quizResultsQuery = useQuizResults(query)
    const quizResultsFacetQuery = useQuizResultFacet()
    const deleteQuizResultQuery = useDeleteQuizResult()

    const onEditModal = (item) => {

    }

    const handleDelete = (id) => {
        deleteQuizResultQuery
            .mutateAsync(id)
            .then(() => {
                quizResultsQuery.refetch()
            })
    }



    return (
        <CustomTable
            searchPlaceholder="Search quizs"
            filters={getFilters(quizResultsFacetQuery?.data, ["quizzes"])}
            head={[
                {
                    title: "Name",
                    extractor: "name",
                    align: "left",
                    component: (item) => (
                        <TableInfoPopover
                            data={item}
                            titleKey='userName'
                            onEdit={(item) => onEditModal(item)}
                            onDelete={(id) => handleDelete(id)}
                            triggerOnClick={() => onEditModal(item)}
                        >
                            <LabelValuePair label="Correct Answers" value={item.answersCorrect} />
                            <LabelValuePair label="Incorrect Answers" value={item.answersIncorrect} />
                            <LabelValuePair
                                label="Status"
                                value={true}
                                valueComponent={
                                    <StatusBadge value={item.status} />
                                }
                            />
                            <LabelValuePair label="Created" value={formatDateTime(item.createdBy?.dateTime)} />
                            <LabelValuePair label="Last Updated" value={formatDateTime(item.updateBy?.dateTime)} />
                        </TableInfoPopover>
                    )
                },
                { title: "Percentage", extractor: "percentage", component: (item) => <span>{item.percentage}%</span> },
                {
                    title: "Status",
                    extractor: "resultName",
                    component: (item) =>
                        <Badge colorScheme={item.resultName === RESULT_STATUS.PASS ? "green" : "red"}>{item.resultName}</Badge>
                },
                {
                    title: "Last Modified",
                    extractor: "lastModified",
                    component: (item) => <Chronology
                        data={item}
                        childData={
                            <>
                                <LabelValuePair
                                    label="Started at"
                                    headingProps={{
                                        fontSize: "14px",
                                    }}
                                    value={formatDateTime(item.startDate)}
                                />
                                <LabelValuePair
                                    label="Submitted at"
                                    headingProps={{
                                        fontSize: "14px",
                                    }}
                                    value={formatDateTime(item.submitDate)}
                                />
                                <LabelValuePair
                                    label="Updated by"
                                    headingProps={{
                                        fontSize: "14px",
                                    }}
                                    value={item.updatedBy?.dateTime}
                                    valueComponent={
                                        <HStack>
                                            <Flex>
                                                <Avatar size="sm" name={item.updatedBy?.name} src={item.updatedBy?.profilePicture} />
                                            </Flex>
                                            <chakra.p whiteSpace={"normal"} fontSize="14px">
                                                <chakra.p fontWeight="bold">{item.updatedBy?.name}</chakra.p><chakra.p fontWeight="normal">{formatDateTime(item.updatedBy?.dateTime)}</chakra.p>
                                            </chakra.p>
                                        </HStack>
                                    }
                                />
                            </>
                        }
                    />
                },
                { title: "Actions", extractor: "actions" }
            ]}
            data={quizResultsQuery?.data?.results?.map((item) => {
                return {
                    ...item,
                    lastModified: item.updatedBy?.dateTime ? formatDateTimeFromNow(item.updatedBy?.dateTime) : formatDateTimeFromNow(item.createdBy?.dateTime),
                    actions: [
                        {
                            title: "View",
                            action: () => navigate(`/admin/quizs/${item.id}`),
                            icon: APP_ICONS.EYE,
                            isView: true
                        },
                        {
                            title: "Delete",
                            action: handleDelete,
                            icon: APP_ICONS.BIN,
                            isDelete: true
                        }
                    ]
                }
            })}
            loading={quizResultsQuery?.isLoading}
            totalResults={quizResultsQuery?.data?.totalResults}
            totalPages={quizResultsQuery?.data?.totalPages}
            pageNo={query.pageNo}
            pageSize={query.pageSize}
            onQueryChange={(updatedQuery) => setQuery({ ...query, ...updatedQuery })}
            query={query}
            onRefresh={() => quizResultsQuery.refetch()}
            isRefreshing={quizResultsQuery?.isFetching}
        />
    )
}

export default QuizResults