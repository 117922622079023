import { Box, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../components/BasicUI/Buttons/PrimaryButton'
import ChapterList from './ChapterList'
import ChapterForm from './ChapterForm'
import APP_ICONS from '../../../config/constants/icons'

const Chapters = () => {
  const formDisclosure = useDisclosure()
  const [selectedChapter, setSelectedChapter] = React.useState(null)

  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">Chapters</Heading>
        <PrimaryButton
          onClick={() => {
            setSelectedChapter(null)
            formDisclosure.onOpen()
          }}
          leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
        >
          Create New Chapter
        </PrimaryButton>
      </Flex>
      <ChapterList
        onEditModal={(data) => {
          setSelectedChapter(data)
          formDisclosure.onOpen()
        }}
      />
      <ChapterForm disclosure={formDisclosure} data={selectedChapter} />
    </Box>
  )
}

export default Chapters