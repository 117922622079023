import React from 'react'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    Button,
    Box,
    chakra,
    useColorMode,
    VStack,
    Divider,
    HStack,
    Checkbox,
    Icon,
    Flex,
} from '@chakra-ui/react'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import APP_ICONS from '../../../config/constants/icons'

const FormFilterSelect = ({ value, label, options, onChange }) => {
    const { colorMode } = useColorMode()

    return (
        <Popover isLazy>
            {({ isOpen, onClose }) => (
                <>
                    <PopoverTrigger>
                        {value
                            ? <Button h="40px" justifyContent="left" minW="230px" size="sm" variant={"outline"} fontWeight={"normal"}>
                                <Flex align="center" justify="space-between" w="full">
                                    <Flex>
                                        <chakra.span>
                                            {label}
                                        </chakra.span>
                                        {value?.length > 0 && `: ${value?.length} selected `}
                                    </Flex>
                                    {value?.length > 0
                                        ? <Icon as={APP_ICONS.CLOSE_ALT} boxSize="4" onClick={() => onChange([])} />
                                        : isOpen ? <Icon as={APP_ICONS.UpChevron} /> : <Icon as={APP_ICONS.DownChevron} />}
                                </Flex>
                            </Button>
                            : <Button justifyContent="left" minW="230px" fontWeight={"normal"} color={getColor(colorKeys.placeholderColor, colorMode)} size="sm" variant={"outline"}>Cohorts</Button>
                        }
                    </PopoverTrigger>
                    <PopoverContent maxW="fit-content" minW="230px" m="auto">
                        <PopoverBody maxW="fit-content" minW="230px" p="0">
                            <VStack my={0} spacing={0} divider={<Divider />} align="stretch" w="fit-content" maxH={"calc(100vh - 400px)"} overflow={"auto"}>
                                {options?.map((item, index) => {
                                    const isSelected = value?.find((i) => i === item?.key)
                                    if (!item.key || !item.value) return null
                                    return (
                                        <HStack
                                            px={2}
                                            spacing={1}
                                            bg={isSelected ? getColor(colorKeys.lightBackgroundFill, colorMode) : getColor(colorKeys.white, colorMode)}
                                            _hover={{
                                                bg: getColor(colorKeys.lightBackgroundFill, colorMode),
                                            }}

                                        >
                                            {isSelected
                                                ? <Icon as={APP_ICONS.CHECKED} />
                                                : <Icon as={APP_ICONS.UNCHECKED} />
                                            }

                                            <Button
                                                onClick={() => {
                                                    if (isSelected) {
                                                        onChange(value?.filter((i) => i !== item?.key))
                                                    } else {
                                                        onChange([...value, item?.key])
                                                    }
                                                }}
                                                variant={isSelected ? "solid" : "ghost"}
                                                key={index}
                                                justifyContent="left"
                                                minW="230px"
                                                size="sm"
                                                fontWeight={"normal"}
                                            >
                                                {item.value} ({item.count})
                                            </Button>
                                        </HStack>
                                    )
                                })}
                            </VStack>
                        </PopoverBody>
                    </PopoverContent>
                </>
            )}
        </Popover>
    )
}

export default FormFilterSelect