import { Avatar, Box, Flex, Heading, SimpleGrid, Text, VStack, chakra, useColorMode } from '@chakra-ui/react'
import React, { useState } from 'react'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import { useSelector, useDispatch } from 'react-redux'
import { getColor, colorKeys } from '../../../../config/constants/appColors'
import FormInput from '../../../../components/Forms/FormInput'
import FormTextarea from '../../../../components/Forms/FormTextarea'
import FormButton from '../../../../components/Forms/FormButton'
import { useForm } from 'react-hook-form'
import { Put } from "../../../../config/api"
import API_CONSTANTS from '../../../../config/constants/api'
import { updateUser } from "../../../../config/redux/slices/userSlice"
import { toast } from 'react-toastify'
import { EMAIL_REGEX, PHONE_REGEX } from '../../../../config/constants/regexConstants'
import FormPhoneInput from '../../../../components/Forms/FormPhoneInput'
import SecondaryButton from '../../../../components/BasicUI/Buttons/SecondaryButton'
import uploadFile from '../../../../config/helpers/uploadFile'
import { ALLOWED_FILE_TYPES } from '../../../../config/constants/fileHelper'
import FormFileUpload from '../../../../components/Forms/FormFileUpload'
import { useUpdateProfilePicture } from '../../../../config/query/authQuery'

const Profile = () => {
  const { user, token } = useSelector(state => state.user)
  const [profilePicture, setProfilePicture] = useState(user?.profilePictureUrl)

  const updateProfilePictureQuery = useUpdateProfilePicture()

  const dispatch = useDispatch()
  const { colorMode } = useColorMode()
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { ...user }
  })

  function onSubmit(values) {
    return new Promise((resolve, reject) => {
      Put({
        path: API_CONSTANTS.AUTH.updateProfile,
        token,
        body: values,
        toastError: true,
        toastMessage: true
      })
        .then(() => {
          dispatch(updateUser(values))
          resolve()
        })
        .catch((error) => {
          reject()
        })
    });
  }

  const onImageChange = (file) => {
    updateProfilePictureQuery.mutateAsync({ file }).then(() => {
      const image = URL.createObjectURL(file)
      setProfilePicture(image)
      dispatch(updateUser({ ...user, profilePicture: image, profilePictureUrl: image }))
    })
  }

  const removePicture = () => {
    updateProfilePictureQuery.mutateAsync({ remove: true }).then(() => {
      setProfilePicture(null)
    })
  }

  return (
    <Box p="40px">

      <Box p="14px 20px" bg={getColor(colorKeys.tableBackground, colorMode)}>
        <Heading>{user?.name}</Heading>
        <Text>{user?.description}</Text>
      </Box>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} p="60px 0">

        <Box>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            <Heading fontSize={"20px"}>Personal Information</Heading>

            <Box mt={4} maxW="500px">

              <VStack spacing={4}>

                <FormInput
                  id={"name"}
                  label="Name"
                  placeholder="Enter your name"
                  required={true}
                  errors={errors}
                  control={control}
                />

                <FormInput
                  id={"email"}
                  label="Email"
                  placeholder="someone@example.com"
                  required={true}
                  errors={errors}
                  control={control}
                  rules={{
                    pattern: {
                      value: EMAIL_REGEX,
                      message: "Invalid email address"
                    }
                  }}
                />

                <FormPhoneInput
                  id={"phoneNumber"}
                  label="Phone Number"
                  type="tel"
                  placeholder="Enter your phone number"
                  required={true}
                  errors={errors}
                  control={control}
                  setCountryCodeValue={(value) => {
                    setValue("countryCode", value)
                  }}
                  countryCodeValue={getValues("countryCode")}
                  rules={{
                    pattern: {
                      value: PHONE_REGEX,
                      message: "Invalid phone number"
                    }
                  }}
                />

                <FormTextarea
                  id={"description"}
                  label="Description"
                  placeholder="Write something about yourself"
                  errors={errors}
                  control={control}
                />

                <FormInput
                  id={"country"}
                  label="Country"
                  placeholder="Enter your country"
                  errors={errors}
                  control={control}
                />

                <FormInput
                  id={"city"}
                  label="City"
                  placeholder="Enter your city"
                  errors={errors}
                  control={control}
                />

                <FormTextarea
                  id={"address"}
                  label="Address"
                  placeholder="Enter your address"
                  errors={errors}
                  control={control}
                />

                <FormButton
                  mr="auto"
                  isLoading={isSubmitting}
                  type="submit"
                  containerStyles={{ textAlignLast: "right" }}
                >
                  Save Changes
                </FormButton>

              </VStack>

            </Box>

          </chakra.form>
        </Box>

        <Box>
          <Heading fontSize={"20px"}>Profile Picture</Heading>
          <Flex mt={4} align={"center"}>
            <Box>
              {profilePicture ? (
                <Avatar fontSize="44px" boxSize={140} src={profilePicture} name={user?.name} />
              ) : (
                <Avatar fontSize="44px" bg={getColor(colorKeys.avatarBg, colorMode)} boxSize={140} name={user?.name} />
              )}
            </Box>
            <Flex flexDir={"column"} mx={8}>
              <FormFileUpload
                component={<PrimaryButton my={1}>
                  Change Picture
                </PrimaryButton>}
                fileType={"IMAGE"}
                onChange={onImageChange}
              />
              {user?.profilePicture && <SecondaryButton my={1} onClick={removePicture}>Remove Picture</SecondaryButton>}
            </Flex>
          </Flex>
          <Text mt={3} color={getColor(colorKeys.gray, colorMode)} fontStyle={"italic"} fontSize={"14px"}>Profile picture should be aroung 250x300 pixels</Text>
        </Box>

      </SimpleGrid>
    </Box>
  )
}

export default Profile