import { Input, FormControl, FormLabel, InputGroup, InputLeftElement, Icon, Button, createStandaloneToast } from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";
import { useRef } from "react";
import { ALLOWED_FILE_EXTENSIONS, getFileExtention } from "../../../config/constants/fileHelper";
const { toast } = createStandaloneToast()

export const FormFileUpload = ({ id, label, value, placeholder, acceptedFileTypes = '', isRequired = false, onChange, component, componentProps, fileType, multiple }) => {
    const inputRef = useRef();
    if (fileType) {
        acceptedFileTypes = ALLOWED_FILE_EXTENSIONS[fileType].join(', ');
    }
    return (
        <>
            <input type='file'
                multiple={multiple}
                onChange={(e) => {
                    console.log(e.target.files, "files")
                    if (acceptedFileTypes && !acceptedFileTypes.includes(getFileExtention(e.target.files[0]))) {
                        toast({
                            id: `File type not allowed. Allowed file types are ${acceptedFileTypes}`,
                            title: 'Oh oh!',
                            description: `File type not allowed. Allowed file types are ${acceptedFileTypes}`,
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        })
                        return;
                    }
                    if (multiple) {
                        onChange(e.target.files)
                        return;
                    }
                    console.log(e.target.files, "files")
                    onChange(e.target.files[0])
                }}
                accept={acceptedFileTypes}
                name={id}
                ref={inputRef}
                style={{ display: 'none' }}
            />
            {component
                ? (<Button p="0" w="fit-content" bg="transparent !important" onClick={() => inputRef.current.click()} {...componentProps}>{component}</Button>)
                : (
                    <FormControl isRequired={isRequired}>
                        {label && <FormLabel htmlFor="writeUpFile">{label}</FormLabel>}
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents="none">
                                <Icon as={FiFile} />
                            </InputLeftElement>

                            <Input
                                cursor={"pointer"}
                                placeholder={placeholder || "Your file ..."}
                                onClick={() => inputRef.current.click()}
                                readOnly={true}
                                value={(value && value.id) || ''}
                                accept={acceptedFileTypes || '*'}
                            />
                        </InputGroup>
                    </FormControl>
                )}
        </>
    );
}


export default FormFileUpload;