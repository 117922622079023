import React, { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import FormDrawer from '../../../../../components/Forms/FormDrawer'
import { Box, Button, SimpleGrid, VStack } from '@chakra-ui/react'
import FormInput from '../../../../../components/Forms/FormInput'
import { useParams } from 'react-router'
import { useQuiz, useQuizQuestions, useQuizUpsertQuestions } from '../../../../../config/query/quizQuery'
import { generateOptionObject } from '../../../../../config/helpers/formHelpers/questionFormHelper'
import APP_ICONS from '../../../../../config/constants/icons'
import RichTextEditor from '../../../../../components/Forms/RichTextEditor'
import FormEmbededButton from '../../../../../components/Forms/FormEmbededButton'
import FormSwitch from '../../../../../components/Forms/FormSwitch'
import { useQueryClient } from '@tanstack/react-query'
import { generateString } from '../../../../../config/helpers/stringHelper'

const AddQuestionsForm = ({ disclosure, data }) => {

    const queryClient = useQueryClient()

    const { quizId } = useParams()
    const quizQuery = useQuiz(quizId)
    const quizQuestionsQuery = useQuizQuestions(quizId)
    const upsertQuestionsQuery = useQuizUpsertQuestions(quizId)

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        values: {
            ...data
        },
    })

    const optionFieldArray = useFieldArray({
        name: "options",
        control,
        keyName: "id",
        rules: {
            required: true
        },
        shouldUnregister: true,
    })

    const fields = watch()

    const onSubmit = (values) => {
        values = {
            ...values,
            options: values.options.map(item => {
                delete item.id
                return item
            })
        }

        const questionsBody = data
            ? [
                ...quizQuestionsQuery.data.filter(item => item.id !== data.id),
                {
                    ...values,
                    cohortId: quizQuery.data?.cohortId,
                    chapterId: quizQuery.data?.chapterId,
                    type: 1,
                    status: 1
                }
            ]
            : [
                ...quizQuestionsQuery.data,
                {
                    ...values,
                    ...values,
                    cohortId: quizQuery.data?.cohortId,
                    chapterId: quizQuery.data?.chapterId,
                    type: 1,
                    status: 1
                }
            ]

        upsertQuestionsQuery.mutateAsync(questionsBody).then(() => {
            queryClient.invalidateQueries(["quizQuestions", quizId])
            reset()
            disclosure.onClose()
        })
    }

    const handleRemoveOption = (id) => {
        optionFieldArray.remove(id)
    }

    useEffect(() => {
        if (!data?.id && disclosure?.isOpen && fields.options?.length < 4) {
            new Array(4).fill({
                optionLabel: "",
                isCorrect: false
            }).forEach((item, index) =>
                optionFieldArray.insert(index, { ...item, id: generateString(10) })
            )
        }
    }, [disclosure?.isOpen])

    return (
        <FormDrawer
            title="Add Question"
            disclosure={disclosure}
            isSubmitting={upsertQuestionsQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            maxW='75rem'
        >
            <VStack spacing={5} align={"stretch"}>
                <FormInput
                    label={"Question"}
                    control={control}
                    errors={errors}
                    id="name"
                    required={true}
                    placeholder="Enter question"
                    rules={{
                        minLength: {
                            value: 3,
                            message: "Question must be at least 3 characters"
                        }
                    }}
                />

                <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
                    {optionFieldArray.fields?.map((item, index) => {
                        return <Box key={item.id}>
                            <FormInput
                                key={item.id}
                                label={`Option ${index + 1}`}
                                control={control}
                                errors={errors}
                                id={`options[${index}].optionLabel`}
                                required={true}
                                placeholder="Enter option label"
                                inputProps={{
                                    roundedRight: "none",
                                    borderRight: "none",
                                }}
                                rightElement={<FormEmbededButton
                                    onClick={() => handleRemoveOption(index)}
                                    icon={APP_ICONS.CLOSE}
                                    error={errors?.options?.at(index)?.optionLabel?.message}
                                />}
                                customError={errors?.options?.at(index)?.optionLabel?.message}
                            />
                            <FormSwitch
                                label={`Correct`}
                                control={control}
                                errors={errors}
                                id={`options[${index}].isCorrect`}
                                containerProps={{
                                    mt: 0,
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            />

                        </Box>
                    })}
                    <Button
                        mt="32px"
                        onClick={() => optionFieldArray.append(generateOptionObject())}
                    >
                        Add Option
                    </Button>
                </SimpleGrid>

                <RichTextEditor
                    label={"Description"}
                    placeholder="Describe your question ..."
                    control={control}
                    errors={errors}
                    id="description"
                    containerProps={{
                        mt: 5
                    }}
                />
            </VStack>
        </FormDrawer>
    )
}

export default AddQuestionsForm