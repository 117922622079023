import React from "react"
import { Menu, MenuButton, MenuList, MenuItem, Button, Flex, Text, Icon, } from "@chakra-ui/react"
import APP_ICONS from "../../../config/constants/icons"

const MenuDropdown = ({ label, options = [], onChange, size = "sm", customTrigger, menuButtonProps }) => {
    return (
        <Menu closeOnSelect={false} size={size}>
            <MenuButton onClick={(e) => e.stopPropagation()} as={Button} colorScheme='gray' size={size} {...menuButtonProps}>
                {customTrigger ? customTrigger : <Flex align="center">
                    <Text>{label}</Text>
                    <Icon ml={2} as={APP_ICONS.DownChevron} />
                </Flex>}
            </MenuButton>
            <MenuList minWidth='240px'>
                {options.map((item, index) =>
                    <MenuItem key={index} onClick={() => onChange(item.value)} value={item.value}>{item.label}</MenuItem>
                )}
            </MenuList>
        </Menu>
    )
}

export default MenuDropdown