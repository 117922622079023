import { Box, Flex, Heading, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import QuizCategoriesList from './QuizCategoriesList'
import QuizCategoryForm from './QuizCategoryForm'

const QuizCategories = () => {
  const formDisclosure = useDisclosure()

  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">Quiz Categories</Heading>
        <PrimaryButton onClick={() => formDisclosure.onOpen()}>Create New Category</PrimaryButton>
      </Flex>
      <QuizCategoriesList />
      <QuizCategoryForm disclosure={formDisclosure} />
    </Box>
  )
}

export default QuizCategories