import { Box, HStack, Icon, IconButton, Tooltip, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import QuestionBox from './QuestionBox'
import APP_ICONS from '../../../config/constants/icons'

const QuestionSuggestionBox = ({ question, onRemove, onAdd, onRemoveFromList, isAdded }) => {
    const { colorMode } = useColorMode()
    return (
        <Box border={`1px solid ${getColor(colorKeys.lightGray, colorMode)}`} rounded="md">
            <QuestionBox
                question={question}
                questionProps={{ fontSize: "14px" }}
                answerProps={{ fontSize: "12px" }}
                answersWrapperProps={{ spacing: 1 }}
                containerProps={{ p: 2 }}
                renderActions={false}
            />
            <Box bg={getColor(colorKeys.lightGrayBackgroundFill, colorMode)} roundedBottom={"sm"} p="2px">
                <HStack spacing={1} justify={"end"}>
                    <Tooltip label={isAdded ? "Remove Question" : "Add Question"} placement="top">
                        <IconButton
                            colorScheme={isAdded ? "green" : "gray"}
                            onClick={isAdded ? onRemove : onAdd}
                            size="sm"
                            icon={<Icon as={isAdded ? APP_ICONS.DOUBLE_CHECK : APP_ICONS.ADD} boxSize={isAdded ? "4" : "6"} />}
                        />
                    </Tooltip>
                    <Tooltip label="Ignore Question" placement="top">
                        <IconButton colorScheme='red' onClick={onRemoveFromList} size="sm" icon={<Icon as={APP_ICONS.CLOSE} boxSize={"10px"} />} />
                    </Tooltip>
                </HStack>
            </Box>
        </Box>
    )
}

export default QuestionSuggestionBox