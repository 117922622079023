import {
    useMutation
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { CONTENT_TYPE } from "../constants";

export const useLogin = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Post({
                path: API_CONSTANTS.AUTH.login,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}

export const useUpdateProfilePicture = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Put({
                path: API_CONSTANTS.AUTH.updateProfilePicture,
                token,
                body,
                toastError: true,
                toastMessage: true,
                contentType: CONTENT_TYPE.FORM_DATA
            })
            return data
        },
    })
}
