const errorHandler = ({ response, toast = false }) => {

}

export const isTabClean = ({ fields, errors }) => {
    return !fields.some(field => {
        return errors[field]
    })
}

export const getServerError = (error) => {
    let message;
    if (error?.response?.data?.data) {
        const errors = Array.from(Object.values(error?.response?.data?.data))
        message = errors[0][0]
    }
    else {
        message = error?.response?.data?.data || error?.response?.data?.apiMessage || "Unknown Error!"
    }
    message = message.length > 130 ? "Unknown Error!" : message
    return message
}

export default errorHandler