import React, { useState } from 'react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import { useNavigate } from 'react-router'
import { useDeleteQuestion, useQuestionFacet, useQuestions } from '../../../../config/query/questionsQuery'
import TableInfoPopover from '../../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../../components/BasicUI/LabelValuePair'
import StatusBadge from '../../../../components/BasicUI/Badges/StatusBadge'
import { formatDateTime, formatDateTimeFromNow } from '../../../../config/helpers/dateHelper'
import { getFilters } from '../../../../config/helpers/filterHelper'
import APP_ICONS from '../../../../config/constants/icons'
import Chronology from '../../../../components/BasicUI/DataBoxes/Chronology'

const QuestionsList = () => {
    const navigate = useNavigate()
    const [query, setQuery] = useState({
        pageNo: 1,
        pageSize: 20,
    })

    const questionsQuery = useQuestions(query)
    const questionFacetQuery = useQuestionFacet()
    const deleteQuestionQuery = useDeleteQuestion()

    const onEditModal = () => {

    }

    const handleDelete = (id) => {
        deleteQuestionQuery.mutateAsync(id)
            .then(() => {
                questionsQuery.refetch()
            })
    }
    return (
        <CustomTable
            containerProps={{ mt: 5 }}
            searchPlaceholder="Search quizs"
            filters={getFilters(questionFacetQuery?.data)}
            head={[
                {
                    title: "Name",
                    extractor: "name",
                    align: "left",
                    component: (item) => (
                        <TableInfoPopover
                            data={item}
                            onEdit={(item) => onEditModal(item)}
                            onDelete={(id) => handleDelete(id)}
                            triggerOnClick={() => onEditModal(item)}
                        >
                            <LabelValuePair label="Description" value={item.description} />
                            <LabelValuePair label="Course" value={item.courseName} />
                            <LabelValuePair label="Qualification" value={item.qualificationName} />
                            <LabelValuePair
                                label="Status"
                                value={true}
                                valueComponent={
                                    <StatusBadge value={item.status} />
                                }
                            />
                            <LabelValuePair label="Created" value={formatDateTime(item.createdBy?.dateTime)} />
                            <LabelValuePair label="Last Updated" value={formatDateTime(item.updateBy?.dateTime)} />
                        </TableInfoPopover>
                    )
                },
                { title: "Cohort Name", extractor: "cohortName" },
                { title: "Chapter Name", extractor: "chapterName" },
                { title: "Status", extractor: "status", component: (item) => <StatusBadge value={item.status} /> },
                {
                    title: "Last Modified",
                    extractor: "lastModified",
                    component: (item) => <Chronology data={item} />
                },
                { title: "Actions", extractor: "actions" }
            ]}
            data={questionsQuery?.data?.results?.map((item) => {
                return {
                    ...item,
                    lastModified: item.updatedBy?.dateTime ? formatDateTimeFromNow(item.updatedBy?.dateTime) : formatDateTimeFromNow(item.createdBy?.dateTime),
                    actions: [
                        {
                            title: "View",
                            action: () => navigate(`/admin/quizs/${item.id}`),
                            icon: APP_ICONS.EYE,
                            isView: true
                        },
                        {
                            title: "Edit",
                            action: () => onEditModal(item),
                            icon: APP_ICONS.EDIT,
                            isEdit: true
                        },
                        {
                            title: "Delete",
                            action: handleDelete,
                            icon: APP_ICONS.BIN,
                            isDelete: true
                        }
                    ]
                }
            })}
            loading={questionsQuery?.isLoading}
            totalResults={questionsQuery?.data?.totalResults}
            totalPages={questionsQuery?.data?.totalPages}
            pageNo={query.pageNo}
            pageSize={query.pageSize}
            onQueryChange={(updatedQuery) => setQuery({ ...query, ...updatedQuery })}
            query={query}
            onRefresh={() => questionsQuery.refetch()}
            isRefreshing={questionsQuery?.isFetching}
        />
    )
}

export default QuestionsList