import { Box, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../components/BasicUI/Buttons/PrimaryButton'
import LectureList from './LectureList'
import LectureForm from './LectureForm'
import APP_ICONS from '../../../config/constants/icons'
import ModalPlayer from '../../../components/VideoComponents/ModalPlayer'

const Lectures = () => {
  const formDisclosure = useDisclosure()
  const playerDisclosure = useDisclosure()
  const [selectedLecture, setSelectedLecture] = React.useState(null)

  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">Lectures</Heading>
        <PrimaryButton onClick={() => {
          formDisclosure.onOpen()
          setSelectedLecture(null)
        }}
          leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
        >
          Create New Lecture
        </PrimaryButton>
      </Flex>
      <LectureList
        onEditModal={(data) => {
          setSelectedLecture(data)
          formDisclosure.onOpen()
        }}
        onWatchModal={(data) => {
          setSelectedLecture(data)
          playerDisclosure.onOpen()
        }}
      />
      <LectureForm disclosure={formDisclosure} data={selectedLecture} />
      <ModalPlayer isOpen={playerDisclosure?.isOpen} onClose={playerDisclosure?.onClose} title={selectedLecture?.name} data={selectedLecture} />
    </Box>
  )
}

export default Lectures