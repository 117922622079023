import React from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../../components/Forms/FormDrawer'
import { Divider, Heading, SimpleGrid, VStack } from '@chakra-ui/react'
import FormInput from '../../../../components/Forms/FormInput'
import FormSwitch from '../../../../components/Forms/FormSwitch'
import { STATUS } from '../../../../config/constants/enums'
import { useQueryClient } from '@tanstack/react-query'
import { useUpdateZoomSettings } from '../../../../config/query/settingQuery'


const ZoomForm = ({ disclosure, index, list }) => {
  const queryClient = useQueryClient()
  const updateZoomSettingsQuery = useUpdateZoomSettings()

  const {
    handleSubmit,
    control,
    reset: resetForm,
    getValues: getFormValues,
    formState: { errors },
  } = useForm({
    values: { ...list?.at(index) }
  })


  const onSubmit = (values) => {
    const updatedList = [...list]
    updatedList[index] = values
    updateZoomSettingsQuery.mutateAsync(updatedList)
      .then(() => {
        resetForm(Object.keys(getFormValues()).map((item) => ({ [item]: "" })))
        disclosure.onClose()
        queryClient.invalidateQueries(["zoomSettings"])
      })
  }

  return (
    <FormDrawer
      title={"Edit Zoom Account"}
      disclosure={disclosure}
      isSubmitting={updateZoomSettingsQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      containerProps={{ maxW: "50rem" }}
    >
      <VStack spacing={3} align={"stretch"}>
        <Heading fontSize={"20px"}>Meta</Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>

          <FormInput
            label={"Account Name"}
            control={control}
            errors={errors}
            id="accountName"
            required={true}
            placeholder="Enter account name"
            rules={{
              minLength: {
                value: 3,
                message: "Account name must be at least 3 characters"
              }
            }}
          />

          <FormInput
            label={"Account ID"}
            control={control}
            errors={errors}
            id="accountId"
            required={true}
            placeholder="Enter account id"
          />

        </SimpleGrid>

        <Divider />

        <Heading fontSize={"20px"}>SSO</Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
          <FormInput
            label={"SSO Auth Client ID"}
            control={control}
            errors={errors}
            id="ssoAuth.clientId"
            required={true}
            placeholder="Enter sso auth client id"
          />

          <FormInput
            label={"SSO Auth Client Secret"}
            control={control}
            errors={errors}
            id="ssoAuth.clientSecret"
            required={true}
            placeholder="Enter sso auth client secret"
          />

          <FormInput
            label={"SSO Auth Event Secret Token"}
            control={control}
            errors={errors}
            id="ssoAuth.eventSecretToken"
            required={true}
            placeholder="Enter ss auth event secret token"
          />
        </SimpleGrid>

        <Divider />

        <Heading fontSize={"20px"}>SDK</Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>

          <FormInput
            label={"Meeting SDK Client ID"}
            control={control}
            errors={errors}
            id="meetingSDK.clientId"
            required={true}
            placeholder="Enter meeting sdk client id"
          />

          <FormInput
            label={"Meeting SDK Client Secret"}
            control={control}
            errors={errors}
            id="meetingSDK.clientSecret"
            required={true}
            placeholder="Enter meeting sdk client secret"
          />

          <FormInput
            label={"Meeting SDK Event Secret Token"}
            control={control}
            errors={errors}
            id="meetingSDK.eventSecretToken"
            required={true}
            placeholder="Enter meeting sdk event secret token"
          />

        </SimpleGrid>
      </VStack>
    </FormDrawer>
  )
}

export default ZoomForm
