import React, { useCallback, useState } from 'react'
import APP_ICONS from '../../../config/constants/icons'
import { useDeleteChat, useChats } from '../../../config/query/chatQuery'
import { formatDateTime, formatDateTimeFromNow } from '../../../config/helpers/dateHelper'
import { makeInitialQueryObject } from '../../../config/helpers/queryHelper'
import { useNavigate } from 'react-router'
import { Flex, Heading, Input, VStack, Icon, InputLeftElement, InputGroup, Divider, Image, Button } from '@chakra-ui/react'
import MenuDropdown from '../../../components/Forms/MenuDropdown'
import ChatItem from './ChatItem'
import IMAGES from '../../../config/constants/images'
import ChatListSkeleton from '../../../components/Skeletons/ChatListSkeleton'
import sortOrders from '../../../config/constants/sortOrders'
import _ from "lodash"

const ChatList = () => {

    const navigate = useNavigate()
    const [query, setQuery] = useState(
        makeInitialQueryObject({
            currentQuery: true, injectParams: {
                pageNo: 1,
                pageSize: 20,
                sortBy: "lastMessage",
                sortOrder: sortOrders.DESC
            }
        })
    )

    const chatQuery = useChats(query)

    const handleQueryChange = (updatedQuery) => {
        setQuery({ ...query, ...updatedQuery })
    }

    const debounceQuery = useCallback(_.debounce(handleQueryChange, 250), []);

    return (
        <VStack w="full" align="stretch" spacing={2}>
            <Flex justifyContent="space-between" alignItems="center">
                <Heading as="h2" fontSize={26} fontWeight="bold">Chats</Heading>
                <Button
                    minW="100px"
                    size="sm"
                    leftIcon={
                        <Icon
                            as={APP_ICONS.REFRESH}
                            fontSize={"20px"}
                        />
                    }
                    isLoading={chatQuery.isFetching}
                    onClick={chatQuery.refetch}
                >Refresh</Button>
            </Flex>
            <InputGroup size="sm" variant='filled'>
                <InputLeftElement pointerEvents='none' children={<Icon as={APP_ICONS.SEARCH} />} />
                <Input onChange={(e) => debounceQuery({ searchQuery: e.target.value })} type="search" variant='filled' placeholder="Search chats..." />
            </InputGroup>

            <VStack divider={<Divider />} spacing={0} h="calc(100vh - 175px)" overflow="auto">
                {chatQuery.isLoading && <ChatListSkeleton />}
                {!chatQuery.data?.totalResults && !chatQuery.isLoading && (
                    <Image src={IMAGES.NO_RESULTS} maxW="180px" m="auto" pb="150px" />
                )}
                {chatQuery.data?.results?.map((item, index) => <ChatItem key={index} chatItem={item} />)}
            </VStack>
        </VStack>
    )
}

export default ChatList

