import React from 'react'
import { Modal, ModalOverlay, Box, ModalContent, ModalHeader, Avatar, ModalBody, ModalFooter, Flex, HStack, IconButton, Icon, useDisclosure, Tooltip, Button, Text, TabList, Tabs, Tab, TabPanels, TabPanel, Badge } from '@chakra-ui/react'
import APP_ICONS from "../../../config/constants/icons"
import TableInfoPopover from '../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'

const EmailLogView = ({ disclosure, emailLog, containerProps }) => {
    const { isOpen, onClose } = disclosure
    const expandDisclosure = useDisclosure()
    return (
        <Modal
            size={expandDisclosure.isOpen ? "full" : "2xl"}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            motionPreset='none'
        >
            <ModalOverlay />
            <ModalContent maxH={{ base: "unset", md: "calc(100vh - 50px)" }} overflow={"auto"} {...containerProps} rounded="sm" maxW={expandDisclosure.isOpen ? "full" : "75rem"}>

                <Flex align="center" justify="space-between">
                    <ModalHeader minW="50%">{emailLog?.name}</ModalHeader>
                    <HStack spacing={5} px={4}>
                        <Tooltip label={expandDisclosure.isOpen ? "Collapse" : "Expand"} aria-label="A tooltip">
                            <IconButton onClick={expandDisclosure.onToggle} variant={"ghost"} size={"sm"} aria-label="close" icon={<Icon as={APP_ICONS.EXPAND} />} />
                        </Tooltip>
                        <Tooltip label={"Close"} aria-label="A tooltip">
                            <IconButton onClick={onClose} variant={"ghost"} size={"sm"} aria-label="Close" icon={<Icon as={APP_ICONS.CLOSE} />} />
                        </Tooltip>
                    </HStack>
                </Flex>

                <ModalBody>
                    <Tabs defaultIndex={0} isLazy>
                        <TabList>
                            <Tab>Preview</Tab>
                            <Tab>Recipients </Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Flex>
                                    <Avatar size="md" name={emailLog?.from?.name} src={emailLog?.avatar} />
                                    <Flex direction="column" ml={4}>
                                        <Text fontWeight="bold">{emailLog?.from?.name}</Text>
                                        <Text>{emailLog?.from?.email}</Text>
                                    </Flex>
                                </Flex>

                                <Box mt={4} dangerouslySetInnerHTML={{ __html: emailLog?.body }} />
                            </TabPanel>
                            <TabPanel>
                                {emailLog?.to?.map((to, index) => <Badge key={index} mr={2} variant="solid" >{to.email}</Badge>)}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                </ModalBody>


                <ModalFooter>
                    <Button variant='outline' mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>

            </ModalContent>
        </Modal>
    )
}

export default EmailLogView