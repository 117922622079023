export const USER_TYPES = {
    Student: "63b1c653d936740c34c4c72e",
    Teacher: "63b1c64bd936740c34c4c72d",
    Admin: "63b1c642d936740c34c4c72c"
}

export const STATUS = {
    ACTIVE: 1,
    INACTIVE: 2
}

export const RESULT_STATUS = {
    PASS: "Pass",
    FAIL: "Fail"
}

export const COHORT_CONTENT_TYPES = {
    NOTES: "Notes",
    QUIZ: "Quizz",
    LECTURES: "Lecture",
}

// export const APP_PERMISSIONS = {
//     User: {
//         GetAll: "User",
//         Export: "User.Export",
//         GetOne: "User.GetOne",
//         Update: "User.Update",
//         Create: "User.Create",
//         Delete: "User.Delete",
//         SaveFilter: "User.SaveFilter",
//         DeleteFilter: "User.DeleteFilter"
//     },
//     UserRole: {
//         GetAll: "UserRole.GetAll",
//         GetOne: "UserRole.GetOne",
//         Update: "UserRole.Update",
//         Create: "UserRole.Create",
//         Delete: "UserRole.Delete",
//         Clone: "UserRole.Clone",
//         GetPermissions: "UserRole.GetPermissions",
//         SaveFilter: "UserRole.SaveFilter",
//         DeleteFilter: "UserRole.DeleteFilter"
//     },
//     UserDevices: {
//         GetAll: "UserDevices.GetAll",
//         GetOne: "UserDevices.GetOne",
//         Update: "UserDevices.Update",
//         Create: "UserDevices.Create",
//         Delete: "UserDevices.Delete",
//         SaveFilter: "UserDevices.SaveFilter",
//         DeleteFilter: "UserDevices.DeleteFilter"
//     },
//     UserCohort: {
//         GetAll: "UserCohort.GetAll",
//         GetOne: "UserCohort.GetOne",
//         Update: "UserCohort.Update",
//         Create: "UserCohort.Create",
//         Delete: "UserCohort.Delete",
//         SaveFilter: "UserCohort.SaveFilter",
//         DeleteFilter: "UserCohort.DeleteFilter"
//     },
//     Chapter: {
//         GetAll: "Chapter.GetAll",
//         GetOne: "Chapter.GetOne",
//         Update: "Chapter.Update",
//         Create: "Chapter.Create",
//         Delete: "Chapter.Delete",
//         Clone: "Chapter.Clone",
//         SaveFilter: "Chapter.SaveFilter",
//         DeleteFilter: "Chapter.DeleteFilter",
//         UpdateOrder: "Chapter.UpdateOrder"
//     },
//     Cohort: {
//         GetAll: "Cohort.GetAll",
//         GetOne: "Cohort.GetOne",
//         Update: "Cohort.Update",
//         Create: "Cohort.Create",
//         Delete: "Cohort.Delete",
//         Clone: "Cohort.Clone",
//         SaveFilter: "Cohort.SaveFilter",
//         DeleteFilter: "Cohort.DeleteFilter"
//     },
//     CohortChat: {
//         GetAll: "CohortChat.GetAll",
//         GetOne: "CohortChat.GetOne",
//         Update: "CohortChat.Update",
//         Create: "CohortChat.Create",
//         Delete: "CohortChat.Delete",
//         SaveFilter: "CohortChat.SaveFilter",
//         DeleteFilter: "CohortChat.DeleteFilter"
//     },
//     CohortChatMessage: {
//         GetAll: "CohortChatMessage.GetAll",
//         GetOne: "CohortChatMessage.GetOne",
//         Update: "CohortChatMessage.Update",
//         Create: "CohortChatMessage.Create",
//         Delete: "CohortChatMessage.Delete",
//         SaveFilter: "CohortChatMessage.SaveFilter",
//         DeleteFilter: "CohortChatMessage.DeleteFilter"
//     },
//     Note: {
//         GetAll: "Note.GetAll",
//         GetOne: "Note.GetOne",
//         Update: "Note.Update",
//         Create: "Note.Create",
//         Delete: "Note.Delete",
//         Clone: "Note.Clone",
//         SaveFilter: "Note.SaveFilter",
//         DeleteFilter: "Note.DeleteFilter"
//     },
//     CohortContent: {
//         GetAll: "CohortContent.GetAll",
//         GetOne: "CohortContent.GetOne",
//         Update: "CohortContent.Update",
//         Create: "CohortContent.Create",
//         Delete: "CohortContent.Delete",
//         Clone: "CohortContent.Clone",
//         SaveFilter: "CohortContent.SaveFilter",
//         DeleteFilter: "CohortContent.DeleteFilter",
//         UpdateOrder: "CohortContent.UpdateOrder"
//     },
//     Course: {
//         GetAll: "Course.GetAll",
//         GetOne: "Course.GetOne",
//         Update: "Course.Update",
//         Create: "Course.Create",
//         Delete: "Course.Delete",
//         Clone: "Course.Clone",
//         SaveFilter: "Course.SaveFilter",
//         DeleteFilter: "Course.DeleteFilter"
//     },
//     CohortVariant: {
//         GetAll: "CohortVariant.GetAll",
//         GetOne: "CohortVariant.GetOne",
//         Update: "CohortVariant.Update",
//         Create: "CohortVariant.Create",
//         Delete: "CohortVariant.Delete",
//         Clone: "CohortVariant.Clone",
//         SaveFilter: "CohortVariant.SaveFilter",
//         DeleteFilter: "CohortVariant.DeleteFilter"
//     },
//     Lecture: {
//         GetAll: "Lecture.GetAll",
//         GetOne: "Lecture.GetOne",
//         Update: "Lecture.Update",
//         Create: "Lecture.Create",
//         Delete: "Lecture.Delete",
//         Clone: "Lecture.Clone",
//         SaveFilter: "Lecture.SaveFilter",
//         DeleteFilter: "Lecture.DeleteFilter"
//     },
//     Quizz: {
//         GetAll: "Quizz.GetAll",
//         GetOne: "Quizz.GetOne",
//         Update: "Quizz.Update",
//         Create: "Quizz.Create",
//         Delete: "Quizz.Delete",
//         GetQuizzQuestions: "Quizz.GetQuizzQuestions",
//         UpdateQuizzQuestions: "Quizz.UpdateQuizzQuestions",
//         ReadQuizzQuestionsFile: "Quizz.ReadQuizzQuestionsFile",
//         SaveFilter: "Quizz.SaveFilter",
//         DeleteFilter: "Quizz.DeleteFilter"
//     },
//     QuizzResult: {
//         GetAll: "QuizzResult.GetAll",
//         GetOne: "QuizzResult.GetOne",
//         Update: "QuizzResult.Update",
//         Create: "QuizzResult.Create",
//         Delete: "QuizzResult.Delete",
//         SaveFilter: "QuizzResult.SaveFilter",
//         DeleteFilter: "QuizzResult.DeleteFilter"
//     },
//     QuizzQuestion: {
//         GetAll: "QuizzQuestion.GetAll",
//         GetOne: "QuizzQuestion.GetOne",
//         Update: "QuizzQuestion.Update",
//         Create: "QuizzQuestion.Create",
//         Delete: "QuizzQuestion.Delete",
//         ReadQuizzQuestionsFile: "QuizzQuestion.ReadQuizzQuestionsFile",
//         DownloadQuizzQuestionsFile: "QuizzQuestion.DownloadQuizzQuestionsFile",
//         AutoSelectQuizzQuestions: "QuizzQuestion.AutoSelectQuizzQuestions",
//         SaveFilter: "QuizzQuestion.SaveFilter",
//         DeleteFilter: "QuizzQuestion.DeleteFilter"
//     },
//     Meeting: {
//         GetAll: "Meeting.GetAll",
//         GetOne: "Meeting.GetOne",
//         GetJwtSignature: "Meeting.GetJwtSignature",
//         Update: "Meeting.Update",
//         Create: "Meeting.Create",
//         Delete: "Meeting.Delete",
//         Clone: "Meeting.Clone",
//         SaveFilter: "Meeting.SaveFilter",
//         DeleteFilter: "Meeting.DeleteFilter"
//     },
//     Qualification: {
//         GetAll: "Qualification.GetAll",
//         GetOne: "Qualification.GetOne",
//         Update: "Qualification.Update",
//         Create: "Qualification.Create",
//         Delete: "Qualification.Delete",
//         Clone: "Qualification.Clone",
//         SaveFilter: "Qualification.SaveFilter",
//         DeleteFilter: "Qualification.DeleteFilter"
//     },
//     Issue: {
//         GetAll: "Issue.GetAll",
//         GetOne: "Issue.GetOne",
//         Update: "Issue.Update",
//         Create: "Issue.Create",
//         Delete: "Issue.Delete",
//         Clone: "Issue.Clone",
//         SaveFilter: "Issue.SaveFilter",
//         DeleteFilter: "Issue.DeleteFilter"
//     },
//     CohortVariantType: {
//         GetAll: "CohortVariantType.GetAll",
//         GetOne: "CohortVariantType.GetOne",
//         Update: "CohortVariantType.Update",
//         Create: "CohortVariantType.Create",
//         Delete: "CohortVariantType.Delete",
//         Clone: "CohortVariantType.Clone",
//         SaveFilter: "CohortVariantType.SaveFilter",
//         DeleteFilter: "CohortVariantType.DeleteFilter"
//     },
//     Media: {
//         GetAll: "Media.GetAll",
//         GetOne: "Media.GetOne",
//         Update: "Media.Update",
//         Create: "Media.Create",
//         Delete: "Media.Delete",
//         SaveFilter: "Media.SaveFilter",
//         DeleteFilter: "Media.DeleteFilter"
//     },
//     EmailTemplate: {
//         GetAll: "EmailTemplate.GetAll",
//         GetOne: "EmailTemplate.GetOne",
//         Update: "EmailTemplate.Update",
//         Create: "EmailTemplate.Create",
//         Delete: "EmailTemplate.Delete",
//         Clone: "EmailTemplate.Clone",
//         SaveFilter: "EmailTemplate.SaveFilter",
//         DeleteFilter: "EmailTemplate.DeleteFilter"
//     },
//     EmailLog: {
//         GetAll: "EmailLog.GetAll",
//         GetOne: "EmailLog.GetOne",
//         Update: "EmailLog.Update",
//         Create: "EmailLog.Create",
//         Delete: "EmailLog.Delete",
//         SaveFilter: "EmailLog.SaveFilter",
//         DeleteFilter: "EmailLog.DeleteFilter"
//     },
//     Cache: {
//         Purge: "Cache.Purge"
//     },
//     SeedData: {
//         Materialize: "SeedData.Materialize"
//     },
//     Transaction: {
//         GetAll: "Transaction.GetAll",
//         GetOne: "Transaction.GetOne",
//         Update: "Transaction.Update",
//         Delete: "Transaction.Delete",
//         SaveFilter: "Transaction.SaveFilter",
//         DeleteFilter: "Transaction.DeleteFilter"
//     },
//     Account: {
//         FeePay: "Account.FeePay",
//         ArrearsFeePay: "Account.ArrearsFeePay",
//         FeeRefund: "Account.FeeRefund",
//         FeeStatus: "Account.FeeStatus",
//         FeeTransfer: "Account.FeeTransfer",
//         FeeWithdraw: "Account.FeeWithdraw",
//         AmountWithdraw: "Account.AmountWithdraw",
//         GetCohortReport: "Account.GetCohortReport",
//         GetCohortVariantReport: "Account.GetCohortVariantReport"
//     },
//     DeletedItem: {
//         GetAll: "DeletedItem.GetAll",
//         GetOne: "DeletedItem.GetOne",
//         Delete: "DeletedItem.Delete",
//         Restore: "DeletedItem.Restore",
//         SaveFilter: "DeletedItem.SaveFilter",
//         DeleteFilter: "DeletedItem.DeleteFilter"
//     },
//     Setting: {
//         GetAll: "Setting.GetAll",
//         Update: "Setting.Update",
//         Create: "Setting.Create",
//         Delete: "Setting.Delete",
//         Clone: "Setting.Clone",
//         SaveFilter: "Setting.SaveFilter",
//         DeleteFilter: "Setting.DeleteFilter"
//     }
// }

export const APP_PERMISSIONS = {
    "User": {
        "GetAll": "GetAll",
        "Export": "Export",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "UserRole": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "GetPermissions": "GetPermissions",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "UserDevices": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "UserCohort": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "Chapter": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter",
        "UpdateOrder": "UpdateOrder"
    },
    "Cohort": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "CohortChat": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "CohortChatMessage": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "Note": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "CohortContent": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter",
        "UpdateOrder": "UpdateOrder"
    },
    "Course": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "CohortVariant": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "Lecture": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "Quizz": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "GetQuizzQuestions": "GetQuizzQuestions",
        "UpdateQuizzQuestions": "UpdateQuizzQuestions",
        "ReadQuizzQuestionsFile": "ReadQuizzQuestionsFile",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "QuizzResult": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "QuizzQuestion": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "ReadQuizzQuestionsFile": "ReadQuizzQuestionsFile",
        "DownloadQuizzQuestionsFile": "DownloadQuizzQuestionsFile",
        "AutoSelectQuizzQuestions": "AutoSelectQuizzQuestions",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "Meeting": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "GetJwtSignature": "GetJwtSignature",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "Qualification": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "Issue": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "CohortVariantType": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "Media": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "EmailTemplate": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "EmailLog": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "Cache": {
        "Purge": "Purge"
    },
    "SeedData": {
        "Materialize": "Materialize"
    },
    "Transaction": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Update": "Update",
        "Delete": "Delete",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "Account": {
        "FeePay": "FeePay",
        "ArrearsFeePay": "ArrearsFeePay",
        "FeeRefund": "FeeRefund",
        "FeeStatus": "FeeStatus",
        "FeeTransfer": "FeeTransfer",
        "FeeWithdraw": "FeeWithdraw",
        "AmountWithdraw": "AmountWithdraw",
        "GetCohortReport": "GetCohortReport",
        "GetCohortVariantReport": "GetCohortVariantReport"
    },
    "DeletedItem": {
        "GetAll": "GetAll",
        "GetOne": "GetOne",
        "Delete": "Delete",
        "Restore": "Restore",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    },
    "Setting": {
        "GetAll": "GetAll",
        "Update": "Update",
        "Create": "Create",
        "Delete": "Delete",
        "Clone": "Clone",
        "SaveFilter": "SaveFilter",
        "DeleteFilter": "DeleteFilter"
    }
}

export const BASE_MODULES = {
    "User": "User",
    "UserRole": "UserRole",
    "UserDevices": "UserDevices",
    "UserCohort": "UserCohort",
    "Chapter": "Chapter",
    "Cohort": "Cohort",
    "CohortChat": "CohortChat",
    "CohortChatMessage": "CohortChatMessage",
    "Note": "Note",
    "CohortContent": "CohortContent",
    "Course": "Course",
    "CohortVariant": "CohortVariant",
    "Lecture": "Lecture",
    "Quizz": "Quizz",
    "QuizzResult": "QuizzResult",
    "QuizzQuestion": "QuizzQuestion",
    "Meeting": "Meeting",
    "Qualification": "Qualification",
    "Issue": "Issue",
    "CohortVariantType": "CohortVariantType",
    "Media": "Media",
    "EmailTemplate": "EmailTemplate",
    "EmailLog": "EmailLog",
    "Transaction": "Transaction",
    "DeletedItem": "DeletedItem",
    "Setting": "Setting"
}