import React from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../components/Forms/FormDrawer'
import { Avatar, Button, chakra, Flex, Image, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Wrap, WrapItem, useColorMode } from '@chakra-ui/react'
import FormInput from '../../../components/Forms/FormInput'
import FormTextarea from '../../../components/Forms/FormTextarea'
import FormPhoneInput from '../../../components/Forms/FormPhoneInput'
import FormSwitch from '../../../components/Forms/FormSwitch'
import { EMAIL_REGEX, PHONE_REGEX } from '../../../config/constants/regexConstants'
import { useCreateUser, useUpdateUser } from '../../../config/query/userQuery'
import FormSearchSelect from '../../../components/Forms/FormSearchSelect'
import { useRolesOptions } from '../../../config/query/roleQuery'
import { makeSelectList } from '../../../config/helpers/selectListHelper'
import { STATUS } from '../../../config/constants/enums'
import { useQueryClient } from '@tanstack/react-query'
import { useDevices } from '../../../config/query/deviceQuery'
import DeviceBox from '../../../components/BasicUI/DataBoxes/DeviceBox'
import IMAGES from '../../../config/constants/images'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import { generateString } from '../../../config/helpers/stringHelper'

const UserForm = ({ disclosure, data }) => {
    const { colorMode } = useColorMode()
    const queryClient = useQueryClient()
    const rolesOptionsQuery = useRolesOptions()
    const userDevicesQuery = useDevices({ users: data?.id })

    const createUserQuery = useCreateUser()
    const updateUserQuery = useUpdateUser(data?.id)

    const {
        handleSubmit,
        control,
        setValue,
        reset: resetForm,
        getValues: getFormValues,
        watch,
        formState: { errors },
    } = useForm({
        values: {
            ...data,
            status: (data?.id && data?.status === STATUS.INACTIVE) ? false : true,
            cohorts: data?.cohorts || [],
        },
    })

    const fields = watch()

    const onSubmit = (values) => {
        let formMutate = data?.id ? updateUserQuery.mutateAsync : createUserQuery.mutateAsync

        formMutate({
            ...values,
            status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
            allowedDevices: Number(values.allowedDevices),
        }).then(() => {
            resetForm(Object.keys(getFormValues()).map((item) => ({ [item]: "" })))
            queryClient.invalidateQueries(["users"])
            disclosure.onClose()
        }).catch((error) => console.error(error))
    }

    const handleGeneratePassword = () => {
        setValue("password", generateString(8))
    }

    return (
        <FormDrawer
            title={data ? "Edit User" : "Add User"}
            disclosure={disclosure}
            isSubmitting={createUserQuery.isLoading || updateUserQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
        >

            <Tabs defaultIndex={0} isLazy>
                <TabList>
                    <Tab>Information</Tab>
                    <Tab>Devices</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Wrap columns={{ base: 1, md: 2 }} spacing={5}>
                            <WrapItem w="87%">
                                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} w="full">
                                    <FormInput
                                        label={"Name"}
                                        control={control}
                                        errors={errors}
                                        id="name"
                                        required={true}
                                        placeholder="Enter user name"
                                        rules={{
                                            minLength: {
                                                value: 3,
                                                message: "Name must be at least 3 characters"
                                            }
                                        }}
                                    />
                                    <FormInput
                                        label={"Father Name"}
                                        control={control}
                                        errors={errors}
                                        id="fatherName"
                                        required={true}
                                        placeholder="Enter father name"
                                        rules={{
                                            minLength: {
                                                value: 3,
                                                message: "Father name must be at least 3 characters"
                                            }
                                        }}
                                    />
                                </SimpleGrid>
                            </WrapItem>

                            <WrapItem>
                                <Avatar name={fields.name} src={fields.profilePictureUrl} size="xl" />
                            </WrapItem>
                        </Wrap>

                        <SimpleGrid columns={{ base: 1, md: data ? 3 : 4 }} mt={5} spacing={5}>

                            <FormPhoneInput
                                id={"phoneNumber"}
                                label="Phone Number"
                                type="tel"
                                placeholder="Enter your phone number"
                                required={true}
                                errors={errors}
                                control={control}
                                setCountryCodeValue={(value) => {
                                    setValue("countryCode", value)
                                }}
                                countryCodeValue={getFormValues("countryCode")}
                                rules={{
                                    pattern: {
                                        value: PHONE_REGEX,
                                        message: "Invalid phone number"
                                    }
                                }}
                            />

                            <FormSearchSelect
                                id="roleId"
                                label="Role"
                                placeholder={'Select Role'}
                                required={true}
                                errors={errors}
                                control={control}
                                options={makeSelectList(rolesOptionsQuery?.data)}
                            />

                            <FormInput
                                id={"email"}
                                label="Email"
                                placeholder="someone@example.com"
                                required={true}
                                errors={errors}
                                control={control}
                                rules={{
                                    pattern: {
                                        value: EMAIL_REGEX,
                                        message: "Invalid email address"
                                    }
                                }}
                            />

                            {!data && (
                                <>
                                    <FormInput
                                        id={"password"}
                                        label="Password"
                                        type="password"
                                        placeholder="Create strong password"
                                        required={true}
                                        errors={errors}
                                        control={control}
                                        secure={true}
                                        labelProps={{
                                            mr: 0
                                        }}
                                        labelComponent={
                                            <Flex alignItems="center" justify={"space-between"}>
                                                <Text>Password<chakra.span color={getColor(colorKeys.danger, colorMode)}>*</chakra.span></Text>
                                                <Button onClick={handleGeneratePassword} variant="solid" size="xs">Generate</Button>
                                            </Flex>
                                        }
                                    />

                                </>
                            )}
                        </SimpleGrid>

                        <SimpleGrid columns={{ base: 1 }} mt={5} spacing={5}>

                            <FormInput
                                label={"Address"}
                                control={control}
                                errors={errors}
                                id="address"
                                placeholder="Enter address"
                            />

                        </SimpleGrid>

                        <SimpleGrid columns={{ base: 1, md: 2 }} mt={5} spacing={5}>

                            <FormInput
                                id={"country"}
                                label="Country"
                                placeholder="Enter your country"
                                errors={errors}
                                control={control}
                            />

                            <FormInput
                                id={"city"}
                                label="City"
                                placeholder="Enter your city"
                                errors={errors}
                                control={control}
                            />

                        </SimpleGrid>

                        <FormTextarea
                            label={"Remarks"}
                            placeholder="Enter remarks"
                            control={control}
                            errors={errors}
                            id="description"
                            containerProps={{
                                mt: 5
                            }}
                        />

                        <SimpleGrid columns={{ base: 1, md: 2 }} mt={5} spacing={5}>

                            <FormInput
                                id={"allowedDevices"}
                                label="Number of Allowed Devices"
                                type="number"
                                min={1}
                                placeholder="Enter number of allowed devices"
                                errors={errors}
                                control={control}
                            />

                            <FormSwitch
                                label={"Status"}
                                control={control}
                                errors={errors}
                                id="status"
                            />

                        </SimpleGrid>
                    </TabPanel>

                    <TabPanel>

                        {!userDevicesQuery.data?.totalResults && !userDevicesQuery.isLoading && (
                            <Flex justifyContent="center" alignItems="center" h="100%" flexDirection="column">
                                <Image src={IMAGES.NO_RESULTS} maxW="250px" />
                                <Text color={getColor(colorKeys.secondaryText, colorMode)} mt={0}>There are no registered devices of this user</Text>
                            </Flex>
                        )}
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                            {userDevicesQuery.data?.results?.map((item, index) => (
                                <DeviceBox key={index} number={index + 1} device={item} />
                            ))}
                        </SimpleGrid>
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </FormDrawer>
    )
}

export default UserForm