import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../../components/Forms/FormDrawer'
import { Box, SimpleGrid, VStack } from '@chakra-ui/react'
import FormInput from '../../../../components/Forms/FormInput'
import FormSwitch from '../../../../components/Forms/FormSwitch'
import { useCreateNotes, useUpdateNotes } from '../../../../config/query/notesQuery'
import { STATUS, USER_TYPES } from '../../../../config/constants/enums'
import { useQueryClient } from '@tanstack/react-query'
import FormSearchSelect from '../../../../components/Forms/FormSearchSelect'
import { makeSelectList } from '../../../../config/helpers/selectListHelper'
import { useCohortOptions } from '../../../../config/query/cohortQuery'
import { useChapterOptions } from '../../../../config/query/chapterQuery'
import { useUserOptions } from '../../../../config/query/userQuery'
import RichTextEditor from '../../../../components/Forms/RichTextEditor'

const NotesForm = ({ disclosure, data, chapterId, cohortId }) => {
  const queryClient = useQueryClient()
  const createNotesQuery = useCreateNotes()
  const updateNotesQuery = useUpdateNotes(data?.id)

  const [query, setQuery] = useState({
    chapterOptionsQuery: {
      cohorts: data?.cohortId,
    },
  })

  const cohortOptionsQuery = useCohortOptions({})
  const chapterOptionsQuery = useChapterOptions(query?.chapterOptionsQuery)
  const teacherOptions = useUserOptions({ roles: USER_TYPES.Teacher })

  const {
    handleSubmit,
    control,
    reset: resetForm,
    getValues: getFormValues,
    formState: { errors },
    watch,
  } = useForm({
    values: {
      ...data,
      status: (data?.id && data?.status === STATUS.INACTIVE) ? false : true,
      cohortId: cohortId,
      chapterId: chapterId,
    },
  })


  const onSubmit = (values) => {
    let formMutate = data?.id ? updateNotesQuery.mutateAsync : createNotesQuery.mutateAsync
    formMutate({
      ...values,
      status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
    })
      .then(() => {
        resetForm(Object.keys(getFormValues()).map((item) => ({ [item]: "" })))
        disclosure.onClose()
        queryClient.invalidateQueries(["notes"])
      })
  }

  const handleFormChangeEffects = (name, value) => {
    const updatedQuery = { ...query[name], ...value }
    setQuery({ ...query, [name]: updatedQuery })
  }

  watch((data, { name, type }) => {
    if (type === "change") {
      if (name === "cohortId") {
        handleFormChangeEffects("chapterOptionsQuery", { cohorts: data[name] })
      }
    }
  })

  useEffect(() => {
    handleFormChangeEffects("chapterOptionsQuery", { cohorts: data?.cohortId })
  }, [data?.cohortId])



  return (
    <FormDrawer
      title={data ? "Edit Notes" : "Add Notes"}
      disclosure={disclosure}
      isSubmitting={createNotesQuery.isLoading || updateNotesQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      containerProps={{
        minW: { base: "100%", md: "80%" },
      }}
    >

      <VStack spacing={5} align="stretch">
        <SimpleGrid columns={{ base: 1, md: 1 }} spacing={5}>

          <FormInput
            label={"Name"}
            control={control}
            errors={errors}
            id="name"
            required={true}
            placeholder="Enter notes name"
            rules={{
              minLength: {
                value: 3,
                message: "Name must be at least 3 characters"
              }
            }}
          />

        </SimpleGrid>

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5} >

          {!cohortId && (<FormSearchSelect
            id="cohortId"
            label="Cohort"
            placeholder={'Select Cohort'}
            required={true}
            errors={errors}
            control={control}
            options={makeSelectList(cohortOptionsQuery?.data)}
          />)}

          {!chapterId && (<FormSearchSelect
            id="chapterId"
            label="Chapter"
            placeholder={'Select Chapter'}
            required={true}
            errors={errors}
            control={control}
            options={makeSelectList(chapterOptionsQuery?.data)}
          />)}

          <FormSearchSelect
            multiple={true}
            id="teacherIds"
            label="Teachers"
            placeholder={'Select Teachers'}
            required={true}
            errors={errors}
            control={control}
            options={makeSelectList(teacherOptions?.data)}
          />

          <FormSwitch
            label={"Status"}
            control={control}
            errors={errors}
            id="status"
          />


        </SimpleGrid>

        <Box>
          <RichTextEditor
            label="Content"
            placeholder="Enter notes content..."
            control={control}
            errors={errors}
            id="note.body"
            required={true}
          />
        </Box>
      </VStack>

    </FormDrawer>
  )
}

export default NotesForm