import { HStack, Skeleton, VStack } from "@chakra-ui/react";
import React from "react";

const ContentSkeleton = ({ isLoading, count = 3 }) => {
  if (!isLoading) return null;
  return (
    <VStack
      p="5px"
      borderWidth={"1px"}
      borderTopWidth={0}
      spacing={2}
      align="stretch"
    >
      {new Array(count).fill(null).map((item, index) => (
        <HStack key={index}>
          <Skeleton h="70px" w="80px" />
          <VStack spacing={1} align="stretch">
            <Skeleton h="15px" w="250px" />
            <Skeleton h="18px" w="180px" />
            <Skeleton h="15px" w="120x" />
          </VStack>
        </HStack>
      ))}
    </VStack>
  );
};

export default ContentSkeleton;
