import React from 'react'
import { Checkbox, CheckboxGroup, FormControl, FormErrorMessage, FormLabel, Wrap, WrapItem } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'

const FormCheckbox = ({ options, defaultChecked, label, errors = {}, id, required = false, control, rules }) => {
    if (required) {
        required = `${label} is required`
    }
    return (
        <Controller
            control={control}
            name={id}
            rules={{
                required: required,
                ...rules
            }}
            render={({ field: { onChange, onBlur, value, ref, ...rest } }) => {
                if (defaultChecked && !value) {
                    onChange(defaultChecked)
                }
                return (
                    <FormControl
                        isInvalid={errors[id]}
                    >
                        <FormLabel htmlFor={id}>{label}</FormLabel>
                        <CheckboxGroup
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            defaultValue={defaultChecked}
                            ref={ref}
                            {...rest}
                        >

                            <Wrap spacing={[1, 5]} maxW={"full"}>
                                {options?.map((item, index) =>
                                    <WrapItem key={index}>
                                        <Checkbox key={index} value={item.value || item}>{item.label || item}</Checkbox>
                                    </WrapItem>
                                )}
                            </Wrap>
                        </CheckboxGroup>
                        <FormErrorMessage>
                            {errors[id] && errors[id].message}
                        </FormErrorMessage>
                    </FormControl>
                )
            }}
        />
    )
}

export default FormCheckbox