import APP_ICONS from "./icons"

const syllabusSubsiderOptions = [
    {
        icon: APP_ICONS.CATEGORIES_TAG,
        title: "Cohorts",
        link: "/admin/syllabus"
    },
    {
        icon: APP_ICONS.CATEGORIES_TAG,
        title: "Chapters",
        link: "/admin/syllabus/chapters"
    },
    {
        icon: APP_ICONS.ARCHIVE,
        title: "Notes",
        link: "/admin/syllabus/notes"
    },
    {
        icon: APP_ICONS.EXAMS_LIST,
        title: "Courses",
        link: "/admin/syllabus/courses"
    },
    {
        icon: APP_ICONS.ARCHIVE,
        title: "Qualification",
        link: "/admin/syllabus/qualifications"
    },
]

export default syllabusSubsiderOptions