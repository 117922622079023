import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../../components/Forms/FormDrawer'
import { Heading, SimpleGrid, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import FormInput from '../../../../components/Forms/FormInput'
import FormSwitch from '../../../../components/Forms/FormSwitch'
import { useCreateRole, useUpdateRole } from '../../../../config/query/roleQuery'
import { usePermissions } from '../../../../config/query/roleQuery'
import FormSearchSelect from '../../../../components/Forms/FormSearchSelect'
import { joinWithDot, makeSelectList, seprerateViaDot } from '../../../../config/helpers/selectListHelper'
import { STATUS } from '../../../../config/constants/enums'
import { useQueryClient } from '@tanstack/react-query'
import FormCheckbox from '../../../../components/Forms/FormCheckbox'
import { breakStringWithCaps } from '../../../../config/helpers/stringHelper'

const RoleForm = ({ disclosure, data }) => {

  const queryClient = useQueryClient()
  const permissionsOptions = usePermissions()
  const createRoleQuery = useCreateRole()
  const updateRoleQuery = useUpdateRole(data?.id)

  const {
    handleSubmit,
    control,
    reset: resetForm,
    getValues: getFormValues,
    watch,
    formState: { errors },
  } = useForm({
    values: {
      ...data,
      permissions: seprerateViaDot(data?.permissions),
      status: (data?.id && data?.status === STATUS.INACTIVE) ? false : true,
      isAdmin: Boolean(data?.id && data?.isAdmin)
    },
  })

  const onSubmit = async (values) => {
    values.permissionNames = joinWithDot(values.permissions)
    let formMutate = data?.id ? updateRoleQuery.mutateAsync : createRoleQuery.mutateAsync
    formMutate({
      ...values,
      status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
    })
      .then(() => {
        resetForm(Object.keys(getFormValues()).map((item) => ({ [item]: "" })))
        queryClient.invalidateQueries(["roles"])
        disclosure.onClose()
      })

  }

  const permissionOptions = useMemo(() => {
    return seprerateViaDot(permissionsOptions?.data)
  })

  return (
    <FormDrawer
      title={data ? "Edit Role" : "Add Role"}
      disclosure={disclosure}
      isSubmitting={createRoleQuery.isLoading || updateRoleQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
    >

      <VStack spacing={5} align="stretch">

        <SimpleGrid columns={1} spacing={5}>
          <FormInput
            label={"Name"}
            control={control}
            errors={errors}
            id="name"
            required={true}
            placeholder="Enter role name"
            rules={{
              minLength: {
                value: 3,
                message: "Name must be at least 3 characters"
              }
            }}
          />

        </SimpleGrid>

        <Wrap spacing={5} ml={2}>
          <WrapItem>
            <FormSwitch
              label={"Is Admin?"}
              control={control}
              errors={errors}
              id="isAdmin"
            />
          </WrapItem>
          <WrapItem>
            <FormSwitch
              label={"Status"}
              control={control}
              errors={errors}
              id="status"
            />
          </WrapItem>
        </Wrap>

        {!watch("isAdmin") && (
          <React.Fragment>
            <Heading size="sm" fontWeight="semibold" color="gray.600" mt="5">
              Select Permissions
            </Heading>

            <VStack columns={{ base: 1, md: 4 }} spacing={5}>
              {permissionOptions && Object.keys(permissionOptions).map((item, index) => {
                return (
                  <FormCheckbox
                    key={index}
                    control={control}
                    id={`permissions.${item}`}
                    defaultChecked={seprerateViaDot(data?.permissionNames)?.[item]}
                    label={breakStringWithCaps(item)}
                    options={makeSelectList(permissionOptions[item], null, null, true)}
                    errors={errors}
                  />
                )
              })}
            </VStack>
          </React.Fragment>
        )}

      </VStack>

    </FormDrawer >
  )
}

export default RoleForm