import { Card, Flex, HStack, Icon, IconButton, Switch, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import APP_ICONS from '../../../config/constants/icons'
import LabelValuePair from '../LabelValuePair'
import { getBrowser, getPlatform } from '../../../config/helpers/deviceHelper'
import { formatDateTime, formatDateTimeFromNow } from '../../../config/helpers/dateHelper'
import { useDeleteDevice, useUpdateDeviceStatus } from '../../../config/query/deviceQuery'
import { useQueryClient } from '@tanstack/react-query'
import DeletePopover from '../DeletePopover'
import { STATUS } from '../../../config/constants/enums'

const DeviceBox = ({ device, number }) => {
    const queryClient = useQueryClient()
    const updateDeviceStatusQuery = useUpdateDeviceStatus()
    const deleteDeviceQuery = useDeleteDevice()

    const updateDeviceStatus = () => {
        const status = device.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE
        console.log(status)
        updateDeviceStatusQuery.mutateAsync({ id: device.id, status })
            .then(() => {
                queryClient.invalidateQueries(['devices'])
            })
    }

    const handleDelete = () => {
        deleteDeviceQuery.mutateAsync(device.id)
            .then(() => {
                queryClient.invalidateQueries(['devices'])
            })

    }


    return (
        <Card p={5}>
            <Flex justifyContent="space-between" alignItems="center" mb={5}>
                <Text fontSize="lg" fontWeight="bold">Device {number}</Text>

                <HStack spacing={2} alignItems="center">
                    <Switch defaultChecked={device.status === STATUS.ACTIVE} onChange={updateDeviceStatus} />
                    <DeletePopover onConfirm={handleDelete}>
                        <IconButton size="xs" colorScheme='red' alignSelf={"flex-end"} icon={<Icon as={APP_ICONS.BIN} />} variant="solid" aria-label="Remove" />
                    </DeletePopover>
                </HStack>

            </Flex>
            <VStack alignItems="flex-start" spacing={2}>
                <LabelValuePair
                    label="Platform"
                    value={true}
                    valueComponent={getPlatform(device)}
                />
                <LabelValuePair
                    label="Browser"
                    value={true}
                    valueComponent={getBrowser(device)}
                />
                <LabelValuePair label="Last Login" value={`${formatDateTime(device?.lastLoginDateTime)} (${formatDateTimeFromNow(device?.lastLoginDateTime)})`} />
            </VStack>
        </Card>
    )
}

export default DeviceBox