import React, { useState } from 'react'
import CustomTable from '../../../components/BasicUI/CustomTable'
import { useDeleteLecture, useLectures, useLecturesFacet } from '../../../config/query/lectureQuery'
import { formatDateTime, formatDateTimeFromNow } from '../../../config/helpers/dateHelper'
import APP_ICONS from '../../../config/constants/icons'
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge'
import Chronology from '../../../components/BasicUI/DataBoxes/Chronology'
import TableInfoPopover from '../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'
import { getFilters } from '../../../config/helpers/filterHelper'
import AvatarStack from '../../../components/BasicUI/DataBoxes/AvatarStack'
import { Text } from '@chakra-ui/react'

const LectureList = ({ onEditModal, onWatchModal }) => {

    const [query, setQuery] = useState({
        pageNo: 1,
        pageSize: 20,
    })

    const lectureQuery = useLectures(query)
    const lectureFacetQuery = useLecturesFacet()
    const deleteLectureQuery = useDeleteLecture()

    const handleDelete = (id) => {
        deleteLectureQuery.mutateAsync(id)
            .then((response) => {
                lectureQuery.refetch()
                console.log(`Lecture deleted successfully: ${response}`)
            })
            .catch((error) => {
                console.log(`Error while deleting Lecture: ${error}`)
            })
    }

    return (
        <CustomTable
            containerProps={{ mt: 5 }}
            searchPlaceholder="Search lectures"
            filters={getFilters(lectureFacetQuery?.data)}
            head={[
                {
                    title: "Name",
                    extractor: "name",
                    align: "left",
                    isSortable: true,
                    component: (item) => (
                        <TableInfoPopover
                            data={item}
                            onEdit={(item) => onEditModal(item)}
                            onDelete={(id) => handleDelete(id)}
                            triggerOnClick={() => onEditModal(item)}
                        >
                            <LabelValuePair label="Description" value={item.description} />
                            <LabelValuePair label="Chapter" value={item.chapterName} />
                            <LabelValuePair label="Cohort" value={item.cohortName} />
                            <LabelValuePair label="Course" value={item.courseName} />
                            <LabelValuePair label="Qualification" value={item.qualificationName} />
                            <LabelValuePair
                                label="Status"
                                value={true}
                                valueComponent={
                                    <StatusBadge value={item.status} />
                                }
                            />
                            <LabelValuePair label="Created" value={formatDateTime(item.createdBy?.dateTime)} />
                            <LabelValuePair label="Last Updated" value={formatDateTime(item.updateBy?.dateTime)} />
                        </TableInfoPopover>
                    )
                },
                { title: "Chapter", extractor: "chapterName" },
                { title: "Cohort", extractor: "courseName" },
                { title: "Teachers", extractor: "teachers", component: (item) => item.teachers?.length ? <AvatarStack data={item.teachers} /> : <Text fontSize="14px">N/A</Text> },
                { title: "Status", extractor: "status", component: (item) => <StatusBadge value={item.status} /> },
                {
                    title: "Last Modified",
                    extractor: "lastModified",
                    component: (item) => <Chronology data={item} />
                },
                { title: "Actions", extractor: "actions" }
            ]}
            data={lectureQuery?.data?.results?.map((item) => {
                return {
                    ...item,
                    availableStartDateTimeValue: formatDateTime(item.availableStartDateTime),
                    availableEndDateTimeValue: formatDateTime(item.availableEndDateTime),
                    lastModified: item.updatedBy?.dateTime ? formatDateTimeFromNow(item.updatedBy?.dateTime) : formatDateTimeFromNow(item.createdBy?.dateTime),
                    actions: [
                        {
                            title: "Watch",
                            action: () => onWatchModal(item),
                            icon: APP_ICONS.EYE,
                        },
                        {
                            title: "Edit",
                            action: () => onEditModal(item),
                            icon: APP_ICONS.EDIT,
                            isEdit: true
                        },
                        {
                            title: "Delete",
                            action: handleDelete,
                            icon: APP_ICONS.BIN,
                            isDelete: true
                        }
                    ]
                }
            })}
            loading={lectureQuery?.isLoading}
            totalResults={lectureQuery?.data?.totalResults}
            totalPages={lectureQuery?.data?.totalPages}
            pageNo={query.pageNo}
            pageSize={query.pageSize}
            onQueryChange={(updatedQuery) => setQuery({ ...query, ...updatedQuery })}
            query={query}
            onRefresh={() => lectureQuery.refetch()}
            isRefreshing={lectureQuery?.isFetching}
            sortBy={query?.sortBy}
            sortOrder={query?.sortOrder}
        />
    )
}

export default LectureList