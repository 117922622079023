import { Box, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import MeetingList from './MeetingList'
import MeetingForm from './MeetingForm'
import APP_ICONS from '../../../../config/constants/icons'

const Meetings = () => {
    const formDisclosure = useDisclosure()
    const [selectedMeeting, setSelectedMeeting] = React.useState(null)

    return (
        <Box>
            <Flex justify="space-between" align={"center"}>
                <Heading as="h2" fontSize={26} fontWeight="bold">Meetings</Heading>
                <PrimaryButton onClick={() => {
                    formDisclosure.onOpen()
                    setSelectedMeeting(null)
                }}
                    leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
                >
                    Create New Meeting
                </PrimaryButton>
            </Flex>
            <MeetingList
                onEditModal={(data) => {
                    setSelectedMeeting(data)
                    formDisclosure.onOpen()
                }}
            />
            <MeetingForm
                disclosure={formDisclosure}
                data={selectedMeeting}
            />
        </Box>
    )
}

export default Meetings