import React, { useEffect, useState } from 'react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import APP_ICONS from '../../../../config/constants/icons'
import { useDeleteNotes, useNotes, useNotesFacet } from '../../../../config/query/notesQuery'
import { formatDateTime, formatDateTimeFromNow } from '../../../../config/helpers/dateHelper'
import { getFilters } from '../../../../config/helpers/filterHelper'
import LabelValuePair from '../../../../components/BasicUI/LabelValuePair'
import TableInfoPopover from '../../../../components/BasicUI/Popovers/TableInfoPopover'
import StatusBadge from '../../../../components/BasicUI/Badges/StatusBadge'
import Chronology from '../../../../components/BasicUI/DataBoxes/Chronology'
import { makeInitialQueryObject } from '../../../../config/helpers/queryHelper'
import { useNavigate } from 'react-router'
import { Avatar, AvatarGroup, Text } from '@chakra-ui/react'
import AvatarStack from '../../../../components/BasicUI/DataBoxes/AvatarStack'

const NotesList = ({ onEditModal }) => {

    const navigate = useNavigate()

    const [query, setQuery] = useState(
        makeInitialQueryObject({
            currentQuery: true, injectParams: {
                pageNo: 1,
                pageSize: 20,
            }
        })
    )

    const notesQuery = useNotes(query)
    const notesFacetQuery = useNotesFacet()
    const deleteNotesQuery = useDeleteNotes()

    const handleDelete = (id) => {
        deleteNotesQuery.mutateAsync(id)
            .then((response) => {
                notesQuery.refetch()
                console.log(`Notes deleted successfully: ${response}`)
            })
            .catch((error) => {
                console.log(`Error while deleting notes: ${error}`)
            })
    }

    const handleQueryChange = (updatedQuery) => {
        setQuery(updatedQuery)
        navigate(`?${makeInitialQueryObject({
            injectParams: updatedQuery,
            prepareQueryString: true
        })}`)
    }

    return (
        <CustomTable
            containerProps={{ mt: 5 }}
            searchPlaceholder="Search notes"
            filters={getFilters(notesFacetQuery?.data)}
            head={[
                {
                    title: "Name",
                    extractor: "name",
                    align: "left",
                    isSortable: true,
                    component: (item) => (
                        <TableInfoPopover
                            data={item}
                            onEdit={(item) => onEditModal(item)}
                            onDelete={(id) => handleDelete(id)}
                            triggerOnClick={() => onEditModal(item)}
                        >
                            <LabelValuePair label="Cohort Name" value={item.cohortName} />
                            <LabelValuePair label="Chapter Name" value={item.chapterName} />
                            <LabelValuePair
                                label="Teachers"
                                value={Boolean(item.teachers?.length)}
                                valueComponent={
                                    <AvatarGroup size='sm' max={5}>
                                        {item.teachers?.map((teacher, i) => <Avatar key={i} name={teacher.name} src={teacher.profilePicture} />)}
                                    </AvatarGroup>
                                }
                            />
                            <LabelValuePair
                                label="Status"
                                value={true}
                                valueComponent={
                                    <StatusBadge value={item.status} />
                                }
                            />
                            <LabelValuePair label="Created" value={formatDateTime(item.createdBy?.dateTime)} />
                            <LabelValuePair label="Last Updated" value={formatDateTime(item.updateBy?.dateTime)} />
                        </TableInfoPopover>
                    )
                },
                {
                    title: "Cohort Name",
                    extractor: "cohortName",
                },
                {
                    title: "Chapter Name",
                    extractor: "chapterName",
                },
                { title: "Teachers", extractor: "teachers", component: (item) => item.teachers?.length ? <AvatarStack data={item.teachers} /> : <Text fontSize={"14px"}>N/A</Text> },
                {
                    title: "Status",
                    extractor: "status",
                    isSortable: true,
                    component: (item) => <StatusBadge value={item.status} />
                },
                {
                    title: "Last Modified",
                    extractor: "lastModified",
                    component: (item) => <Chronology data={item} />
                },
                {
                    title: "Actions",
                    extractor: "actions"
                }
            ]}
            data={notesQuery?.data?.results?.map((item) => {
                return {
                    ...item,
                    lastModified: item.updatedBy ? formatDateTimeFromNow(item.updatedBy?.dateTime) : formatDateTimeFromNow(item.createdBy?.dateTime),
                    actions: [
                        {
                            title: "Edit",
                            action: () => onEditModal(item),
                            icon: APP_ICONS.EDIT,
                            isEdit: true
                        },
                        {
                            title: "Delete",
                            action: handleDelete,
                            icon: APP_ICONS.BIN,
                            isDelete: true
                        }
                    ]
                }
            })}
            loading={notesQuery?.isLoading}
            totalResults={notesQuery?.data?.totalResults}
            totalPages={notesQuery?.data?.totalPages}
            pageNo={query.pageNo}
            pageSize={query.pageSize}
            onQueryChange={(updatedQuery) => handleQueryChange({ ...query, ...updatedQuery })}
            query={query}
            onRefresh={() => notesQuery.refetch()}
            isRefreshing={notesQuery?.isFetching}
            sortBy={query?.sortBy}
            sortOrder={query?.sortOrder}
        />
    )
}

export default NotesList

