import Windows from '../../assets/images/device-info/windows.svg';
import Mac from '../../assets/images/device-info/macos.svg';
import Opera from '../../assets/images/device-info/opera.svg';
import Chrome from '../../assets/images/device-info/chrome.svg';
import Safari from '../../assets/images/device-info/safari.svg';
import Edge from '../../assets/images/device-info/edge.svg';
import IE from '../../assets/images/device-info/internet-explorer.svg';
import KNOWLY_CLIPBOARD from '../../assets/images/knowly--clipboard.svg';
import KNOWLY_GAME from "../../assets/images/knowly--game.svg";
import KNOWLY_HAT from "../../assets/images/knowly--hat.svg";
import KNOWLY_WINGS from "../../assets/images/knowly--wings.svg"
import KNOWLY_POINTING_LEFT from "../../assets/images/knowly-pointing--left.svg"
import KNOWLY_STATS from "../../assets/images/knowly--stats.svg"
import KNOWLY_TEACH from "../../assets/images/knowly--teach.svg"
import KNOWLY_USERS from "../../assets/images/knowly--users.svg"

const IMAGES = {
    LOGO: require('../../assets/images/evolv-lms-logo.png'),

    PLACEHOLDER: require('../../assets/images/placeholder.png'),
    VIMEO_PLACEHOLDER: require('../../assets/images/vimeo-placeholder.png'),

    //DEVICE INFO
    ANDROID: require('../../assets/images/device-info/android.png'),
    IOS: require('../../assets/images/device-info/ios.png'),
    WINDOWS: Windows,
    MAC: Mac,
    LINUX: require('../../assets/images/device-info/linux.png'),

    OPERA: Opera,
    CHROME: Chrome,
    FIREFOX: require('../../assets/images/device-info/firefox.png'),
    SAFARI: Safari,
    EDGE: Edge,
    IE: IE,

    ANDROID_PHONE: require('../../assets/images/device-info/android-phone.png'),
    IPHONE: require('../../assets/images/device-info/iphone.png'),

    GIRL_CHAT: require("../../assets/images/girl-chat.png"),
    NO_RESULTS: require("../../assets/images/no-results.png"),

    //KNOWLY ICONS
    KNOWLY_CLIPBOARD,
    KNOWLY_GAME,
    KNOWLY_HAT,
    KNOWLY_WINGS,
    KNOWLY_POINTING_LEFT,
    KNOWLY_STATS,
    KNOWLY_TEACH,
    KNOWLY_USERS,

    QUIZ_ICON: require("../../assets/icons/quiz-icon.png")



}

export default IMAGES;