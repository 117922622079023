import { Avatar, Box, Flex, Text, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import { Link, useParams } from 'react-router-dom'
import { formatDateTimeFromNow } from '../../../config/helpers/dateHelper'

const ChatItem = ({ chatItem }) => {
    const { colorMode } = useColorMode()
    const { chatId } = useParams()

    return (
        <Flex
            as={Link}
            to={`/admin/chats/${chatItem?.id}`}
            p="8px"
            rounded="md"
            w="full"
            cursor={"pointer"}
            role='group'
            _hover={{ bg: getColor(colorKeys.lightGrayBackgroundFill, colorMode) }}
            bg={chatId === chatItem?.id ? getColor(colorKeys.lightGrayBackgroundFill, colorMode) : "transparent"}
        >
            <Avatar size="sm" src={chatItem?.thumbnailMedia?.thumbnailUrl} name={chatItem?.name} />
            <Box w="full" ml={2}>
                <Flex align={"center"} justify={"space-between"} w="full">
                    <Text fontSize={"14px"} fontWeight={"500"}>{chatItem?.name}</Text>
                    <Text fontSize={"13px"}>{formatDateTimeFromNow(chatItem?.lastMessage?.createdBy?.dateTime)}</Text>
                </Flex>
                <Box>
                    <Text fontSize={"15px"}>
                        {chatItem?.lastMessage?.message || "No messages yet"}
                    </Text>
                </Box>
            </Box>
        </Flex>
    )
}

export default ChatItem