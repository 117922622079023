import React from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'


const CustomTabs = ({ heads, bodies, isLazy, tabsProps, tabListProps, tabProps, tabPanelsProps, tabPanelProps }) => {
    return (
        <Tabs isLazy={isLazy} {...tabsProps}>
            <TabList {...tabListProps}>
                {heads?.map((item, index) => <Tab key={index} {...tabProps}>{item?.label || item}</Tab>)}
            </TabList>

            <TabPanels {...tabPanelsProps}>
                {bodies?.map((item, index) =>
                    <TabPanel key={index} {...tabPanelProps}>
                        {item}
                    </TabPanel>
                )}
            </TabPanels>
        </Tabs>
    )
}

export default CustomTabs