import React from 'react'
import { useParams } from 'react-router'
import { SimpleGrid, Box, Heading, Card, CardBody, CardHeader, VStack, useColorMode, useDisclosure, Flex, IconButton, HStack, Image, Text } from '@chakra-ui/react'
import { useCohortVariants, useDeleteCohortVariant } from '../../../../../../config/query/cohortVariantsQuery'
import LabelValuePair from '../../../../../../components/BasicUI/LabelValuePair'
import { colorKeys, getColor } from '../../../../../../config/constants/appColors'
import VariantForm from './VariantForm'
import { useState } from 'react'
import PrimaryButton from '../../../../../../components/BasicUI/Buttons/PrimaryButton'
import { Icon } from '@chakra-ui/icons'
import APP_ICONS from '../../../../../../config/constants/icons'
import { useQueryClient } from '@tanstack/react-query'
import DeletePopover from '../../../../../../components/BasicUI/DeletePopover'
import NoResults from '../../../../../../components/SVGComponents/NoResults'
import IMAGES from '../../../../../../config/constants/images'
import VariantListSkeleton from '../../../../../../components/Skeletons/VariantListSkeleton'

const Variants = () => {
    const queryClient = useQueryClient()
    const { cohortId } = useParams()
    const { colorMode } = useColorMode()
    const [selectedVariant, setSelectedVariant] = useState(null)
    const formDisclosure = useDisclosure()

    const cohortVariantsQuery = useCohortVariants({
        cohorts: cohortId
    })
    const deleteCohortVariantQuery = useDeleteCohortVariant()

    const onEdit = (item) => {
        setSelectedVariant(item)
        formDisclosure.onOpen()
    }

    const onCreateNew = () => {
        setSelectedVariant(null)
        formDisclosure.onOpen()
    }

    const onDelete = (id) => {
        deleteCohortVariantQuery
            .mutateAsync(id)
            .then(() => {
                queryClient.invalidateQueries(["cohortVariants"])
                queryClient.invalidateQueries(["cohortVariantsOptions"])
            })
            .catch((err) => console.log(err))
    }

    return (
        <Box>
            <Flex justify="space-between" align={"center"} mb={2}>
                <Heading as="h2" fontSize={26} fontWeight="bold">Variants</Heading>
                <PrimaryButton
                    leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
                    onClick={onCreateNew}
                >
                    Create New Variant
                </PrimaryButton>
            </Flex>

            {cohortVariantsQuery.data?.results?.length === 0 && !cohortVariantsQuery.isLoading && (
                <Flex justifyContent="center" alignItems="center" h="100%" flexDirection="column">
                    <Image src={IMAGES.NO_RESULTS} maxW="250px" mt={10} />
                    <Text color={getColor(colorKeys.secondaryText, colorMode)} mt={0}>There are no variants available for this cohort</Text>
                </Flex>
            )}

            <VariantListSkeleton isLoading={cohortVariantsQuery.isLoading} />

            <SimpleGrid spacing={5} columns={{ base: 1, md: 3, lg: 4 }}>
                {cohortVariantsQuery.data?.results?.map((item) =>
                    <Card key={item.id}>
                        <CardHeader py="1" bg={getColor(colorKeys.lightestGray, colorMode)}>
                            <Flex justify="space-between" align={"center"}>
                                <Heading fontSize="18px">{item.name}</Heading>
                                <HStack spacing={2}>
                                    <IconButton icon={<Icon as={APP_ICONS.EDIT} />} onClick={() => onEdit(item)} />
                                    <DeletePopover onConfirm={() => onDelete(item.id)}>
                                        <IconButton icon={<Icon as={APP_ICONS.BIN} />} />
                                    </DeletePopover>
                                </HStack>
                            </Flex>
                        </CardHeader>
                        <CardBody>
                            <VStack spacing="1" align="stretch">
                                <LabelValuePair
                                    label="Display Name"
                                    value={item.formattedName}
                                />
                                <LabelValuePair
                                    label="Fee"
                                    value={item.price}
                                />
                                <LabelValuePair
                                    label="Discount"
                                    value={item.discount}
                                />
                            </VStack>
                        </CardBody>
                    </Card>
                )}
            </SimpleGrid>
            <VariantForm disclosure={formDisclosure} data={selectedVariant} cohortId={cohortId} />
        </Box>
    )
}

export default Variants