import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Get, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { appendQueryParams } from "../helpers/queryHelper";;

export const useSettings = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["settings", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.SETTINGS.base}?${appendQueryParams({params})}`,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useUpdateSetting = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Put({
                path: `${API_CONSTANTS.SETTINGS.base}/${id}`,
                token,
                body
            })
            return data
        },
    })
}

export const useEmailSettings = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["emailSettings"],
        queryFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.SETTINGS.email,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useUpdateEmailSettings = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Put({
                path: API_CONSTANTS.SETTINGS.email,
                token,
                body,
                toastError: true,
                toastMessage:true
            })
            return data
        },
    })
}

export const useZoomSettings = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["zoomSettings"],
        queryFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.SETTINGS.zoom,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useUpdateZoomSettings = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Put({
                path: API_CONSTANTS.SETTINGS.zoom,
                token,
                body,
                toastError: true,
                toastMessage:true
            })
            return data
        },
    })
}

export const useBlobStorageSettings = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["blobStorageSettings"],
        queryFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.SETTINGS.blobStorage,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useUpdateBlobStorageSettings = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Put({
                path: API_CONSTANTS.SETTINGS.blobStorage,
                token,
                body,
                toastError: true,
                toastMessage:true
            })
            return data
        },
    })
}

export const useVimeoSettings = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["vimeoSettings"],
        queryFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.SETTINGS.vimeo,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useUpdateVimeoSettings = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Put({
                path: API_CONSTANTS.SETTINGS.vimeo,
                token,
                body,
                toastError: true,
                toastMessage:true
            })
            return data
        },
    })
}