import React from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Input, Flex, Box, chakra, useColorMode, Progress, FormControl, FormLabel } from '@chakra-ui/react'
import FormDrawer from '../Forms/FormDrawer'
import FormFileUpload from '../Forms/FormFileUpload'
import { getColor, colorKeys } from '../../config/constants/appColors'
import { useCreateFolder } from '../../config/query/mediaQuery'
import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import FormInput from '../Forms/FormInput'

const VideoForm = ({ disclosure, parentId = null }) => {
    const queryClient = useQueryClient()

    const createFolderQuery = useCreateFolder()

    const {
        handleSubmit,
        control,
        reset: resetForm,
        formState: { errors },
    } = useForm()

    const onSubmit = (values) => {
        createFolderQuery
            .mutateAsync({ ...values, parentId })
            .then(() => {
                queryClient.invalidateQueries(["media"])
                disclosure?.onClose()
            })
            .catch((err) => {
                console.log(err, "error while uploading media")
            })
    }

    return (
        <FormDrawer
            title={"Add Folder"}
            disclosure={disclosure}
            isSubmitting={createFolderQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            containerProps={{ size: "sm" }}
            maxW="30rem"
            onClose={disclosure.onClose}
            reset={() => resetForm()}
        >

            <FormInput
                label={"Name"}
                control={control}
                errors={errors}
                id="name"
                required={true}
                placeholder="Enter folder name"
                rules={{
                    minLength: {
                        value: 1,
                        message: "Name must be at least 1 characters"
                    }
                }}
            />

        </FormDrawer>
    )
}

export default VideoForm