import React, { useState } from 'react'
import CustomTable from '../../../components/BasicUI/CustomTable'
import { useDeleteQuiz, useQuizzes, useQuizzesFacet } from '../../../config/query/quizQuery'
import { formatDateTime, formatDateTimeFromNow } from '../../../config/helpers/dateHelper'
import APP_ICONS from '../../../config/constants/icons'
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge'
import Chronology from '../../../components/BasicUI/DataBoxes/Chronology'
import AvatarStack from '../../../components/BasicUI/DataBoxes/AvatarStack'
import { Text } from '@chakra-ui/react'
import TableInfoPopover from '../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'
import { getFilters } from '../../../config/helpers/filterHelper'
import { useNavigate } from 'react-router'

const QuizList = () => {
    const navigate = useNavigate()
    const [query, setQuery] = useState({
        pageNo: 1,
        pageSize: 20,
    })

    const quizQuery = useQuizzes(query)
    const quizsFacetQuery = useQuizzesFacet()
    const deleteQuizQuery = useDeleteQuiz()

    const onEditModal = () => {

    }

    const handleDelete = (id) => {

    }

    return (
        <CustomTable
            containerProps={{ mt: 5 }}
            searchPlaceholder="Search quizs"
            filters={getFilters(quizsFacetQuery?.data)}
            head={[
                {
                    title: "Name",
                    extractor: "name",
                    align: "left",
                    component: (item) => (
                        <TableInfoPopover
                            data={item}
                            onEdit={(item) => onEditModal(item)}
                            onDelete={(id) => handleDelete(id)}
                            triggerOnClick={() => onEditModal(item)}
                        >
                            <LabelValuePair label="Description" value={item.description} />
                            <LabelValuePair label="Course" value={item.courseName} />
                            <LabelValuePair label="Qualification" value={item.qualificationName} />
                            <LabelValuePair
                                label="Status"
                                value={true}
                                valueComponent={
                                    <StatusBadge value={item.status} />
                                }
                            />
                            <LabelValuePair label="Created" value={formatDateTime(item.createdBy?.dateTime)} />
                            <LabelValuePair label="Last Updated" value={formatDateTime(item.updateBy?.dateTime)} />
                        </TableInfoPopover>
                    )
                },
                { title: "Cohort Name", extractor: "cohortName" },
                { title: "Chapter Name", extractor: "chapterName" },
                { title: "duration", extractor: "quiz.duration", component: (item) => <span>{item.quizz?.duration} mins</span> },
                { title: "Max Attempts", extractor: "quiz.maxAttempts", component: (item) => <span>{item.quizz?.maxAttempts}</span> },
                { title: "Passing %", extractor: "quiz.passingPercentage", component: (item) => <span>{item.quizz?.passingPercentage}%</span> },
                { title: "teachers", extractor: "teachers", align: "center", component: (item) => item.teachers?.length ? <AvatarStack data={item.teachers} /> : <Text fontSize="14px">N/A</Text> },
                // { title: "Available From", extractor: "availableStartDateTime" },
                // { title: "Available Till", extractor: "availableEndDateTime" },
                { title: "Status", extractor: "status", component: (item) => <StatusBadge value={item.status} /> },
                {
                    title: "Last Modified",
                    extractor: "lastModified",
                    component: (item) => <Chronology data={item} />
                },
                { title: "Actions", extractor: "actions" }
            ]}
            data={quizQuery?.data?.results?.map((item) => {
                return {
                    ...item,
                    lastModified: item.updatedBy?.dateTime ? formatDateTimeFromNow(item.updatedBy?.dateTime) : formatDateTimeFromNow(item.createdBy?.dateTime),
                    actions: [
                        {
                            title: "View",
                            action: () => navigate(`/admin/exams/quizzes/${item.id}`),
                            icon: APP_ICONS.EYE,
                            isView: true
                        },
                        {
                            title: "Edit",
                            action: () => onEditModal(item),
                            icon: APP_ICONS.EDIT,
                            isEdit: true
                        },
                        {
                            title: "Delete",
                            action: handleDelete,
                            icon: APP_ICONS.BIN,
                            isDelete: true
                        }
                    ]
                }
            })}
            loading={quizQuery?.isLoading}
            totalResults={quizQuery?.data?.totalResults}
            totalPages={quizQuery?.data?.totalPages}
            pageNo={query.pageNo}
            pageSize={query.pageSize}
            onQueryChange={(updatedQuery) => setQuery({ ...query, ...updatedQuery })}
            query={query}
            onRefresh={() => quizQuery.refetch()}
            isRefreshing={quizQuery?.isFetching}
        />
    )
}

export default QuizList