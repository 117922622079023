import { Box, Card, CardBody, Flex, HStack, Heading, Icon, IconButton, Text, Tooltip, useColorMode, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import { useParams } from 'react-router'
import { useQuiz, useUpdateQuizStatus } from '../../../../config/query/quizQuery'
import CustomTabs from '../../../../components/BasicUI/DataBoxes/CustomTabs'
import QuizInformation from './QuizInformation'
import QuizForm from '../QuizForm'
import QuizQuestions from './QuizQuestions'
import { colorKeys, getColor } from '../../../../config/constants/appColors'
import QuestionBox from '../../../../components/BasicUI/DataBoxes/QuestionBox'
import APP_ICONS from '../../../../config/constants/icons'
import QuestionSuggestionBox from '../../../../components/BasicUI/DataBoxes/QuestionSuggestionBox'
import { STATUS } from '../../../../config/constants/enums'
import QuizResults from './QuizResults'

const QuizDetails = () => {
    const { colorMode } = useColorMode()
    const formDisclosure = useDisclosure()
    const { quizId } = useParams()
    const quizQuery = useQuiz(quizId)

    const updateQuizStatusQuery = useUpdateQuizStatus()

    const handleUpdateQuizStatus = () => {
        updateQuizStatusQuery.mutateAsync({
            id: quizId,
            status: quizQuery.data?.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE
        }).then(() => {
            quizQuery.refetch()
        })
    }

    return (
        <HStack spacing={3}>
            <Box w="full">
                <Flex justify="space-between" align={"center"}>
                    <Box>
                        <Heading as="h2" fontSize={26} fontWeight="bold">{quizQuery.data?.name}</Heading>
                        <Text>Fine tune your quiz with maximum possibilities</Text>
                    </Box>
                    <PrimaryButton isLoading={updateQuizStatusQuery.isLoading} onClick={handleUpdateQuizStatus}>
                        {quizQuery.data?.status === STATUS.ACTIVE ? "Unpublish" : "Publish"}
                    </PrimaryButton>
                </Flex>

                <Card shadow="lg" mt={3} minH="calc(100vh - 160px)">
                    <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
                        <CustomTabs
                            head={[
                                { label: 'Information' },
                                { label: 'Questions' },
                                { label: 'Results' },
                            ]}
                            body={[
                                <QuizInformation />,
                                <QuizQuestions />,
                                <QuizResults />,
                            ]}
                            tabPanelProps={{
                                px: 0
                            }}
                        />
                    </CardBody>
                </Card>
            </Box>

            <QuizForm disclosure={formDisclosure} data={quizQuery.data} />
        </HStack>
    )
}

export default QuizDetails