import { Avatar, HStack, Tooltip } from '@chakra-ui/react'
import React from 'react'

const AvatarStack = ({ data, nameKey = "name", idKey = "id", imageKey = "avatar", size = "xs", containerProps, avatarProps }) => {
    return (
        <HStack
            justify={"center"}
            spacing={2}
            {...containerProps}
        >
            {data?.map((item) =>
                <Tooltip label={item[nameKey]} key={item[idKey]}>
                    <Avatar
                        size={size}
                        key={item[idKey]}
                        name={item[nameKey]}
                        src={item[imageKey]}
                        {...avatarProps}
                    />
                </Tooltip>
            )}
        </HStack>
    )
}

export default AvatarStack