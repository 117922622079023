import React from 'react'
import { PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields } from '@syncfusion/ej2-react-pdfviewer';
PdfViewerComponent.Inject(
    Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields
);
const PDFEditor = () => {
    return (
        <div>
            <div className='control-section'>
                {/* Render the PDF Viewer */}
                <PdfViewerComponent
                    id="container"
                    documentPath="PDF_Succinctly.pdf"
                    serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
                />
                {/* < services={[Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields]} /> */}
                {/* </PdfViewerComponent> */}
            </div>
        </div>
    )
}

export default PDFEditor