export const makeSelectList = (array, value = "id", label = "name", useIndex) => {
    if (useIndex) {
        return array?.map((item) => {
            return {
                value: item,
                label: item,
            }
        })
    }
    return array?.map((item) => {
        return {
            value: item[value],
            label: item[label],
        }
    })
}

export const seprerateViaDot = (data) => {
    if (data === undefined || data === null) return
    const separatedData = {};
    for (const item of data) {
        const [category, option] = item.split('.');
        if (!separatedData[category]) {
            separatedData[category] = [];
        }
        separatedData[category].push(option);
    }
    return separatedData;
}

export const joinWithDot = (data) => {
    const dataArray = [];
    for (const category in data) {
        if (!data[category]) continue
        if (!data.hasOwnProperty(category)) continue;
        if (data[category].length === 0) continue;
        const options = data[category];
        for (const option of options) {
            dataArray.push(`${category}.${option}`);
        }
    }
    return dataArray;
}