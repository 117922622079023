import React from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../../components/Forms/FormDrawer'
import { SimpleGrid } from '@chakra-ui/react'
import FormInput from '../../../../components/Forms/FormInput'
import FormSelect from '../../../../components/Forms/FormSelect'
import FormTextarea from '../../../../components/Forms/FormTextarea'

const QuizCategoryForm = ({ disclosure }) => {
    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
    } = useForm()

    const onSubmit = () => {
        console.log("form submitted successfully!")
        disclosure.onClose()
    }
    return (
        <FormDrawer
            title="Add Quiz Category"
            disclosure={disclosure}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(onSubmit)}
        >
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                <FormInput
                    label={"Title"}
                    control={control}
                    errors={errors}
                    id="title"
                    required={true}
                    placeholder="Sendup, Mock Quiz etc"
                    rules={{
                        minLength: {
                            value: 3,
                            message: "Title must be at least 3 characters"
                        }
                    }}
                />

                <FormSelect
                    id="quizCategory"
                    label="Category"
                    placeholder={'Select Category'}
                    required={true}
                    errors={errors}
                    control={control}
                    options={[
                        {
                            label: "Some value 1",
                            value: "Some value 1",
                        },
                    ]}
                />
            </SimpleGrid>

            <FormTextarea
                label={"Description"}
                placeholder="Enter quiz description..."
                control={control}
                errors={errors}
                id="description"
                required={true}
                containerProps={{
                    mt:5
                }}
            />
        </FormDrawer>
    )
}

export default QuizCategoryForm