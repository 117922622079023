import React, { useState } from 'react'
import { Box, VStack, Flex, Heading, useDisclosure, HStack, Button, Icon, useColorMode } from '@chakra-ui/react'
import { useParams } from 'react-router'
import { useQuiz, useQuizQuestions, useQuizUpsertQuestions } from '../../../../../config/query/quizQuery'
import QuestionBox from '../../../../../components/BasicUI/DataBoxes/QuestionBox'
import APP_ICONS from '../../../../../config/constants/icons'
import AddQuestionsForm from './AddQuestionsForm'
import { useQueryClient } from '@tanstack/react-query'
import { generateString } from '../../../../../config/helpers/stringHelper'
import SuggestQuestions from './SuggestQuestions'
import ImportQuestions from './ImportQuestions'

const QuizQuestions = () => {

    const queryClient = useQueryClient()

    const [selectedQuestion, setSelectedQuestion] = useState(null)

    const { quizId } = useParams()
    const formDisclosure = useDisclosure()
    const suggestionDisclosure = useDisclosure()
    const importDisclosure = useDisclosure()

    const quizQuery = useQuiz(quizId)
    const quizQuestionsQuery = useQuizQuestions(quizId)
    const upsertQuestionsQuery = useQuizUpsertQuestions(quizId)

    const handleRemoveQuestion = (questionId) => {
        upsertQuestionsQuery.mutateAsync(
            quizQuestionsQuery.data.filter(item => item.id !== questionId)
        ).then(() => {
            queryClient.invalidateQueries(["quizQuestions", quizId])
        })
    }

    const onAdd = () => {
        setSelectedQuestion(null)
        formDisclosure.onOpen()
    }

    const onEdit = (question) => {
        setSelectedQuestion(question)
        formDisclosure.onOpen()
    }

    const onSuggest = () => {
        suggestionDisclosure.onOpen()
    }

    const onImport = () => {
        importDisclosure.onOpen()
    }
    return (
        <Box w="full">
            <Flex justify="space-between" align={"center"}>
                <Box>
                    <Heading as="h2" fontSize={"22px"} fontWeight="bold">Manage Questions</Heading>
                </Box>
                <HStack spacing={5}>
                    <Button onClick={onSuggest} rightIcon={<Icon as={APP_ICONS.MAGIC} />}>Suggest Questions</Button>
                    <Button onClick={onImport} rightIcon={<Icon boxSize="6" as={APP_ICONS.ADD} />}>Import Questions</Button>
                    <Button onClick={onAdd} rightIcon={<Icon boxSize="6" as={APP_ICONS.ADD} />}>Add New</Button>
                </HStack>
            </Flex>
            <VStack spacing={4} align="stretch" mt={5}>
                {quizQuestionsQuery.data?.map((item, index) =>
                    <QuestionBox
                        key={index}
                        question={item}
                        onRemove={() => handleRemoveQuestion(item.id)}
                        onEdit={() => onEdit(item)}
                    />
                )}
            </VStack>

            <AddQuestionsForm
                disclosure={formDisclosure}
                data={selectedQuestion && {
                    ...selectedQuestion,
                    options: selectedQuestion.options.map(item => ({ ...item, id: item.id || generateString(10) }))
                }}
            />
            <SuggestQuestions
                disclosure={suggestionDisclosure}
                query={{
                    cohortId: quizQuery.data?.cohortId,
                    chapterId: quizQuery.data?.chapterId,
                    PageSize: 20,
                    PageNumber: 1
                }}
            />
            <ImportQuestions disclosure={importDisclosure} />
        </Box>

    )
}

export default QuizQuestions

