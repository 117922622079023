import React from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../components/Forms/FormDrawer'
import { Box, SimpleGrid, Text, VStack, chakra } from '@chakra-ui/react'
import FormInput from '../../../components/Forms/FormInput'
import FormSelect from '../../../components/Forms/FormSelect'
import FormTextarea from '../../../components/Forms/FormTextarea'
import FormSearchSelect from '../../../components/Forms/FormSearchSelect'
import { makeSelectList } from '../../../config/helpers/selectListHelper'
import { useCohortOptions } from '../../../config/query/cohortQuery'
import { useChapterOptions } from '../../../config/query/chapterQuery'
import { useUserOptions } from '../../../config/query/userQuery'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useCreateQuiz, useUpdateQuiz } from '../../../config/query/quizQuery'
import { STATUS, USER_TYPES } from '../../../config/constants/enums'
import { useEffect } from 'react'
import FormSwitch from '../../../components/Forms/FormSwitch'
import { NUMBERS_ONLY, PERCENTAGE_REGEX } from '../../../config/constants/regexConstants'
import SuccessBox from '../../../components/BasicUI/DataBoxes/SuccessBox'

const QuizForm = ({ disclosure, data, chapterId, cohortId }) => {

    const queryClient = useQueryClient()
    const [isSuccess, setIsSuccess] = useState(false)

    const [query, setQuery] = useState({
        chapterOptionsQuery: {
            cohorts: data?.cohortId,
        }
    })

    const cohortOptionsQuery = useCohortOptions({})
    const chapterOptionsQuery = useChapterOptions(query?.chapterOptionsQuery)
    const teacherOptions = useUserOptions({ roles: USER_TYPES.Teacher })

    const createLectureQuery = useCreateQuiz()
    const updateLectureQuery = useUpdateQuiz(data?.id)

    const {
        handleSubmit,
        control,
        watch,
        reset: resetForm,
        getValues: getFormValues,
        formState: { errors },
    } = useForm({
        values: {
            "quizz.maxAttempts": 1,
            ...data,
            status: (data?.id && data?.status === STATUS.ACTIVE),
            cohortId: cohortId,
            chapterId: chapterId,
        },
    })

    const onSubmit = async (values) => {
        values = {
            ...values,
            status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
            quizz: {
                ...values.quizz,
                PassingPercentage: Number(values.quizz?.PassingPercentage),
                duration: Number(values.quizz?.duration),
                maxAttempts: Number(values.quizz?.maxAttempts),
            },
        }

        let formMutate = data?.id ? updateLectureQuery.mutateAsync : createLectureQuery.mutateAsync
        formMutate(values)
            .then(() => {
                resetForm(Object.keys(getFormValues()).map((item) => ({ [item]: "" })))
                queryClient.invalidateQueries(["quizs"])
                setIsSuccess(true)
            })
    }


    const handleFormChangeEffects = (name, value) => {
        const updatedQuery = { ...query[name], ...value }
        setQuery({ ...query, [name]: updatedQuery })
    }

    watch((data, { name, type }) => {
        if (type === "change") {
            if (name === "cohortId") {
                handleFormChangeEffects("chapterOptionsQuery", { cohorts: data[name] })
            }
        }
    })

    useEffect(() => {
        handleFormChangeEffects("chapterOptionsQuery", { cohorts: data?.cohortId })
    }, [data?.cohortId])


    return (
        <FormDrawer
            title={isSuccess ? "Manage Quiz" : data?.id ? "Update Quiz" : "Add Quiz"}
            disclosure={disclosure}
            isSubmitting={updateLectureQuery.isLoading || createLectureQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            hideFooter={isSuccess}
        >
            {isSuccess
                ? <SuccessBox
                    label='Quiz Created'
                    containerProps={{
                        mb: 5
                    }}
                    link={`/admin/exams/quizzes/${createLectureQuery?.data?.id}`}
                >
                    <Text m="auto" maxW="750px">Your quiz was created, and is not published yet. Go to details to further manage your quiz and <chakra.span fontWeight={"bold"}>publish</chakra.span> it. You can add questions manually or through an excel file.</Text>
                    <Text m="auto" maxW="650px">Plus, you can always use create with <chakra.span fontWeight={"bold"}>magic</chakra.span> to automatically <chakra.span fontWeight={"bold"}>generate questions</chakra.span> relevent to your topic</Text>
                </SuccessBox>
                : <VStack align="stretch" spacing={5}>
                    <SimpleGrid columns={1} spacing={5}>
                        <FormInput
                            label={"Name"}
                            control={control}
                            errors={errors}
                            id="name"
                            required={true}
                            placeholder="Sendup, Mock Quiz etc"
                            rules={{
                                minLength: {
                                    value: 3,
                                    message: "Name must be at least 3 characters"
                                }
                            }}
                        />
                    </SimpleGrid>

                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>

                        {!cohortId && <FormSearchSelect
                            id="cohortId"
                            label="Cohort"
                            placeholder={'Select Cohort'}
                            required={true}
                            errors={errors}
                            control={control}
                            options={makeSelectList(cohortOptionsQuery?.data)}
                        />}

                        {!chapterId && <FormSearchSelect
                            id="chapterId"
                            label="Chapter"
                            placeholder={'Select Chapter'}
                            required={true}
                            errors={errors}
                            control={control}
                            options={makeSelectList(chapterOptionsQuery?.data)}
                        />}

                        <FormSearchSelect
                            multiple={true}
                            id="teacherIds"
                            label="Teachers"
                            placeholder={'Select Teachers'}
                            required={true}
                            errors={errors}
                            control={control}
                            options={makeSelectList(teacherOptions?.data)}
                        />

                        <FormInput
                            label={"Duration (in minutes)"}
                            control={control}
                            errors={errors}
                            id="quizz.duration"
                            required={true}
                            type={"number"}
                            placeholder="Enter quiz duration in minutes"
                            rules={{
                                min: {
                                    value: 1,
                                    message: "Duration must be at least 1 minute"
                                },
                                pattern: {
                                    value: NUMBERS_ONLY,
                                    message: "Duration must be a number"
                                }
                            }}
                        />

                        <FormInput
                            label={"Max Attempts"}
                            control={control}
                            errors={errors}
                            id="quizz.maxAttempts"
                            required={true}
                            type={"number"}
                            placeholder="Enter max attempts allowed"
                            rules={{
                                min: {
                                    value: 1,
                                    message: "Max attempts must be at least 1"
                                },
                                pattern: {
                                    value: NUMBERS_ONLY,
                                    message: "Duration must be a number"
                                }
                            }}
                        />

                        <FormInput
                            label={"Passing Percentage"}
                            control={control}
                            errors={errors}
                            id="quizz.PassingPercentage"
                            required={true}
                            type={"number"}
                            placeholder="Enter passing percentage"
                            rules={{
                                pattern: {
                                    value: PERCENTAGE_REGEX,
                                    message: "Invalid passing percentage"
                                }
                            }}
                        />

                    </SimpleGrid>

                    <FormTextarea
                        label={"Description"}
                        placeholder="Enter quiz description..."
                        control={control}
                        errors={errors}
                        id="quizz.Description"
                        containerProps={{
                            mt: 5
                        }}
                    />
                </VStack>
            }

        </FormDrawer>
    )
}

export default QuizForm