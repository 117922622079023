var Config = {
    ENVIRONMENT: "PRODUCTION", //current selected environment
    ENVIRONMENTS: {
        LOCAL: {
            BASE_URL: "https://localhost:44318/api",
            PERSIST_SECRET_KEY: "secret.local",
        },
        DEVELOPMENT: {
            BASE_URL: "https://app-evolvlms-dev-uaenorth.azurewebsites.net/api",
            PERSIST_SECRET_KEY: "secret.dev",
        },
        STAGING: {
            BASE_URL: "http://localhost:5000/api",
            PERSIST_SECRET_KEY: "secret.staging",
        },
        PRODUCTION: {
            BASE_URL: "https://app-evolvlms-artt-prod-uaenorth.azurewebsites.net/api",
            PERSIST_SECRET_KEY: "shS27i2a4yKeu2A",
        }
    }
};
Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};
export default Config;
