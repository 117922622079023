import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { MILISECONDS } from "../constants";
import { appendQueryParams } from "../helpers/queryHelper";;

export const useRoles = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["roles", params],
        queryFn: async () => {
            const {data} = await Get({
                path: `${API_CONSTANTS.ROLES.base}?${appendQueryParams({params})}`,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}

export const usePermissions = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["permissions"],
        queryFn: async () => {
            const {data} = await Get({
                path: API_CONSTANTS.ROLES.getPermissions,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}


export const useRolesFacet = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["rolesFacet"],
        queryFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.ROLES.getFacet,
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useRolesOptions = (params = {}) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["rolesOptions", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.ROLES.getOptions}?${appendQueryParams({params})}`,
                token,
                toastError: true,
            })
            return data
        },
        staleTime: MILISECONDS.MAX
    })
}

export const useCreateRole = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.ROLES.dataKeys)
            const { data } = await Post({
                path: API_CONSTANTS.ROLES.base,
                token,
                body
            })
            return data
        },
    })
}

export const useUpdateRole = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.ROLES.dataKeys)
            const { data } = await Put({
                path: `${API_CONSTANTS.ROLES.base}/${id}`,
                token,
                body
            })
            return data
        },
    })
}

export const useDeleteRole = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.ROLES.base}/${id}`,
                token,
                toastError: true,
                toastMessage:true
            })
            return data
        },
    })
}