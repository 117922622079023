import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { appendQueryParams } from "../helpers/queryHelper";;

export const useChatMessages = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["chat-messages", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.CHAT_MESSAGES.base}?${appendQueryParams({params})}`,
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useCreateChatMessage = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.CHAT_MESSAGES.dataKeys)
            const { data } = await Post({
                path: API_CONSTANTS.CHAT_MESSAGES.base,
                token,
                body
            })
            return data
        },
    })
}

export const useUpdateChatMessage = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.CHAT_MESSAGES.dataKeys)
            const { data } = await Put({
                path: `${API_CONSTANTS.CHAT_MESSAGES.base}/${id}`,
                token,
                body
            })
            return data
        },
    })
}

export const useDeleteChatMessage = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.CHAT_MESSAGES.base}/${id}`,
                token,
                toastError: true,
                toastMessage:true
            })
            return data
        },
    })
}