import APP_ICONS from "./icons"

const reportsSubsiderOptions = [
    {
        icon: APP_ICONS.EXAMS_LIST,
        title: "Reports",
        link: "/admin/reports"
    }
]

export default reportsSubsiderOptions