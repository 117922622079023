import { BASE_MODULES } from "./enums";
import APP_ICONS from "./icons";
import quizSubSiderOptions from "./quizSubsiderOptions";
import lecturesSubsiderOptions from "./lecturesSubsiderOptions";
import syllabusSubsiderOptions from "./usersSubsiderOptions";

export const siderMenu = [
    {
        icon: APP_ICONS.DASHBOARD,
        title: "Dashboard",
        link: "/admin/dashboard",
    },
    {
        icon: APP_ICONS.EXAMS,
        title: "Exams",
        link: "/admin/exams",
        subsider: quizSubSiderOptions,
        permissionKey: [BASE_MODULES.Quizz, BASE_MODULES.QuizzQuestion, BASE_MODULES.QuizzResult]
    },
    {
        icon: APP_ICONS.COURSES,
        title: "Syllabus",
        link: "/admin/syllabus",
        subsider: syllabusSubsiderOptions,
        permissionKey: [BASE_MODULES.Cohort, BASE_MODULES.Chapter, BASE_MODULES.Note, BASE_MODULES.Course, BASE_MODULES.CohortVariant, BASE_MODULES.CohortVariantType]
    },
    {
        icon: APP_ICONS.LECTURES,
        title: "Lectures",
        link: "/admin/lectures",
        subsider: lecturesSubsiderOptions,
        permissionKey: [BASE_MODULES.Lecture, BASE_MODULES.Media, BASE_MODULES.Meeting]
    },
    {
        icon: APP_ICONS.USERS,
        title: "Users",
        link: "/admin/users",
        subsider: quizSubSiderOptions,
        permissionKey: [BASE_MODULES.User, BASE_MODULES.UserRole]
    },
    {
        icon: APP_ICONS.CHATS,
        title: "Chats",
        link: "/admin/chats",
        permissionKey: [BASE_MODULES.CohortChat, BASE_MODULES.CohortChatMessage]
    },
    {
        icon: APP_ICONS.MEDIA_LIB,
        title: "Media",
        link: "/admin/media",
        permissionKey: [BASE_MODULES.Media]
    },
    {
        icon: APP_ICONS.EMAILS,
        title: "Emails",
        link: "/admin/emails",
        subsider: quizSubSiderOptions,
        permissionKey: [BASE_MODULES.EmailLog, BASE_MODULES.EmailTemplate]
    },
    {
        icon: APP_ICONS.REPORTS,
        title: "Reports",
        link: "/admin/reports",
        subsider: quizSubSiderOptions
    },
    {
        icon: APP_ICONS.BIN,
        title: "Bin",
        link: "/admin/bin",
        subsider: quizSubSiderOptions,
        permissionKey: [BASE_MODULES.DeletedItem]
    },
    {
        icon: APP_ICONS.SETTINGS,
        title: "Settings",
        link: "/admin/settings",
        subsider: quizSubSiderOptions,
        permissionKey: [BASE_MODULES.Setting]
    }
]
