import React from 'react'
import { useCohort } from '../../../../../config/query/cohortQuery'
import { useParams } from 'react-router'
import { Box, Flex, Heading, Icon } from '@chakra-ui/react'
import CustomTabs from '../../../../../components/BasicUI/CustomTabs'
import Variants from './Variants'
import Content from './Content'
import Students from './Students'

const CohortDetails = () => {
    const { cohortId } = useParams()
    const cohortQuery = useCohort(cohortId)

    const cohortTabs = ["Content", "Variants", "Students"]

    return (
        <Box>
            <Flex justify="space-between" align={"center"}>
                <Heading as="h2" fontSize={26} fontWeight="bold">{cohortQuery.data?.formattedName}</Heading>
            </Flex>


            <CustomTabs
                isLazy={true}
                tabsProps={{ mt: 5 }}
                heads={cohortTabs}
                bodies={[
                    <Content />,
                    <Variants />,
                    <Students />,
                ]}
            />
        </Box>
    )
}

export default CohortDetails