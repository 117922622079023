import React from 'react'
import { colorKeys, getColor } from '../../config/constants/appColors'
import { useColorMode } from '@chakra-ui/react'

const NoResults = () => {
    const { colorMode } = useColorMode()
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 2250" >
            <g
                transform="translate(258.75 1110.0) rotate(0.0 403.125 315.0) translate(0.0 0.0) scale(6.177214220042905 6.1445430605676385)">
                <svg width="97.738" height="102.53" viewBox="0 0 97.738 102.53">
                    <g fill={getColor(colorKeys.lightGray, colorMode)}>
                        <ellipse cx="65.26" cy="42.471" rx="6.674" ry="42.471" />
                        <path
                            d="M73.948 64.549C71.266 46.13 81.61 26.42 100.126 13.702c1.681-1.154.726-3.791-1.305-3.61-7.966.711-20.803 4.225-28.467 18.591-10.022 18.786.293 36.96.293 36.96zm-17.376 0C59.254 46.13 48.91 26.42 30.394 13.702c-1.681-1.154-.726-3.791 1.305-3.61 7.966.711 20.803 4.225 28.467 18.591 10.022 18.786-.293 36.96-.293 36.96z" />
                        <path
                            d="M74.437 57.887c4.301-15.787 19.554-28.043 38.94-31.798 1.76-.341 1.915-2.801.215-3.368-6.67-2.222-18.249-3.894-29.467 4.996-14.67 11.626-12.733 29.894-12.733 29.894zm-18.354 0C51.782 42.1 36.528 29.844 17.143 26.09c-1.76-.341-1.915-2.801-.215-3.368 6.67-2.222 18.249-3.894 29.467 4.996C61.064 39.343 59.127 57.61 59.127 57.61z" />
                        <path
                            d="M70.483 58.422c14.091-12.339 36.397-14.381 57.266-5.697 1.894.788 3.599-1.46 2.336-3.077-4.957-6.34-14.946-15.261-31.272-13.878-21.349 1.809-31.059 20.461-31.059 20.461zm-10.446 0C45.945 46.083 23.64 44.041 2.771 52.725c-1.894.788-3.599-1.46-2.336-3.077 4.957-6.34 14.946-15.261 31.272-13.878 21.349 1.809 31.059 20.461 31.059 20.461z" />
                        <path
                            d="M42.973 49.239c-5.576 5.653-9.021 13.414-9.021 21.982 0 17.291 14.017 31.308 31.308 31.308s31.308-14.017 31.308-31.308c0-8.568-3.445-16.329-9.021-21.982z" />
                        <path
                            d="M65.217 46.567c15.351-2.482 31.951 5.901 42.817 21.201.986 1.389 3.177.557 2.998-1.137-.698-6.645-3.801-17.322-15.902-23.536-15.824-8.126-30.87.729-30.87.729z" />
                        <path
                            d="M65.303 46.567c-15.351-2.482-31.951 5.901-42.817 21.201-.986 1.389-3.177.557-2.998-1.137.698-6.645 3.801-17.322 15.902-23.536 15.824-8.126 30.87.729 30.87.729zM49.865 90.23h30.79v12.218h-30.79z" />
                    </g>
                </svg>
            </g>
            <g
                transform="translate(675.0 435.0) rotate(0.0 890.625 652.5) scale(5.454269870383582 5.446259400535862)">
                <svg width="326.579" height="239.614" viewBox="0 0 326.579 239.614">
                    <path
                        d="m211.401 236.459-2.215-1.128a25.026 25.026 0 0 1-13.672-22.302v-77.521h-64.45v77.521a25.026 25.026 0 0 1-13.672 22.302l-2.215 1.128c-1.563.796-.997 3.155.757 3.155h94.71c1.754 0 2.321-2.36.757-3.155z"
                        fill={getColor(colorKeys.lightGrayBackgroundFill, colorMode)} />
                    <path
                        d="M318.237 0H8.342A8.342 8.342 0 0 0 0 8.342v173.514a8.342 8.342 0 0 0 8.342 8.342h309.895a8.342 8.342 0 0 0 8.342-8.342V8.342A8.342 8.342 0 0 0 318.237 0z"
                        fill={getColor(colorKeys.lightGrayBackgroundFill, colorMode)} />
                    <path
                        d="M326.579 160.889H0v20.967a8.342 8.342 0 0 0 8.342 8.342h309.895a8.342 8.342 0 0 0 8.342-8.342zM11.761 11.29h303.057v138.45H11.761z"
                        fill={getColor(colorKeys.lightGray, colorMode)} />
                    <path
                        d="M166.539 182.551h-6.499a3.347 3.347 0 0 1-3.337-3.337v-6.499a3.347 3.347 0 0 1 3.337-3.337h6.499a3.347 3.347 0 0 1 3.337 3.337v6.499a3.347 3.347 0 0 1-3.337 3.337z"
                        fill="#f4f9ff" />
                </svg>
            </g>
            <g
                transform="translate(1312.5 1068.75) rotate(0.0 658.125 322.5) scale(3.634654096907541 3.6887706957193105)">
                <svg width="362.139" height="174.855" viewBox="0 0 362.139 174.855">
                    <path d="m195.014 89.427 8.23 33.338-73.342 18.107-8.23-33.338z" fill={getColor(colorKeys.lightGrayBackgroundFill, colorMode)} />
                    <path
                        d="M7.351 168.907.147 139.722a5 5 0 0 1 3.656-6.053l159.546-39.384 9.601 38.894-159.546 39.384a5 5 0 0 1-6.053-3.656z"
                        fill={getColor(colorKeys.lightGrayBackgroundFill, colorMode)} />
                    <circle cx="274.712" cy="87.427" fill={getColor(colorKeys.white, colorMode)} r="82.408" />
                    <path
                        d="M274.554 174.87c-15.734 0-31.221-4.28-45.035-12.625-19.985-12.071-34.072-31.203-39.668-53.87-5.595-22.667-2.028-46.156 10.043-66.141 12.072-19.985 31.203-34.072 53.87-39.668 46.792-11.553 94.258 17.121 105.809 63.913 11.55 46.792-17.121 94.258-63.914 105.809a88.048 88.048 0 0 1-21.105 2.582zm.154-164.86c-6.125 0-12.341.732-18.547 2.265-20.074 4.955-37.016 17.431-47.707 35.129-10.69 17.698-13.849 38.501-8.894 58.574 4.955 20.074 17.431 37.017 35.129 47.707 17.698 10.691 38.499 13.847 58.574 8.894 41.439-10.229 66.831-52.264 56.601-93.703-8.697-35.233-40.391-58.866-75.156-58.866z"
                        fill={getColor(colorKeys.lightGrayBackgroundFill, colorMode)} />
                </svg>
            </g>
            <g
                transform="translate(41.25 1665.0) rotate(0.0 1449.375 45.0) translate(0.0 37.5) scale(4.617152632434034 4.501350405121537)">
                <svg width="627.822" height="9.997" viewBox="0 8.331 627.822 9.997">
                    <path d="M627.822 0c0 11.042-8.963 19.994-20.02 19.994H20.02C8.963 19.994 0 11.043 0 0z"
                        fill={getColor(colorKeys.lightGrayBackgroundFill, colorMode)} />
                </svg>
            </g>
            <g transform="translate(540.0 307.5) rotate(0.0 708.75 390.0) scale(3.75 3.75)">
                <svg width="378" height="208" viewBox="0 0 378 208">
                    <path
                        d="M378 11.81V208H0V11.81C0 5.29 5.29 0 11.81 0H366.2c6.52 0 11.8 5.29 11.8 11.81z"
                        fill={getColor(colorKeys.lightGrayBackgroundFill, colorMode)} />
                    <path
                        d="M378 11.81v24.32H0V11.81C0 5.29 5.29 0 11.81 0H366.2c6.52 0 11.8 5.29 11.8 11.81z"
                        fill={getColor(colorKeys.lightBackgroundFill, colorMode)} />
                    <circle cx="25.945" cy="17.877" fill="#fff" r="4.273" />
                    <circle cx="41.094" cy="17.877" fill={getColor(colorKeys.lightGray, colorMode)} r="4.273" />
                    <circle cx="56.244" cy="17.877" fill={getColor(colorKeys.lightBackgroundFill, colorMode)} r="4.273" />
                    <g fill="#fff">
                        <path
                            d="M361.946 6.835h4.114v4.114h-4.114zm0 9.369h4.114v4.114h-4.114zm0 9.369h4.114v4.114h-4.114z" />
                    </g>
                    <path
                        d="M331.82 23.65h-240c-3.575 0-6.5-2.925-6.5-6.5s2.925-6.5 6.5-6.5h240c3.575 0 6.5 2.925 6.5 6.5s-2.925 6.5-6.5 6.5z"
                        fill={getColor(colorKeys.lightGray, colorMode)} />
                </svg>
            </g>
            <g
                transform="translate(1050.0 551.25) rotate(0.0 196.875 196.875) scale(3.6525296376690597 3.6525296376690597)">
                <svg width="107.802" height="107.802" viewBox="0 0 107.802 107.802">
                    <g fill={getColor(colorKeys.lightBackgroundFill, colorMode)}>
                        <path d="M0 15.557 15.556.001l92.245 92.245-15.556 15.556z" />
                        <path d="M0 92.246 92.243 0 107.8 15.557l-92.245 92.245z" />
                    </g>
                </svg>
            </g>
            <g
                transform="translate(506.25 1803.75) rotate(0.0 1025.625 65.625) scale(2.5640625 2.514367816091954)">
                <svg width="800" height="52.2" viewBox="0 273.9 800 52.2">
                    <path d="M800 326.1H0v-31.7c0-4.5 4.3-7.1 8.8-7.1l782.9-.5c4.5 0 8.3 3.1 8.3 7.6z"
                        fill={getColor(colorKeys.lightGrayBackgroundFill, colorMode)} />
                    <path
                        d="M60.5 293.5H22.9l4.5-19.6H43l2.9 3H56zm58.1 0H81.1l4.5-19.6h15.6l2.6 3h10.3zm58.2 0h-37.5l4.4-19.6h15.7l2.2 3h10.7zm58.2 0h-37.6l4.5-19.6h15.8l.5 1.3 12.2.1zm236.3 0h-37.5l4.5-19.6H454l.9 1.2 12 .1zm58.2 0H492l4.5-19.6h15.6l.9 1.2h12.1zm58.2 0h-37.6l4.5-19.6h15.6l1.3 2h11.7zm58.1 0h-37.5l4.5-19.6h15.6l1.6 2h11.3zm58.2 0h-37.5l4.5-19.6h15.6l1.2 2h11.7zm58.2 0h-37.6l4.5-19.6h15.6l1.6 2h11.4zm-353.5-18.6-31.1.7-100.7-.3-1.1-1.4h-15.7l-4.5 19.6h157.6z"
                        fill="#D2D3DA" />
                    <g fill="#E8E9EC">
                        <path
                            d="M76.1 293.5H38.5l4.5-19.6h28.6zm58.1 0H96.7l4.5-19.6h28.5zm58.2 0h-37.5l4.5-19.6h28.5zm58.2 0H213l4.5-19.6h28.6zm236.3 0h-37.5l4.5-19.6h28.6zm58.2 0h-37.5l4.5-19.6h28.5zm58.2 0h-37.6l4.5-19.6h28.6zm58.1 0h-37.5l4.5-19.6H657zm58.2 0h-37.5l4.5-19.6h28.5zm58.2 0h-37.6l4.5-19.6h28.6zm-353.5-19.6H275.7l-4.5 19.6h157.6z" />
                    </g>
                    <path d="M800 295.2H0v-.7c0-4.5 3.6-8.1 8.1-8.1h783.8c4.5 0 8.1 3.6 8.1 8.1z"
                        fill={getColor(colorKeys.lightGrayBackgroundFill, colorMode)} />
                </svg>
            </g>
        </svg>
    )
}

export default NoResults