import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { CONTENT_TYPE, MILISECONDS } from "../constants";
import { appendQueryParams } from "../helpers/queryHelper";;

export const useMedia = (params) => {
    const token = useSelector((state) => state.user?.token)
    Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] === "") {
            delete params[key]
        }
    })
    params = prepareData(params, API_CONSTANTS.MEDIA.filterKeys)
    return useQuery({
        queryKey: ["media", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.MEDIA.folder}?${appendQueryParams({params})}`,
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true,
        cacheTime: MILISECONDS.MAX,
        staleTime: MILISECONDS.MAX
    })
}

export const useCreateMedia = (setProgress) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Post({
                path: API_CONSTANTS.MEDIA.uploadSingle,
                token,
                body,
                toastError: true,
                toastMessage: true,
                contentType: CONTENT_TYPE.FORM_DATA,
                options: {
                    onUploadProgress: function (progressEvent) {
                        var percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(percentCompleted);
                    }
                }
            })
            return data
        },
    })
}

export const useCreateMultipleMedia = (setProgress) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Post({
                path: API_CONSTANTS.MEDIA.uploadMultiple,
                token,
                body,
                toastError: true,
                toastMessage: true,
                contentType: CONTENT_TYPE.FORM_DATA,
                options: {
                    onUploadProgress: function (progressEvent) {
                        var percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(percentCompleted);
                    }
                }
            })
            return data
        },
    })
}

export const useCreateVideo = (setProgress) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Post({
                path: API_CONSTANTS.MEDIA.uploadVimeo,
                token,
                body,
                toastError: true,
                toastMessage: true,
                contentType: CONTENT_TYPE.FORM_DATA,
                options: {
                    onUploadProgress: function (progressEvent) {
                        var percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(percentCompleted);
                    }
                }
            })
            return data
        },
    })
}

export const useCreateFolder = () =>{
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Post({
                path: API_CONSTANTS.MEDIA.folder,
                token,
                body,
                toastError: true,
                toastMessage: true,
            })
            return data
        },
    })
}

export const useUpdateMedia = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Put({
                path: `${API_CONSTANTS.MEDIA.base}/${id}`,
                token,
                body,
                toastError: true,
                toastMessage: true,
                contentType: CONTENT_TYPE.FORM_DATA
            })
            return data
        },
    })
}

export const useDeleteMedia = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async () => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.MEDIA.base}/${id}`,
                token,
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}