export const formatAddress = (input) => {
    if (typeof input !== 'object') {
        throw new Error('Input must be an object');
    }

    const { address, city, country } = input;

    let formattedAddress = '';

    if (address) {
        formattedAddress += address;
    }

    if (city) {
        if (formattedAddress.length > 0) {
            formattedAddress += ', ' + city;
        } else {
            formattedAddress += city;
        }
    }

    if (country) {
        if (formattedAddress.length > 0) {
            formattedAddress += ', ' + country;
        } else {
            formattedAddress += country;
        }
    }

    return formattedAddress;
}

export const accessValue = (object, path) => {
    if (typeof object !== 'object' || typeof path !== 'string') {
        throw new Error('Invalid input');
    }
    const properties = path.split('.');
    let value = object;
    for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        value = value?.[property];

        if (value === undefined) {
            return undefined;
        }
    }
    return value;
}

export const generateString = (length, onlyCaps = false, onlyNumbers = false) => {
    length = length ? length : 8;
    let charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    if (onlyCaps) {
        charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    }
    if (onlyNumbers) {
        charset = "1234567890";
    }
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
};

export const breakStringWithCaps = (string) => {
    return string.replace(/([a-z])([A-Z])/g, '$1 $2');
}