import { Box, Flex, Heading, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import QuestionsList from './QuestionsList'
import QuestionsForm from './QuestionsForm'

const Questions = () => {
  const formDisclosure = useDisclosure()

  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">Questions</Heading>
        <PrimaryButton onClick={() => formDisclosure.onOpen()}>Create New Question</PrimaryButton>
      </Flex>
      <QuestionsList />
      <QuestionsForm disclosure={formDisclosure} />
    </Box>
  )
}

export default Questions