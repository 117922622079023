import { Box, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import APP_ICONS from '../../../../config/constants/icons'
import CohortVariantTypeList from './CohortVariantTypeList'
import CohortVariantTypeForm from './CohortVariantTypeForm'

const CohortVariantsTypes = () => {
    const formDisclosure = useDisclosure()
    const [selectedCohortVariant, setSelectedCohortVariant] = React.useState(null)

    return (
        <Box>
            <Flex justify="space-between" align={"center"}>
                <Heading as="h2" fontSize={26} fontWeight="bold">Cohort Variant Types</Heading>
                <PrimaryButton
                    onClick={() => {
                        setSelectedCohortVariant(null)
                        formDisclosure.onOpen()
                    }}
                    leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
                >
                    Create New Cohort Variant Type
                </PrimaryButton>
            </Flex>
            <CohortVariantTypeList
                onEditModal={(data) => {
                    setSelectedCohortVariant(data)
                    formDisclosure.onOpen()
                }}
            />
            <CohortVariantTypeForm disclosure={formDisclosure} data={selectedCohortVariant} />
        </Box>
    )
}

export default CohortVariantsTypes