import { Text, Box, HStack, Heading, useDisclosure, Flex, Button, Icon, Progress, Wrap, WrapItem, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Link, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import React, { useState } from 'react'
import MediaItem from '../../../components/MediaLibrary/MediaItem'
import { getFileGroup } from '../../../config/constants/fileHelper'
import APP_ICONS from '../../../config/constants/icons'
import FormFileUpload from '../../../components/Forms/FormFileUpload'
import { useQueryClient } from '@tanstack/react-query'
import { useCreateMultipleMedia, useMedia } from '../../../config/query/mediaQuery'
import VideoForm from '../../../components/MediaLibrary/VideoForm'
import FolderForm from '../../../components/MediaLibrary/FolderForm'
import MediaLibrarySkeleton from '../../../components/Skeletons/MediaLibrarySkeleton'

const Media = () => {
    const queryClient = useQueryClient()
    const videoFormDisclosure = useDisclosure()
    const folderFormDisclosure = useDisclosure()

    const [selectedMedia, setSelectedMedia] = useState(null)
    const [progress, setProgress] = useState(0)
    const createMediaQuery = useCreateMultipleMedia(setProgress)

    const [query, setQuery] = useState({
        isTreeListing: true,
    })

    const mediaQuery = useMedia(query)

    //handing file upload
    const onChangeImage = (files) => {
        const formData = new FormData()
        Array.from(files).forEach((item, index) => {
            formData.append(`files[${index}].file`, item)
            formData.append(`files[${index}].name`, Date.now() + index)
            if (query.id) formData.append(`files[${index}].parentId`, query.id)
        })

        createMediaQuery.mutateAsync(formData).then((res) => {
            queryClient.invalidateQueries("media")
        }).catch((err) => {
            console.log(err, "error while uploading media")
        })
    }

    //handling back button click
    const handleBack = () => {
        setQuery({
            ...query,
            id: mediaQuery.data?.path?.at(-2)?.id || null,
        })
    }

    return (
        <Box>
            <Box w="full" >
                <Flex justifyContent={"space-between"} alignItems={"center"} w={"full"}>
                    <Box>
                        <Heading as={Link} _hover={{ textDecor: "underline" }} size={"md"} onClick={() => setQuery({
                            ...query,
                            id: null,
                        })}>
                            Media Library
                        </Heading>

                        <Breadcrumb separator={"/"} fontSize="14px" fontWeight="normal" >
                            {mediaQuery.data?.path?.map((item, index) => {
                                if (item.name === "Root") return null
                                return (
                                    <BreadcrumbItem key={index}>
                                        <BreadcrumbLink fontSize="14px" fontWeight="normal" onClick={() =>
                                            setQuery({
                                                ...query,
                                                id: item.id,
                                            })}
                                        >
                                            {item.name}
                                        </BreadcrumbLink>
                                    </BreadcrumbItem>
                                )
                            })}
                        </Breadcrumb>
                    </Box>
                    <HStack spacing={2}>
                        <Button onClick={handleBack}
                            leftIcon={<Icon as={APP_ICONS.LeftChevron} />}
                            isDisabled={mediaQuery.data?.path?.length <= 1}
                        >
                            Back
                        </Button>

                        <Button
                            onClick={() => mediaQuery.refetch()}
                            isLoading={mediaQuery.isFetching}
                        >
                            Refresh
                        </Button>

                        <Button
                            leftIcon={<Icon as={APP_ICONS.ADD_FOLDER} boxSize="5" />}
                            onClick={() => folderFormDisclosure.onOpen()}
                        >
                            Add Folder
                        </Button>


                        <Menu>
                            <MenuButton as={Button} colorScheme='green' leftIcon={<Icon as={APP_ICONS.UPLOAD} />}>
                                Upload
                            </MenuButton>
                            <MenuList>
                                <MenuItem h="32px">
                                    <FormFileUpload
                                        multiple={true}
                                        id={"profilePicture"}
                                        onChange={onChangeImage}
                                        component={<Text fontWeight="normal" w="full" textAlign="left">Images</Text>}
                                        componentProps={{ variant: "ghost", w: "full", textAlign: "left" }}
                                        fileType={"ANY"}
                                    />
                                </MenuItem>

                                <MenuItem h="32px" onClick={() => videoFormDisclosure.onOpen()}>
                                    <Text fontW fontWeight="normal" w="full" textAlign="left">Video</Text>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                </Flex>

                {createMediaQuery.isLoading && (
                    <Progress mt={5} hasStripe value={progress} />
                )}
            </Box>

            <Box mt="20px" h="calc(100vh - 155px)" overflowY="auto">
                <Wrap flexWrap={"wrap"} spacing={5}>
                    <MediaLibrarySkeleton isLoading={mediaQuery.isLoading} />
                    {mediaQuery.isError && mediaQuery.data?.items?.results?.length === 0 && <div>Error fetching data</div>}
                    {mediaQuery.data?.items?.results?.map((media, index) => {
                        const fileGroup = getFileGroup(media.extension)
                        return (
                            <WrapItem p="4px" key={media.id}>
                                <MediaItem
                                    key={index}
                                    media={media}
                                    isSelected={selectedMedia?.id === media.id}
                                    onClick={() => setSelectedMedia(media)}
                                    onDoubleClick={() => {
                                        console.log("kdjaskldjaskldjskldjlkas", fileGroup)
                                        if (fileGroup === "folder") {
                                            setQuery({
                                                ...query,
                                                id: media.id,
                                            })
                                            return;
                                        }
                                    }}
                                />
                            </WrapItem>
                        )
                    })}
                </Wrap>
            </Box>

            <VideoForm disclosure={videoFormDisclosure} parentId={query?.id} />
            <FolderForm disclosure={folderFormDisclosure} parentId={query?.id} />

        </Box>
    )
}

export default Media