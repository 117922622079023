import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import { prepareData } from "../helpers/apiHelper"
import API_CONSTANTS from "../constants/api";
import { appendQueryParams } from "../helpers/queryHelper";;

export const useCourses = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["courses", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.COURSES.base}?${appendQueryParams({params})}`,
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useCoursesFacet = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["coursesFacet"],
        queryFn: async () => {
            const {data} = await Get({
                path: API_CONSTANTS.COURSES.getFacet,
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useCourseOptions = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["courseOptions", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.COURSES.getOptions}?${appendQueryParams({params})}`,
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useCreateCourse = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.COURSES.dataKeys)
            const { data } = await Post({
                path: API_CONSTANTS.COURSES.base,
                token,
                body,
                toastError: true,
                toastMessage:true
            })
            return data
        },
    })
}

export const useUpdateCourse = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.COURSES.dataKeys)
            const { data } = await Put({
                path: `${API_CONSTANTS.COURSES.base}/${body.id}`,
                token,
                body,
                toastError: true,
                toastMessage:true
            })
            return data
        },
    })
}

export const useDeleteCourse = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.COURSES.base}/${id}`,
                token,
                toastError: true,
                toastMessage:true
            })
            return data
        },
    })
}