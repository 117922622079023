import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../components/Forms/FormDrawer'
import { Box, SimpleGrid } from '@chakra-ui/react'
import FormInput from '../../../components/Forms/FormInput'
import FormSwitch from '../../../components/Forms/FormSwitch'
import FormSearchSelect from '../../../components/Forms/FormSearchSelect'
import { useCohortOptions } from '../../../config/query/cohortQuery'
import { useChapterOptions } from '../../../config/query/chapterQuery'
import { makeSelectList } from '../../../config/helpers/selectListHelper'
import { useCreateLecture, useUpdateLecture } from '../../../config/query/lectureQuery'
import { STATUS, USER_TYPES } from '../../../config/constants/enums'
import { useUserOptions } from '../../../config/query/userQuery'
import FormTextarea from '../../../components/Forms/FormTextarea'
import MediaTrigger from '../../../components/MediaLibrary/MediaTrigger'
import MediaTriggerDropzone from '../../../components/MediaLibrary/MediaTriggerDropzone'
import { useQueryClient } from '@tanstack/react-query'

const LectureForm = ({ disclosure, data, chapterId, cohortId }) => {

    const queryClient = useQueryClient()

    const [query, setQuery] = useState({
        chapterOptionsQuery: {
            cohorts: data?.cohortId,
        }
    })

    const cohortOptionsQuery = useCohortOptions({})
    const chapterOptionsQuery = useChapterOptions(query?.chapterOptionsQuery)
    const teacherOptions = useUserOptions({ roles: USER_TYPES.Teacher })

    const createLectureQuery = useCreateLecture()
    const updateLectureQuery = useUpdateLecture(data?.id)

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        reset: resetForm,
        getValues: getFormValues,
        formState: { errors },
    } = useForm({
        values: {
            ...data,
            status: (data?.id && data?.status === STATUS.INACTIVE) ? false : true,
            lecture: data?.lecture?.videoMedia,
            availableFrom: data?.lecture?.availableFrom,
            availableTo: data?.lecture?.availableTo,
            description: data?.lecture?.description,
            cohortId: cohortId,
            chapterId: chapterId,
        },
    })

    const fields = watch()

    const onSubmit = async (values) => {
        values = {
            ...values,
            status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
            lecture: {
                availableFrom: values.availableFrom,
                availableTo: values.availableTo,
                videoMediaId: values.lecture?.id,
                description: values.description,
            }
        }
        delete values.availableFrom
        delete values.availableTo
        delete values.description
        let formMutate = data?.id ? updateLectureQuery.mutateAsync : createLectureQuery.mutateAsync
        formMutate(values)
            .then(() => {
                resetForm(Object.keys(getFormValues()).map((item) => ({ [item]: "" })))
                queryClient.invalidateQueries(["lectures"])
                disclosure.onClose()
            })
    }


    const handleFormChangeEffects = (name, value) => {
        const updatedQuery = { ...query[name], ...value }
        setQuery({ ...query, [name]: updatedQuery })
    }

    watch((data, { name, type }) => {
        if (type === "change") {
            if (name === "cohortId") {
                handleFormChangeEffects("chapterOptionsQuery", { cohorts: data[name] })
            }
        }
    })

    useEffect(() => {
        handleFormChangeEffects("chapterOptionsQuery", { cohorts: data?.cohortId })
    }, [data?.cohortId])

    return (
        <FormDrawer
            title={data ? "Edit Lecture" : "Add Lecture"}
            disclosure={disclosure}
            isSubmitting={createLectureQuery.isLoading || updateLectureQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
        >
            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={5}>

                <FormInput
                    label={"Name"}
                    control={control}
                    errors={errors}
                    id="name"
                    required={true}
                    placeholder="Enter lecture name"
                    rules={{
                        minLength: {
                            value: 3,
                            message: "Name must be at least 3 characters"
                        }
                    }}
                />

            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5} mt={5}>

                {!cohortId && (
                    <FormSearchSelect
                        id="cohortId"
                        label="Cohort"
                        placeholder={'Select Cohort'}
                        required={true}
                        errors={errors}
                        control={control}
                        options={makeSelectList(cohortOptionsQuery?.data)}
                    />
                )}

                {!chapterId && (
                    <FormSearchSelect
                        id="chapterId"
                        label="Chapter"
                        placeholder={'Select Chapter'}
                        required={true}
                        errors={errors}
                        control={control}
                        options={makeSelectList(chapterOptionsQuery?.data)}
                    />
                )}

                <FormSearchSelect
                    multiple={true}
                    id="teacherIds"
                    label="Teachers"
                    placeholder={'Select Teachers'}
                    required={true}
                    errors={errors}
                    control={control}
                    options={makeSelectList(teacherOptions?.data)}
                />

                <FormSwitch
                    label={"Status"}
                    control={control}
                    errors={errors}
                    id="status"
                />

            </SimpleGrid>

            <Box w="full" mt={5}>
                <MediaTrigger
                    component={<MediaTriggerDropzone
                        label={"Lecture Video"}
                        value={fields?.lecture?.blobFileUrl || data?.lecture?.videoMedia?.blobFileUrl}
                        placeholder={"Pro Tip: Click here to see your vimeo videos, you can select them to add in your lecture."}
                        onRemove={(e) => {
                            e.stopPropagation();
                            setValue("lecture", null)
                        }}
                    />}
                    type={"VIDEO"}
                    value={fields?.lecture}
                    label={"Lecture Video"}
                    control={control}
                    errors={errors}
                    id="lecture"
                    required={true}
                />
            </Box>

            <FormTextarea
                label={"Description"}
                placeholder="Enter short description of the lecture..."
                control={control}
                errors={errors}
                id="description"
                containerProps={{
                    mt: 10
                }}
            />
        </FormDrawer>
    )
}

export default LectureForm