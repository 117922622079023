import {
    useQuery,
    useMutation,
    useQueryClient
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { MILISECONDS } from "../constants";
import { appendQueryParams } from "../helpers/queryHelper";

export const useChapters = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["chapters", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.CHAPTERS.base}?${appendQueryParams({ params })}`,
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useChaptersFacet = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["chaptersFacet"],
        queryFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.CHAPTERS.getFacet,
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useChapterOptions = (params = {}) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["chapterOptions", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.CHAPTERS.getOptions}?${appendQueryParams({ params })}`,
                token,
                toastError: true,
            })
            return data
        },
        staleTime: MILISECONDS.MAX
    })
}

export const useCreateChapter = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.CHAPTERS.dataKeys)
            const { data } = await Post({
                path: API_CONSTANTS.CHAPTERS.base,
                token,
                body
            })
            return data
        },
    })
}

export const useUpdateChapter = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.CHAPTERS.dataKeys)
            const { data } = await Put({
                path: `${API_CONSTANTS.CHAPTERS.base}/${id}`,
                token,
                body
            })
            return data
        },
    })
}


export const useDeleteChapter = () => {
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.CHAPTERS.base}/${id}`,
                token,
                toastError: true,
                toastMessage: true
            })
            return data
        },
        onSuccess: () => queryClient.invalidateQueries(["chapters"])
    })
}