import React, { useState } from 'react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import { useDeleteMeeting, useMeetings, useMeetingsFacet } from '../../../../config/query/meetingQuery'
import { formatDateTime, formatDateTimeFromNow } from '../../../../config/helpers/dateHelper'
import APP_ICONS from '../../../../config/constants/icons'
import StatusBadge from '../../../../components/BasicUI/Badges/StatusBadge'
import Chronology from '../../../../components/BasicUI/DataBoxes/Chronology'
import { getFilters } from '../../../../config/helpers/filterHelper'
import TableInfoPopover from '../../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../../components/BasicUI/LabelValuePair'
import ZoomMeetingIcon from '../../../../components/Icons/ZoomMeetingIcon'
import AvatarStack from '../../../../components/BasicUI/DataBoxes/AvatarStack'
import { Text } from '@chakra-ui/react'

const MeetingList = ({ onEditModal }) => {

    const [query, setQuery] = useState({
        pageNo: 1,
        pageSize: 20,
    })

    const meetingsQuery = useMeetings(query)
    const meetingsFacetQuery = useMeetingsFacet()
    const deleteMeetingQuery = useDeleteMeeting()

    const handleDelete = (id) => {
        deleteMeetingQuery.mutateAsync(id)
            .then((response) => {
                meetingsQuery.refetch()
                console.log(`Meeting deleted successfully: ${response}`)
            })
            .catch((error) => {
                console.log(`Error while deleting meeting: ${error}`)
            })
    }

    return (
        <CustomTable
            containerProps={{ mt: 5 }}
            searchPlaceholder="Search meetings"
            filters={getFilters(meetingsFacetQuery?.data)}
            head={[
                {
                    title: "Name",
                    extractor: "name",
                    align: "left",
                    component: (item) => (
                        <TableInfoPopover
                            data={item}
                            onEdit={(item) => onEditModal(item)}
                            onDelete={(id) => handleDelete(id)}
                            triggerOnClick={() => onEditModal(item)}
                        >
                            <LabelValuePair label="Description" value={item.description} />
                            <LabelValuePair label="Chapter" value={item.chapterName} />
                            <LabelValuePair label="Cohort" value={item.cohortName} />
                            <LabelValuePair label="Course" value={item.courseName} />
                            <LabelValuePair label="Qualification" value={item.qualificationName} />
                            <LabelValuePair
                                label="Status"
                                value={true}
                                valueComponent={
                                    <StatusBadge value={item.status} />
                                }
                            />
                            <LabelValuePair label="Created" value={formatDateTime(item.createdBy?.dateTime)} />
                            <LabelValuePair label="Last Updated" value={formatDateTime(item.updateBy?.dateTime)} />
                            <ZoomMeetingIcon boxSize={7} />
                        </TableInfoPopover>
                    )
                },
                { title: "Start Date Time", extractor: "start" },
                { title: "Meeting Status", extractor: "meetingStatus" },
                { title: "Duration (Min)", extractor: "duration" },
                { title: "teachers", extractor: "teachers", align: "center", component: (item) => item.teachers?.length ? <AvatarStack data={item.teachers} /> : <Text fontSize="14px">N/A</Text> },
                { title: "Status", extractor: "status", component: (item) => <StatusBadge value={item.status} /> },
                {
                    title: "Last Modified",
                    extractor: "lastModified",
                    component: (item) => <Chronology data={item} />
                },
                { title: "Actions", extractor: "actions" }
            ]}
            data={meetingsQuery?.data?.results?.map((item) => {
                return {
                    ...item,
                    start: formatDateTime(item.start),
                    meetingStatus:item.meetingStatus,
                    duration: item.duration,
                    lastModified: item.updatedBy?.dateTime ? formatDateTimeFromNow(item.updatedBy?.dateTime) : formatDateTimeFromNow(item.createdBy?.dateTime),
                    actions: [
                        {
                            title: "Edit",
                            action: () => onEditModal(item),
                            icon: APP_ICONS.EDIT,
                            isEdit: true
                        },
                        {
                            title: "Delete",
                            action: handleDelete,
                            icon: APP_ICONS.BIN,
                            isDelete: true
                        }
                    ]
                }
            })}
            loading={meetingsQuery?.isLoading}
            totalResults={meetingsQuery?.data?.totalResults}
            totalPages={meetingsQuery?.data?.totalPages}
            pageNo={query.pageNo}
            pageSize={query.pageSize}
            onQueryChange={(updatedQuery) => setQuery({ ...query, ...updatedQuery })}
            query={query}
            onRefresh={() => meetingsQuery.refetch()}
            isRefreshing={meetingsQuery?.isFetching}
        />
    )
}

export default MeetingList