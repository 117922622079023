import { Avatar, AvatarBadge, AvatarGroup, Button, Flex, Icon, Text, Tooltip, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { colorKeys, getColor } from '../../config/constants/appColors'
import APP_ICONS from '../../config/constants/icons'

const MessageHeader = ({ chat,onRefresh,isRefreshing }) => {
    const { colorMode } = useColorMode()
    return (
        <Flex w="100%" mb={2} justify={"space-between"}>
            <Flex w="100%">
                <Avatar h="51px" w="51px" bg={getColor(colorKeys.gray, colorMode)} size="md" src={chat?.thumbnailMedia?.thumbnailUrl} name={chat?.name} />
                <Flex flexDirection="column" mx="5" justify="center">
                    <Text fontSize="lg" fontWeight="bold">
                        {chat?.name}
                    </Text>
                    {chat?.teachers?.map((item, index) =>
                        <Tooltip label={item?.name}>
                            <Avatar size="xs" key={index} src={item?.profilePicture} name={item?.name} />
                        </Tooltip>
                    )}
                </Flex>
            </Flex>

            <Button leftIcon={<Icon as={APP_ICONS.REFRESH} />} onClick={onRefresh} isLoading={isRefreshing}>Refresh</Button>
        </Flex>
    )
}

export default MessageHeader