import { Input, HStack, VStack, Heading, Flex, IconButton, Icon, useDisclosure, Tooltip, Card, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import { useZoomSettings, useUpdateZoomSettings, useSettings, useUpdateSetting } from '../../../config/query/settingQuery'
import FormFieldsSkeleton from '../../../components/Skeletons/FormFieldsSkeleton'
import FormTextarea from '../../../components/Forms/FormTextarea'
import APP_ICONS from '../../../config/constants/icons'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'
import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'


const Settings = () => {
    const queryClient = useQueryClient()
    const editDisclosure = useDisclosure()
    const [selectedSetting, setSelectedSetting] = useState(null)

    const settingsQuery = useSettings()
    const updateSettingsQuery = useUpdateSetting(selectedSetting?.id)

    const onEditModal = (item) => {
        setSelectedSetting(item)
        editDisclosure.onOpen()
    }

    const onClose = () => {
        setSelectedSetting(null)
        editDisclosure.onClose()
    }

    const onSave = () => updateSettingsQuery.mutateAsync(selectedSetting).then(() => {
        queryClient.invalidateQueries(["settings"])
        setSelectedSetting(null)
        editDisclosure.onClose()
    })


    const handleChange = (e) => {
        setSelectedSetting({
            ...selectedSetting,
            [e.target.name]: e.target.value
        })
    }

    return (
        <VStack spacing={5} align={"stretch"}>
            <Heading fontSize={"20px"}>Configurations</Heading>
            <SimpleGrid columns={{ base: 1, md: 4, }} spacing={5}>
                {settingsQuery.data?.results?.map((item, index) => {
                    const isEdit = Boolean(editDisclosure.isOpen && selectedSetting?.id == item.id)
                    return (
                        <Card key={index} p={"10px"}>
                            <Flex align={"center"} justify={"space-between"} maxW="500px" mb={isEdit ? 4 : 0}>

                                <Heading fontSize={"14px"} style={{ lineBreak: "anywhere" }}>{item.name}</Heading>


                                <HStack spacing={2}>

                                    <Tooltip label={isEdit ? "Close    " : "Edit"} aria-label="A tooltip">
                                        <IconButton
                                            ml={1}
                                            size="sm"
                                            icon={<Icon
                                                boxSize={isEdit ? "0.8em" : "1.25em"}
                                                as={isEdit ? APP_ICONS.CLOSE : APP_ICONS.EDIT}
                                            />}
                                            onClick={() => isEdit ? onClose() : onEditModal(item)}
                                        />
                                    </Tooltip>
                                    {isEdit && (
                                        <Tooltip label={"Save"} aria-label="A tooltip">
                                            <IconButton
                                                ml={1}
                                                size="sm"
                                                icon={<Icon
                                                    as={APP_ICONS.DOUBLE_CHECK}
                                                />}
                                                onClick={() => onSave()}
                                            />
                                        </Tooltip>
                                    )}
                                </HStack>
                            </Flex>

                            <LabelValuePair
                                headingProps={{ fontSize: 12 }}
                                label="Value"
                                value={true}
                                valueComponent={isEdit
                                    ? <Input mt={1} value={selectedSetting?.value} size="sm" onChange={handleChange} name="value" />
                                    : <Text>{item.value}</Text>}
                            />

                        </Card>
                    )
                })}
            </SimpleGrid>
        </VStack>
    )
}

export default Settings