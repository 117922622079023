import { Box, Flex, Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import React from 'react'

const ChatListSkeleton = () => {
    return (
        <>
            {new Array(9).fill(0).map((item, index) =>
                <Flex
                    key={index}
                    p="8px"
                    rounded="md"
                    w="full"
                    cursor={"pointer"}
                    role='group'
                >
                    <SkeletonCircle w="36px" h="32px" size="sm" />
                    <Box w="full" ml={2}>
                        <Skeleton w="150px" h="10px" fontSize={"14px"} fontWeight={"500"} />
                        <Box mt={3}>
                            <SkeletonText noOfLines={3} fontSize={"15px"}>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
                                molestiae quas vel sint commodi repudiandae consequuntur
                            </SkeletonText>
                        </Box>
                    </Box>
                </Flex>
            )}
        </>
    )
}

export default ChatListSkeleton