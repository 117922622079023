import React from "react";
import { COHORT_CONTENT_TYPES } from "../../config/constants/enums";
import LectureCard from "./LectureCard";
import QuizCard from "./QuizCard";
import NotesCard from "./NotesCard";

const ContentCard = ({ content, onDelete }) => {
  switch (content?.type) {
    case COHORT_CONTENT_TYPES.LECTURES:
      return <LectureCard item={content} onDelete={onDelete} />;
    case COHORT_CONTENT_TYPES.QUIZ:
      return <QuizCard item={content} onDelete={onDelete} />;
    default:
      return <NotesCard item={content} onDelete={onDelete} />;
  }
};

export default ContentCard;
