import { Box, Divider, Flex, Heading, Text, VStack, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getColor, colorKeys } from "../../../config/constants/appColors"
import SubNavItem from './SubNavItem'
import { useSelector } from 'react-redux'
import { checkIfSidebarShouldBeHidden } from '../../../config/constants/layoutHelpers'
import { useEffect } from 'react'

const SubSider = ({ containerStyles, sidebarOptions, title }) => {
    const { colorMode } = useColorMode()
    const { pathname } = useLocation()
    const { showSubsider } = useSelector((state) => state.setting)
    const params = useParams()
    const [isSiderHidden, setIsSiderHidden] = React.useState(false)

    useEffect(() => {
        setIsSiderHidden(checkIfSidebarShouldBeHidden(pathname, params))
    }, [pathname, params])


    return (
        <Box
            as="nav"
            pos="fixed"
            top="60px"
            left="80px"
            zIndex="sticky"
            h="full"
            p="0 .5rem"
            overflowX="hidden"
            overflowY="auto"
            boxShadow={"inset 0 2px 6px rgb(51 51 51 / 14%)"}
            bg={getColor(colorKeys.lightBackgroundFill, colorMode)}

            w="240px"
            display={{
                base: "none", md: !showSubsider
                    ? isSiderHidden
                        ? "none"
                        : "block"
                    : "none"
            }}
            {...containerStyles}
        >
            <Heading
                color={getColor(colorKeys.secondaryText, colorMode)}
                fontSize="15px"
                p="15px 8px 0px"
            >
                {title}
            </Heading>

            <VStack
                spacing={sidebarOptions?.at(0).children?.length > 0 ? 10 : 1}
                align={"stretch"}
                mt={4}
                direction="column"
                as="nav"
                fontSize="sm"
                color="primaryText"
                aria-label="Main Navigation"
            >
                {sidebarOptions?.map((item, index) => {
                    if (item.children?.length > 0) {
                        return (
                            <VStack align="stretch" spacing={1} key={index}>
                                <Heading
                                    color={getColor(colorKeys.secondaryText, colorMode)}
                                    fontSize="13px"
                                    p="0px 8px 0px"
                                    fontWeight={"normal"}
                                >
                                    {item.title}
                                </Heading>
                                <Divider />
                                <Box>
                                    {item.children.map((child, childIndex) => (
                                        <SubNavItem
                                            key={childIndex}
                                            icon={child.icon}
                                            title={child.title}
                                            link={child.link}
                                            isChild
                                        />
                                    ))}
                                </Box>
                            </VStack>
                        )
                    }
                    return (
                        <SubNavItem
                            key={index}
                            icon={item.icon}
                            title={item.title}
                            link={item.link}
                        />
                    )
                })}
            </VStack>
        </Box>
    )
}

export default SubSider