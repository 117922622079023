import React from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../../components/Forms/FormDrawer'
import { SimpleGrid } from '@chakra-ui/react'
import FormInput from '../../../../components/Forms/FormInput'
import FormSwitch from '../../../../components/Forms/FormSwitch'
import { useCreateCohortVariantType, useUpdateCohortVariantType } from '../../../../config/query/cohortVariantTypesQuery'
import { STATUS } from '../../../../config/constants/enums'
import { useQueryClient } from '@tanstack/react-query'

const CohortVariantForm = ({ disclosure, data }) => {
    const queryClient = useQueryClient()
    const createCohortVariantTypeQuery = useCreateCohortVariantType()
    const updateCohortVariantTypeQuery = useUpdateCohortVariantType(data?.id)

    const {
        handleSubmit,
        control,
        reset: resetForm,
        getValues: getFormValues,
        formState: { errors },
    } = useForm({
        values: { ...data, status: (data?.id && data?.status === STATUS.INACTIVE) ? false : true },
    })


    const onSubmit = (values) => {
        let formMutate = data?.id ? updateCohortVariantTypeQuery.mutateAsync : createCohortVariantTypeQuery.mutateAsync
        formMutate({
            name: values.name,
            status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
        })
            .then(() => {
                resetForm(Object.keys(getFormValues()).map((item) => ({ [item]: "" })))
                disclosure.onClose()
                queryClient.invalidateQueries(["cohortVariantTypes"])
            })
    }

    return (
        <FormDrawer
            title={data ? "Edit Cohort Variant" : "Add Cohort Variant"}
            disclosure={disclosure}
            isSubmitting={createCohortVariantTypeQuery.isLoading || updateCohortVariantTypeQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            containerProps={{ maxW: "50rem" }}
        >
            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={5}>
                <FormInput
                    label={"Name"}
                    control={control}
                    errors={errors}
                    id="name"
                    required={true}
                    placeholder="Enter cohort variant name"
                    rules={{
                        minLength: {
                            value: 3,
                            message: "Title must be at least 3 characters"
                        }
                    }}
                />

                <FormSwitch
                    label={"Status"}
                    control={control}
                    errors={errors}
                    id="status"
                />

            </SimpleGrid>

        </FormDrawer>
    )
}

export default CohortVariantForm