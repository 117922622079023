import { Avatar, HStack, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

const AvatarNamesStack = ({ data, avatarProps, nameProps, containerProps, stackProps, content }) => {
    return (
        <Wrap spacing={5} {...containerProps}>
            {data?.map((item) =>
                <WrapItem key={item.id}>
                    <HStack spacing={1}  {...stackProps} >
                        <Avatar size="xs" name={item?.name} src={item?.profilePicture} {...avatarProps} />
                        <VStack align="stretch" spacing={0}>
                            <Text fontSize="14px" {...nameProps}>{item.name}</Text>
                            {content}
                        </VStack>
                    </HStack>
                </WrapItem>
            )}
        </Wrap>
    )
}

export default AvatarNamesStack