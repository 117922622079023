import React from 'react'
import { FormControl, FormErrorMessage, FormLabel, useColorMode, chakra } from '@chakra-ui/react'
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import { useController } from 'react-hook-form';
import { getColor, colorKeys } from '../../../config/constants/appColors';
import { accessValue } from '../../../config/helpers/stringHelper';

const RichTextEditor = ({ id, rules = {}, control, label, errors, containerProps, required, placeholder = "Enter detailed text..." }) => {
    const { colorMode } = useColorMode()

    if (required) {
        required = `${label} is required`
    }

    const { field } = useController({
        name: id,
        rules: {
            required: required ? `${label} is required` : false,
            ...rules
        },
        control: control,
    })

    return (
        <FormControl isInvalid={accessValue(errors, `${id}.message`)} {...containerProps}>
            <FormLabel htmlFor={id}>
                {label}
                {required && <chakra.span color={getColor(colorKeys.danger, colorMode)}>*</chakra.span>}
            </FormLabel>
            <RichTextEditorComponent
                change={(e) => field.onChange(e.value)}
                value={field.value}
                enableResize={true}
                height={300}
                enableHtmlSanitizer={true}
                enableAutoUrl={true}
                enableTabKey={true}
                blur={() => field.onBlur()}
                delayUpdate={true}
                placeholder={placeholder}
                backgroundColor={"red"}
                style={{
                    zIndex: 0,
                    ...(accessValue(errors, `${id}.message`) && { borderColor: "red", background: "red" }),
                }}
            >
                <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
            </RichTextEditorComponent>
            <FormErrorMessage>
                {accessValue(errors, `${id}.message`)}
            </FormErrorMessage>
        </FormControl >
    )
}

export default RichTextEditor