import { Box, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../components/BasicUI/Buttons/PrimaryButton'
import UserList from './UserList'
import UserForm from './UserForm'
import APP_ICONS from '../../../config/constants/icons'

const Users = () => {
  const formDisclosure = useDisclosure()
  const [selectedUser, setSelectedUser] = React.useState(null)

  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">Users</Heading>
        <PrimaryButton onClick={() => {
          setSelectedUser(null)
          formDisclosure.onOpen()
        }}
          leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
        >
          Create New User
        </PrimaryButton>
      </Flex>
      <UserList
        onEditModal={(data) => {
          setSelectedUser(data)
          formDisclosure.onOpen()
        }}
      />
      <UserForm disclosure={formDisclosure} data={selectedUser} />
    </Box>
  )
}

export default Users