import { Box, FormLabel, Icon, IconButton, chakra, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { colorKeys, getColor } from '../../config/constants/appColors'
import APP_ICONS from '../../config/constants/icons'

const MediaTriggerDropzone = ({ value, label, placeholder, onRemove, error }) => {
    const { colorMode } = useColorMode()
    return (
        <Box h="125px" w="full">
            <FormLabel htmlFor="writeUpFile">{label}</FormLabel>
            <Box
                bg={`url(${value}) no-repeat center center / cover `}
                backgroundSize="contain"
                backgroundColor={getColor(colorKeys.lightBackgroundFill, colorMode)}
                w="100%"
                h="100%"
                border={!value ? "2px dashed #E2E8F0" : "2px solid #E2E8F0"}
                padding="10px"
            >
                <Box
                    display="flex"
                    flexDir={"column"}
                    justifyContent="center"
                    cursor={"pointer"}
                >
                    {(value && onRemove) && <IconButton onClick={onRemove} size="xs" colorScheme='red' alignSelf={"flex-end"} icon={<Icon as={APP_ICONS.CLOSE} />} variant="solid" aria-label="Remove" />}
                    {!value &&
                        <Box p="15px" textAlign={"center"}>
                            <chakra.p fontWeight={"normal"}>Click here to open media library</chakra.p>
                            {!error && <chakra.p fontWeight={"normal"} whiteSpace={"break-spaces"} color={getColor(colorKeys.gray, colorMode)}>{placeholder}</chakra.p>}
                            {error && <chakra.p mt={3} fontWeight={"normal"} whiteSpace={"break-spaces"} color={getColor(colorKeys.danger, colorMode)}>{error}</chakra.p>}
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default MediaTriggerDropzone