import React, { useState } from 'react'
import CustomTable from '../../../components/BasicUI/CustomTable'
import { useDeleteEmailLog, useEmailLogs, useEmailLogsFacet } from '../../../config/query/emailLogsQuery'
import { formatDateTime, formatDateTimeFromNow } from '../../../config/helpers/dateHelper'
import APP_ICONS from '../../../config/constants/icons'
import { Box, useDisclosure } from '@chakra-ui/react'
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge'
import Chronology from '../../../components/BasicUI/DataBoxes/Chronology'
import { getFilters } from '../../../config/helpers/filterHelper'
import TableInfoPopover from '../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'
import EmailLogView from './EmailLogView'

const EmailLogs = () => {

  const [selectedLog, setSelectedLog] = useState(null)
  const viewDisclosure = useDisclosure()

  const [query, setQuery] = useState({
    pageNo: 1,
    pageSize: 20,
  })

  const emailLogsQuery = useEmailLogs(query)
  const emailLogsFacetQuery = useEmailLogsFacet()
  const deleteEmailLogsQuery = useDeleteEmailLog()

  const handleDelete = (id) => {
    deleteEmailLogsQuery.mutateAsync(id)
      .then((response) => {
        emailLogsQuery.refetch()
        console.log(`Email logs deleted successfully: ${response}`)
      })
      .catch((error) => {
        console.log(`Error while deleting email log: ${error}`)
      })
  }

  const handleView = (log) => {
    setSelectedLog(log)
    viewDisclosure.onOpen()

  }

  return (
    <Box w="full">
      <CustomTable
        containerProps={{ mt: 5 }}
        searchPlaceholder="Search email logs"
        filters={getFilters(emailLogsFacetQuery?.data)}
        head={[
          {
            title: "Name",
            extractor: "name",
            align: "left",
            component: (item) => (
              <TableInfoPopover
                data={item}
                onDelete={(id) => handleDelete(id)}
                triggerOnClick={() => handleView(item)}
              >
                <LabelValuePair label="Created" value={formatDateTime(item.createdBy?.dateTime)} />
                <LabelValuePair label="Last Updated" value={formatDateTime(item.updateBy?.dateTime)} />
              </TableInfoPopover>
            )
          },
          {
            title: "Last Modified",
            extractor: "lastModified",
            component: (item) => <Chronology data={item} />
          },
          { title: "Actions", extractor: "actions" }
        ]}
        data={emailLogsQuery?.data?.results?.map((item) => {
          return {
            ...item,
            lastModified: item.updatedBy?.dateTime ? formatDateTimeFromNow(item.updatedBy?.dateTime) : formatDateTimeFromNow(item.createdBy?.dateTime),
            actions: [
              {
                title: "View",
                action: () => handleView(item),
                icon: APP_ICONS.EYE,
                isView: true
              },
              {
                title: "Delete",
                action: handleDelete,
                icon: APP_ICONS.BIN,
                isDelete: true
              }
            ]
          }
        })}
        loading={emailLogsQuery?.isLoading}
        totalResults={emailLogsQuery?.data?.totalResults}
        totalPages={emailLogsQuery?.data?.totalPages}
        pageNo={query.pageNo}
        pageSize={query.pageSize}
        onQueryChange={(updatedQuery) => setQuery({ ...query, ...updatedQuery })}
        query={query}
        onRefresh={() => emailLogsQuery.refetch()}
        isRefreshing={emailLogsQuery?.isFetching}
      />

      <EmailLogView disclosure={viewDisclosure} emailLog={selectedLog}/>
    </Box>
  )
}

export default EmailLogs