import React from 'react'
// import {
//     DocumentEditorComponent,
//     Print,
//     SfdtExport,
//     WordExport,
//     TextExport,
//     Selection,
//     Search,
//     Editor,
//     ImageResizer,
//     EditorHistory,
//     ContextMenu,
//     OptionsPane,
//     HyperlinkDialog,
//     TableDialog,
//     BookmarkDialog,
//     TableOfContentsDialog,
//     PageSetupDialog,
//     StyleDialog,
//     ListDialog,
//     ParagraphDialog,
//     BulletsAndNumberingDialog,
//     FontDialog,
//     TablePropertiesDialog,
//     BordersAndShadingDialog,
//     TableOptionsDialog,
//     CellOptionsDialog,
//     StylesDialog
// } from '@syncfusion/ej2-react-documenteditor';

// DocumentEditorComponent.Inject(
//     Print,
//     SfdtExport,
//     WordExport,
//     TextExport,
//     Selection,
//     Search,
//     Editor,
//     ImageResizer,
//     EditorHistory,
//     ContextMenu,
//     OptionsPane,
//     HyperlinkDialog,
//     TableDialog,
//     BookmarkDialog,
//     TableOfContentsDialog,
//     PageSetupDialog,
//     StyleDialog,
//     ListDialog,
//     ParagraphDialog,
//     BulletsAndNumberingDialog,
//     FontDialog,
//     TablePropertiesDialog,
//     BordersAndShadingDialog,
//     TableOptionsDialog,
//     CellOptionsDialog,
//     StylesDialog
// );

import {
    DocumentEditorContainerComponent,
    Toolbar,
    Print,
    SfdtExport,
    WordExport,
    TextExport,
    Selection,
    Search,
    Editor,
    ImageResizer,
    EditorHistory,
    ContextMenu,
    OptionsPane,
    HyperlinkDialog,
    TableDialog,
    BookmarkDialog,
    TableOfContentsDialog,
    PageSetupDialog,
    StyleDialog,
    ListDialog,
    ParagraphDialog,
    BulletsAndNumberingDialog,
    FontDialog,
    TablePropertiesDialog,
    BordersAndShadingDialog,
    TableOptionsDialog,
    CellOptionsDialog,
    StylesDialog,
} from '@syncfusion/ej2-react-documenteditor';

DocumentEditorContainerComponent.Inject(
    Toolbar,
    Print,
    SfdtExport,
    WordExport,
    TextExport,
    Selection,
    Search,
    Editor,
    ImageResizer,
    EditorHistory,
    OptionsPane,
    HyperlinkDialog,
    TableDialog,
    BookmarkDialog,
    TableOfContentsDialog,
    PageSetupDialog,
    StyleDialog,
    ListDialog,
    ParagraphDialog,
    BulletsAndNumberingDialog,
    FontDialog,
    TablePropertiesDialog,
    BordersAndShadingDialog,
    TableOptionsDialog,
    CellOptionsDialog,
    StylesDialog,
    ContextMenu
);

const DocumentEditor = () => {

    return (
        <>
            <div className='control-pane'>
                <div className='control-section'>
                    <div id='documenteditor_titlebar' className="e-de-ctn-title"></div>
                    <div id="documenteditor_container_body">
                        <DocumentEditorContainerComponent
                            id="container"
                            style={{ 'display': 'block' }}
                            height={'590px'}
                            isReadOnly={false}
                            enableLocalPaste={true}
                            enableAllModules={true}
                            onChange={(e) => console.log("onchange", e)}
                            contentChange={(e) => console.log("content change", e)}
                            locale='en-US' />
                    </div>
                </div>
                <script>{window.onbeforeunload = function () {
                    return 'Want to save your changes?';
                }}
                </script>
            </div>
        </>
    );
}

export default DocumentEditor