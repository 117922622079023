import { Box, Button, Card, CardBody, CardHeader, useColorMode, chakra, Flex, Heading, Icon, Image, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import APP_ICONS from '../../../config/constants/icons'
import { getColor, colorKeys } from '../../../config/constants/appColors'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import IMAGES from '../../../config/constants/images'

const Dashboard = () => {
  const { user } = useSelector((state) => state.user)
  const { colorMode } = useColorMode()

  const modules = [
    // {
    //   title: "Exams",
    //   description: "Create a more serious knowledge test with a pass or fail mark and even generate certificates automatically.",
    //   image: IMAGES.KNOWLY_CLIPBOARD,
    //   link: "/admin/exams"
    // },
    {
      title: "Syllabus",
      description: "Create any number of courses and lectures and organize them into categories.",
      image: IMAGES.KNOWLY_HAT,
      link: "/admin/syllabus"
    },
    // {
    //   title: "Users",
    //   description: "Create students, teachers, and other admins and assign them to courses.",
    //   image: IMAGES.KNOWLY_USERS,
    //   link: "/admin/users"
    // },
    // {
    //   title: "Chat",
    //   description: "Make announcements to large group of users in a user-friendly manner.",
    //   image: IMAGES.KNOWLY_WINGS,
    //   link: "/admin/chat"
    // },
    // {
    //   title: "Emails",
    //   description: "Send variety of emails to large number of students or teachers within few clicks.",
    //   image: IMAGES.KNOWLY_GAME,
    //   link: "/admin/emails"
    // },
    {
      title: "Settings",
      description: "Configure your application settings and manage your account.",
      image: IMAGES.KNOWLY_STATS,
      link: "/admin/settings"
    }
  ]

  return (
    <Box p="3rem 0">
      <Box maxW={"1110px"} m="0 auto">
        <Box>
          <Heading color={getColor(colorKeys.primaryText, colorMode)} fontSize="26px" fontWeight={"900"} lineHeight="1.4">Hi {user?.name}</Heading>
          <Text fontSize="15px" color={getColor(colorKeys.secondaryText, colorMode)}>Not sure what to choose? Read about the <chakra.span color={getColor(colorKeys.highlightedText, colorMode)}>options for creating learning content.</chakra.span></Text>
        </Box>

        <SimpleGrid columns={{ base: 1, md: 3 }} mt={5} spacingX={10} spacingY={10}>

          {modules.map((item, index) =>
            <Card borderRadius={"3px"} key={index}>
              <CardHeader h="87px" overflow={"hidden"} bg={getColor(colorKeys.whiteSmoke, colorMode)} p="15px 30px">
                <Flex justifyContent={"space-between"}>
                  <Box>
                    <Heading fontSize={"22px"}>{item.title}</Heading>
                    <Button to={item.link} fontSize={"15px"} color={getColor(colorKeys.primaryButtonFill, colorMode)} as={Link} variant="link" rightIcon={<Icon as={APP_ICONS.RightChevron} />}>Browse</Button>
                  </Box>
                  <Image w="33%" maxW="115px" src={item.image} />
                </Flex>
              </CardHeader>
              <CardBody p="30px" bg={getColor(colorKeys.white, colorMode)}>
                <Text m="0 0 15px" fontSize={"15px"}>{item.description}</Text>
              </CardBody>
            </Card>
          )}

        </SimpleGrid>

      </Box>
    </Box>
  )
}

export default Dashboard