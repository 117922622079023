import Config from "../../config"

export const BASE_URL = Config.env().BASE_URL

const API_CONSTANTS = {
    //General
    UPLOADS: {
        post: "/files/upload",
        get: (fileName, isPublic = false, expiryMinutes = '') => `/files/get?fileName=${isPublic ? "[public]" : "[private]"}${fileName}&expiryMinutes=${expiryMinutes}`,
    },
    AUTH: {
        login: "/authorize",
        updateProfile: "/users/me/update-profile",
        updateProfilePicture: "/users/me/update-profile/picture",
        updatePassword: "/users/me/update-profile/password",

    },


    //Syllabus
    QUALIFICATIONS: {
        base: "/qualifications", //options = {searchQuery,pageSize,pageNo,fromDate,toDate,statuses}
        getFacet: "/qualifications/facet",
        getOptions: "/qualifications/select-options",
        dataKeys: ["name", "status"]
    },
    COURSES: {
        base: "/courses", //options = {searchQuery,pageSize,pageNo,fromDate,toDate,qualifications,statuses}
        getFacet: "/courses/facet",
        getOptions: "/courses/select-options",
        dataKeys: ["qualificationId", "id", "name", "status", "createdOn", "updatedOn", "deletedOn"]
    },
    COHORT_VARIANTS_TYPES: {
        base: '/cohort-variant-types',
        getFacet: '/cohort-variant-types/facet',
        getOptions: '/cohort-variant-types/select-options',
    },
    COHORT_VARIANTS: {
        base: '/cohort-variants',
        getFacet: '/cohort-variants/facet',
        getOptions: '/cohort-variants/select-options',
    },
    COHORTS: {
        base: "/cohorts", //options = {searchQuery,pageSize,pageNo,fromDate,toDate,courses,teachers,statuses}
        getFacet: "/cohorts/facet",
        getOptions: "/cohorts/select-options",
        dataKeys: ["courseId", "teacherIds", "startDate", "endDate", "order", "shortDescription", "longDescription", "price", "discount", "thumbnailMediaId", "headerMediaId", "id", "name", "status", "isDeleted", "createdOn", "updatedOn", "deletedOn"]
    },
    COHORT_CONTENT: {
        base: "/cohort-content",
        getFacet: "/cohort-content/facet",
        getOptions: "/cohort-content/select-options",
    },
    CHAPTERS: {
        base: "/chapters", //options = {searchQuery,pageSize,pageNo,fromDate,toDate,cohorts,statuses}
        getFacet: "/chapters/facet",
        getOptions: "/chapters/select-options",
        dataKeys: ["name", "cohortId", "cohortVariantIds", "isDemo", "order", "files", "status"]
    },

    //Lectures
    LECTURES: {
        base: "/lectures", //options = {searchQuery,pageSize,pageNo,fromDate,toDate,onlyAvailable,chapters,cohorts,statuses}
        getFacet: "/lectures/facet",
        getOptions: "/lectures/select-options",
        dataKeys: ["name", "cohortId", "chapterId", "lecture", "order", "status", "teacherIds"]
    },
    MEETINGS: {
        base: "/meetings", //options = {searchQuery,pageSize,pageNo,fromDate,toDate,chapters,cohorts,isUpcoming,statuses}
        getFacet: "/meetings/facet",
        getOptions: "/meetings/select-options",
        dataKeys: ["name", "cohortId", "chapterId", "startDateTime", "endDateTime", "postLecture", "status"]
    },

    //Users
    USERS: {
        base: "/users", //options = {searchQuery,pageSize,pageNo,fromDate,toDate,statuses,roles,cohorts}
        getFacet: "/users/facet",
        getOptions: "/users/select-options",
        dataKeys: ["id", "name", "fatherName", "phoneNumber", "email", "roleId", "password", "description", "country", "city", "address", "profilePicture", "allowedDevices", "cohorts", "status"]
    },
    ROLES: {
        base: "/user-roles", //options = {searchQuery,pageSize,pageNo,fromDate,toDate,statuses}
        getFacet: "/user-roles/facet",
        getOptions: "/user-roles/select-options",
        getPermissions: "/user-roles/permissions",
        dataKeys: ["id", "name", "permissionNames", "status", "isAdmin"]
    },

    //Emails
    EMAIL_TEMPLATES: {
        base: "/email-templates", //options = {searchQuery,pageSize,pageNo,fromDate,toDate,statuses}
        getFacet: "/email-templates/facet",
        getOptions: "/email-templates/select-options",
        dataKeys: ["name", "htmlTemplate", "status"]
    },

    //EMAIL LOGS
    EMAIL_LOGS: {
        base: "/email-logs",
        getFacet: "/email-logs/facet",
        getOptions: "/email-logs/select-options",
        dataKeys: ["name", "status"]
    },

    //Settings
    SETTINGS: {
        base: "/settings", //options = {searchQuery,pageSize,pageNo,fromDate,toDate,statuses}
        getFacet: "/settings/facet",
        getOptions: "/settings/select-options",
        email: "/settings/email",
        vimeo: "/settings/vimeo",
        zoom: "/settings/zoom",
        blobStorage: "/settings/azure-blob",
        dataKeys: ["name", "value", "status"]
    },

    //Bin
    DELETED_ITEMS: {
        base: "/deleted-items", //options = {searchQuery,pageSize,pageNo,fromDate,toDate,types}
        getFacet: "/deleted-items/facet",
        getOptions: "/deleted-items/select-options",
    },

    //Quizzes
    EXAMS: {
        base: "/quizzes", //options = {}
        getFacet: "/quizzes/facet",
        getOptions: "/quizzes/select-options",
        getQuestions: "/quizzes/:id/questions",
        upsertQuestions: "/quizzes/:id/questions",
        updateStatus: "/quizzes/:id/update-status",
        dataKeys: [
            "id",
            "name",
            "cohortId",
            "chapterId",
            "teacherIds",
            "quizz",
            "order",
            "status",
        ]
    },

    //Quiz Questions
    EXAM_QUESTIONS: {
        base: "/quizz-questions",
        getFacet: "/quizz-questions/facet",
        getOptions: "/quizz-questions/select-options",
        autoSelect: "/quizz-questions/auto-select",
        readFile: "/quizz-questions/read-file",
        downloadFile: "/quizz-questions/download-file",
    },

    //Quiz Results
    EXAM_RESULTS: {
        base: "/quizz-results",
        getFacet: "/quizz-results/facet",
        getOptions: "/quizz-results/select-options",
    },

    //Media
    MEDIA: {
        base: "/media",
        folder: "/media/folder",
        getFacet: "/media/facet",
        uploadSingle: "/media/file",
        uploadMultiple: "/media/files",
        uploadVimeo: "/media/video/vimeo",
        getOptions: "/media/select-options",
        filterKeys: [
            "id",
            "extensions",
            "parent",
        ],
        dataKeys: [
            "name",
            "file",
            "parentId",
        ]
    },

    //NOTES
    NOTES: {
        base: "/notes", //options = {searchQuery,pageSize,pageNo,fromDate,toDate,cohorts,statuses}
        getFacet: "/notes/facet",
        getOptions: "/notes/select-options",
        dataKeys: ["name", "cohortId", "chapterId", "teacherIds", "note", "status"]
    },

    //CHATS
    CHATS: {
        base: "/cohort-chats",
        getFacet: "/cohort-chats/facet",
        getOptions: "/cohort-chats/select-options",
        dataKeys: ["message", "status", "cohortId",]
    },

    //CHAT MESSAGES
    CHAT_MESSAGES: {
        base: "/cohort-chat-messages",
        getFacet: "/cohort-chat-messages/facet",
        dataKeys: ["message", "cohortChatId", "cohortId", "status"]
    },

    //DEVICES
    DEVICES: {
        base: "/user-devices",
        updateStatus: "/user-devices/:id/update-status",
        getFacet: "/user-devices/facet",
        getOptions: "/user-devices/select-options",
        dataKeys: [
            "userId",
            "udId",
            "platformName",
            "platformVersion",
            "platformProcessor",
            "deviceType",
            "browserName",
            "browserVersion",
            "engineName",
            "crawlerName",
            "crawlerVersion",
            "isCrawler",
            "lastLoginDateTime",
            "name",
            "status",
        ],
    },

    //Zoom Accounts
    ZOOM_ACCOUNTS: {
        base: "/settings/zoom",
        getOptions: "/settings/zoom/select-options",
    },

}

export default API_CONSTANTS