import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../../components/Forms/FormDrawer'
import { Flex, HStack, Heading, SimpleGrid, chakra } from '@chakra-ui/react'
import FormInput from '../../../../components/Forms/FormInput'
import FormSearchSelect from '../../../../components/Forms/FormSearchSelect'
import FormSwitch from '../../../../components/Forms/FormSwitch'
import { useCohortOptions } from '../../../../config/query/cohortQuery'
import { useChapterOptions } from '../../../../config/query/chapterQuery'
import { makeSelectList } from '../../../../config/helpers/selectListHelper'
import { useCreateMeeting, useUpdateMeeting } from '../../../../config/query/meetingQuery'
import { STATUS, USER_TYPES } from '../../../../config/constants/enums'
import { useQueryClient } from '@tanstack/react-query'
import { useZoomAccountsOptions } from '../../../../config/query/zoomQuery'
import { useUserOptions } from '../../../../config/query/userQuery'

const MeetingForm = ({ disclosure, data }) => {

    const queryClient = useQueryClient()

    const [query, setQuery] = useState({
        chapterOptionsQuery: {
            cohorts: data?.cohortId,
        }
    })

    const cohortOptionsQuery = useCohortOptions({})
    const chapterOptionsQuery = useChapterOptions(query?.chapterOptionsQuery)
    const teacherOptions = useUserOptions({ roles: USER_TYPES.Teacher })
    const zoomAccountOptionsQuery = useZoomAccountsOptions()


    const createMeetingQuery = useCreateMeeting()
    const updateMeetingQuery = useUpdateMeeting(data?.id)

    const {
        handleSubmit,
        control,
        setValue,
        reset: resetForm,
        getValues: getFormValues,
        formState: { errors },
        watch,
    } = useForm({
        values: {
            ...data,
            status: (data?.id && data?.status === STATUS.INACTIVE) ? false : true
        },
    })

    const onSubmit = (values) => {
        let formMutate = data?.id ? updateMeetingQuery.mutateAsync : createMeetingQuery.mutateAsync

        formMutate({
            ...values,
            status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE
        })
            .then(() => {
                resetForm(Object.keys(getFormValues()).map((item) => ({ [item]: "" })))
                queryClient.invalidateQueries(["meetings"])
                disclosure.onClose()
            })
    }

    const handleFormChangeEffects = (name, value) => {
        const updatedQuery = { ...query[name], ...value }
        setQuery({ ...query, [name]: updatedQuery })
    }

    watch((data, { name, type }) => {
        if (type === "change") {
            if (name === "cohortId") {
                handleFormChangeEffects("chapterOptionsQuery", { cohorts: data[name] })
            }
        }
    })

    useEffect(() => {
        handleFormChangeEffects("chapterOptionsQuery", { cohorts: data?.cohortId })
    }, [data?.cohortId])


    return (
        <FormDrawer
            title={data?.id ? "Edit Meeting" : "Create Meeting"}
            disclosure={disclosure}
            isSubmitting={createMeetingQuery.isLoading || updateMeetingQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
        >
            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={5}>

                <FormInput
                    label={"Name"}
                    control={control}
                    errors={errors}
                    id="name"
                    required={true}
                    placeholder="Enter lecture name"
                    rules={{
                        minLength: {
                            value: 3,
                            message: "Name must be at least 3 characters"
                        }
                    }}
                />

            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5} mt={5}>

                <FormSearchSelect
                    id="cohortId"
                    label="Cohort"
                    placeholder={'Select Cohort'}
                    required={true}
                    errors={errors}
                    control={control}
                    options={makeSelectList(cohortOptionsQuery?.data)}
                />

                <FormSearchSelect
                    id="chapterId"
                    label="Chapter"
                    placeholder={'Select Chapter'}
                    required={true}
                    errors={errors}
                    control={control}
                    options={makeSelectList(chapterOptionsQuery?.data)}
                />

                <FormSearchSelect
                    multiple={true}
                    id="teacherIds"
                    label="Teachers"
                    placeholder={'Select Teachers'}
                    required={true}
                    errors={errors}
                    control={control}
                    options={makeSelectList(teacherOptions?.data)}
                />

                <FormSearchSelect
                    id="zoomAccountId"
                    label="Zoom Account"
                    placeholder={'Select Zoom Account'}
                    required={true}
                    errors={errors}
                    control={control}
                    options={makeSelectList(zoomAccountOptionsQuery?.data)}
                />
            </SimpleGrid>


            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mt={5}>
                <FormInput
                    label={"Start Date Time"}
                    control={control}
                    errors={errors}
                    id="start"
                    required={true}
                    type={"datetime-local"}
                />

                <FormInput
                    id={"duration"}
                    label="Duration (Minutes)"
                    type="number"
                    min={1}
                    placeholder="Enter number of minutes"
                    required={true}
                    errors={errors}
                    control={control}
                />

                <HStack spacing={10}>
                    <FormSwitch
                        label={"Status"}
                        control={control}
                        errors={errors}
                        id="status"
                        containerProps={{ w: "fit-content" }}
                    />
                    <FormSwitch
                        label={"Create Lecture"}
                        control={control}
                        errors={errors}
                        id="createLecture"
                        containerProps={{ w: "fit-content" }}
                    />
                </HStack>
            </SimpleGrid>



        </FormDrawer>
    )
}

export default MeetingForm

// "postLecture": {
// "name": "Lecture 1 (15.11.22)",
// "availableStartDateTime": "2012-04-23T18:25:43.511Z",
// "availableEndDateTime": "2012-04-23T18:25:43.511Z"
// },
// "status": 1