import { generateString } from "../stringHelper";

export const optionObject = {
    label: "",
    isCorrect: false
}

export const generateOptionObject = () => {
    return {
        ...optionObject,
        id: generateString(10)
    }
}