import { Box, Flex, Heading, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../components/BasicUI/Buttons/PrimaryButton'
import QuizList from './QuizList'
import QuizForm from './QuizForm'

const Quizzes = () => {
    const formDisclosure = useDisclosure()
    const [selectedQuiz, setSelectedQuiz] = React.useState(null)

    return (
        <Box>
            <Flex justify="space-between" align={"center"}>
                <Heading as="h2" fontSize={26} fontWeight="bold">Quizzes</Heading>
                <PrimaryButton onClick={() => formDisclosure.onOpen()}>Create New Quiz</PrimaryButton>
            </Flex>
            <QuizList />
            <QuizForm disclosure={formDisclosure} data={selectedQuiz} />
        </Box>
    )
}

export default Quizzes