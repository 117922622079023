import { Box, Modal, chakra, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Skeleton, Image } from '@chakra-ui/react'
import React, { useEffect } from 'react'

const ModalPlayer = ({ isOpen, onClose, title, data, type = "VIDEO" }) => {
    const [loading, setLoading] = React.useState(true)
    useEffect(() => {
        setLoading(true)
    }, [isOpen])
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
            <ModalOverlay />
            <ModalContent maxW="70rem" maxH={{ base: "unset", md: "calc(100vh - 50px)" }} overflow={"auto"}>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {type === "VIDEO"
                        ? <>
                            {loading && <Skeleton height="600px" width="100%" />}
                            <Box display={loading ? "none" : "block"} style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                                <chakra.iframe
                                    // src={data?.lecture?.videoMedia?.vimeoVideo?.playerEmbedUrl}
                                    src={`https://player.vimeo.com/video/${data?.lecture?.videoMedia?.blobFile}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                                    frameBorder={0}
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen=""
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%"
                                    }}
                                    title=""
                                    onLoad={() => setLoading(false)}
                                />
                            </Box>
                        </>
                        : <Image src={data?.blobFileUrl} h="650px" objectFit={"contain"} w="full" />
                    }

                </ModalBody>

                <ModalFooter>

                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default ModalPlayer