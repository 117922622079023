import { Box, chakra, VStack, Heading, Flex, IconButton, Icon, useDisclosure, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { useForm } from 'react-hook-form'
import FormButton from '../../../../components/Forms/FormButton'
import FormInput from '../../../../components/Forms/FormInput'
import { EMAIL_REGEX } from '../../../../config/constants/regexConstants'
import FormSwitch from '../../../../components/Forms/FormSwitch'
import { useBlobStorageSettings, useUpdateBlobStorageSettings } from '../../../../config/query/settingQuery'
import FormFieldsSkeleton from '../../../../components/Skeletons/FormFieldsSkeleton'
import FormTextarea from '../../../../components/Forms/FormTextarea'
import APP_ICONS from '../../../../config/constants/icons'


const BlobStorageSettings = () => {
    const editDisclosure = useDisclosure()

    const blobStorageSettingsQuery = useBlobStorageSettings()
    const updateBlobStorageSettingsQuery = useUpdateBlobStorageSettings()

    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        values: blobStorageSettingsQuery.data
    })

    function onSubmit(values) {
        updateBlobStorageSettingsQuery.mutate(values)
    }
    return (
        <Box>
            <chakra.form onSubmit={handleSubmit(onSubmit)}>
                <Flex align={"center"} justify={"space-between"} maxW="500px">
                    <Heading fontSize={"20px"}>Azure Blob Storage Settings</Heading>
                    <Tooltip label={editDisclosure.isOpen ? "Close" : "Edit"} aria-label="A tooltip">
                        <IconButton
                            icon={<Icon
                                boxSize={editDisclosure.isOpen ? "0.8em" : "1.25em"}
                                as={editDisclosure.isOpen ? APP_ICONS.CLOSE : APP_ICONS.EDIT}
                            />}
                            onClick={() => editDisclosure.onToggle()}
                        />
                    </Tooltip>
                </Flex>


                <Box mt={4} maxW="500px">

                    {blobStorageSettingsQuery.isLoading
                        ? <FormFieldsSkeleton columns={1} count={3} />
                        : <VStack spacing={4} align={"stretch"}>

                            <FormInput
                                id={"appName"}
                                label="App Name"
                                placeholder="Enter app name"
                                required={true}
                                errors={errors}
                                control={control}
                                inputProps={{
                                    isDisabled: updateBlobStorageSettingsQuery.isLoading || !editDisclosure.isOpen
                                }}
                            />

                            <FormInput
                                id={"accountName"}
                                label="Account Name"
                                placeholder="Enter account name"
                                required={true}
                                errors={errors}
                                control={control}
                                inputProps={{
                                    isDisabled: updateBlobStorageSettingsQuery.isLoading || !editDisclosure.isOpen
                                }}
                            />

                            <FormTextarea
                                id={"connectionString"}
                                label="Connection String"
                                placeholder="Enter connection string"
                                required={true}
                                errors={errors}
                                control={control}
                                inputProps={{
                                    isDisabled: updateBlobStorageSettingsQuery.isLoading || !editDisclosure.isOpen
                                }}
                            />

                            {editDisclosure.isOpen && (
                                <FormButton
                                    mr="auto"
                                    isLoading={updateBlobStorageSettingsQuery.isLoading}
                                    type="submit"
                                    containerStyles={{ textAlignLast: "right" }}

                                >
                                    Save Changes
                                </FormButton>
                            )}
                        </VStack>
                    }



                </Box>

            </chakra.form>
        </Box>
    )
}

export default BlobStorageSettings