import React from 'react'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    useDisclosure,
    Text,
    Box
} from '@chakra-ui/react'

const Dump = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Popover
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            placement='right'
            closeOnBlur={false}
        >
            <PopoverTrigger>
                <Text>Trigger</Text>
            </PopoverTrigger>
            <PopoverContent p={5}>
                <Box>

                </Box>
            </PopoverContent>
        </Popover>
    )
}

export default Dump