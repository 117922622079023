
import { Box, Card, CardBody, useColorMode, Divider, Button, Flex, HStack, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import Messages from '../../../components/ChatComponents/Messages'
import MessageFooter from '../../../components/ChatComponents/MessageFooter'
import MessageHeader from '../../../components/ChatComponents/MessageHeader'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { colorKeys, getColor } from '../../../config/constants/appColors'
import { useChat } from '../../../config/query/chatQuery'
import { makeInitialQueryObject } from '../../../config/helpers/queryHelper'
import { useChatMessages, useCreateChatMessage } from '../../../config/query/chatMessageQuery'
import { useQueryClient } from '@tanstack/react-query'
import sortOrders from '../../../config/constants/sortOrders'

const ChatBox = () => {
  const { colorMode } = useColorMode()
  const { chatId } = useParams();
  const chatQuery = useChat(chatId)
  const [query, setQuery] = useState(
    makeInitialQueryObject({
      currentQuery: true, injectParams: {
        pageNo: 1,
        pageSize: 20,
        sortBy: "createdOn",
        sortOrder: sortOrders.DESC,
        chats: chatId
      }
    })
  )
  const queryClient = useQueryClient()
  const chatMessagesQuery = useChatMessages(query)
  const createMessageQuery = useCreateChatMessage()
  const [inputMessage, setInputMessage] = useState("");

  const handleSendMessage = async (e) => {
    if (!inputMessage.trim().length) {
      return;
    }
    const messageObject = {
      message: inputMessage,
      cohortChatId: chatId,
      cohortId: chatQuery.data?.cohortId,
      status: 1
    }
    setInputMessage("");
    createMessageQuery.mutateAsync(messageObject).then(() => queryClient.invalidateQueries(["chat-messages"]))
  };

  useEffect(() => {
    setQuery({
      ...query,
      chats: chatId
    })
  }, [chatId])

  return (
    <Box>
      <Card>
        <CardBody bg={getColor(colorKeys.tableBackground, colorMode)}>
          <MessageHeader chat={chatQuery.data} onRefresh={chatMessagesQuery.refetch} isRefreshing={chatMessagesQuery.isFetching}/>
          <Divider />
          <Messages messages={chatMessagesQuery.data?.results} />
          <Divider />
          <MessageFooter
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            handleSendMessage={handleSendMessage}
          />
        </CardBody>
      </Card>
    </Box>
  )
}

export default ChatBox