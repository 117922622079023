import { Box, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import CoursesList from './CoursesList'
import CourseForm from './CourseForm'
import APP_ICONS from '../../../../config/constants/icons'

const Courses = () => {
  const formDisclosure = useDisclosure()
  const [selectedCourse, setSelectedCourse] = React.useState(null)

  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">Courses</Heading>
        <PrimaryButton
          onClick={() => {
            setSelectedCourse(null)
            formDisclosure.onOpen()
          }}
          leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
        >
          Create New Course
        </PrimaryButton>
      </Flex>
      <CoursesList
        onEditModal={(data) => {
          setSelectedCourse(data)
          formDisclosure.onOpen()
        }}
      />
      <CourseForm disclosure={formDisclosure} data={selectedCourse} />
    </Box>
  )
}

export default Courses