import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { appendQueryParams } from "../helpers/queryHelper";;

export const useDevices = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["devices", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.DEVICES.base}?${appendQueryParams({params})}`,
                token,
                toastError: true,
            })
            return data
        },
        enabled: Boolean(params?.users),
        keepPreviousData: true
    })
}

export const useUpdateDeviceStatus = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async ({ id, status }) => {
            const { data } = await Put({
                path: API_CONSTANTS.DEVICES.updateStatus.replace(":id", id),
                token,
                body: { status },
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}

export const useDeleteDevice = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.DEVICES.base}/${id}`,
                token,
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}