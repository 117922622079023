import { Box, Button, Flex, HStack, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAutoSelectQuestions } from '../../../../../config/query/questionsQuery'
import QuestionSuggestionBox from '../../../../../components/BasicUI/DataBoxes/QuestionSuggestionBox'
import APP_ICONS from '../../../../../config/constants/icons'
import { useQuiz, useQuizQuestions, useQuizUpsertQuestions } from '../../../../../config/query/quizQuery'
import { useParams } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import NoResults from '../../../../../components/SVGComponents/NoResults'

const SuggestQuestions = ({ disclosure, query }) => {
    const queryClient = useQueryClient()

    const [questions, setQuestions] = useState([])

    const { quizId } = useParams()
    const autoSelectQuestionsQuery = useAutoSelectQuestions()
    const quizQuestionsQuery = useQuizQuestions(quizId)
    const upsertQuestionsQuery = useQuizUpsertQuestions(quizId)

    useEffect(() => {
        if (questions?.length === 0 && disclosure.isOpen) {
            autoSelectQuestionsQuery.mutateAsync(query).then((data) => {
                setQuestions(data)
            })
        }
    }, [disclosure?.isOpen])

    const onAdd = (index) => {
        const newQuestions = [...questions]
        newQuestions[index].isAdded = true
        setQuestions(newQuestions)
    }

    const onRemove = (index) => {
        const newQuestions = [...questions]
        newQuestions[index].isAdded = false
        setQuestions(newQuestions)
    }

    const onRemoveFromList = (index) => {
        const newQuestions = [...questions]
        newQuestions.splice(index, 1)
        setQuestions(newQuestions)
    }

    const onAddAll = () => {
        const newQuestions = [...questions]
        newQuestions.forEach(item => item.isAdded = true)
        setQuestions(newQuestions)
    }

    const onRemoveAll = () => {
        const newQuestions = [...questions]
        newQuestions.forEach(item => item.isAdded = false)
        setQuestions(newQuestions)
    }

    const onSubmit = () => {
        const newQuestions = questions.filter(item => item.isAdded)
        upsertQuestionsQuery.mutateAsync(
            [
                ...quizQuestionsQuery.data,
                ...newQuestions
            ]
        ).then(() => {
            queryClient.invalidateQueries(["quizQuestions", quizId])
            disclosure.onClose()
        })
    }

    const allAdded = questions?.filter(item => item.isAdded).length === questions?.length

    return (
        <Modal isOpen={disclosure?.isOpen} onClose={disclosure?.onClose} isCentered={true}>
            <ModalOverlay />
            <ModalContent maxW="90rem" maxH={{ base: "unset", md: "calc(100vh - 50px)" }} overflow={"auto"}>
                <ModalHeader>Suggested Question</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex justify="space-between" align="center" mb={5}>
                        <Text>Here are some suggested questions for you to add to this quiz.</Text>
                        <HStack spacing={2}>
                            <Button
                                leftIcon={<Icon as={APP_ICONS.REFRESH} boxSize={"5"} />}
                                size="sm"
                                isLoading={autoSelectQuestionsQuery.isLoading}
                                onClick={() => {
                                    autoSelectQuestionsQuery.mutateAsync(query).then((data) => {
                                        setQuestions(data)
                                    })
                                }}
                            >Refresh</Button>
                            <Button
                                colorScheme={allAdded ? "red" : "gray"}
                                size="sm"
                                onClick={allAdded ? onRemoveAll : onAddAll}
                                leftIcon={<Icon as={allAdded ? APP_ICONS.CLOSE : APP_ICONS.ADD} boxSize={allAdded ? "3" : "6"} />}
                                isDisabled={questions?.length === 0}
                            >
                                {allAdded ? "Remove all" : "Add all"}
                            </Button>
                            <Button
                                size="sm"
                                colorScheme="green"
                                onClick={onSubmit}
                                leftIcon={<Icon as={APP_ICONS.DONE} />}
                                isLoading={upsertQuestionsQuery.isLoading}
                                isDisabled={questions?.length === 0}
                            >
                                Finish
                            </Button>
                        </HStack>
                    </Flex>
                    {questions?.length === 0 && <Box m="auto" maxW="350px">
                        <NoResults />
                    </Box>}

                    <VStack spacing={5} align={"stretch"}>
                        {questions?.map((item, index) =>
                            <QuestionSuggestionBox
                                isAdded={item.isAdded}
                                onAdd={() => onAdd(index)}
                                onRemove={() => onRemove(index)}
                                onRemoveFromList={() => onRemoveFromList(index)}
                                question={item}
                                key={item.id}
                            />
                        )}
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="green" onClick={onSubmit} isLoading={upsertQuestionsQuery.isLoading}>Finish</Button>
                    <Button colorScheme="gray" onClick={disclosure.onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default SuggestQuestions