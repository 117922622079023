import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { appendQueryParams } from "../helpers/queryHelper";;

export const useContents = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["contents", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.COHORT_CONTENT.base}?${appendQueryParams({ params })}`,
                token,
                toastError: true,
            })
            return data
        },
        keepPreviousData: true,
        enabled: !params.disabled
    })
}

export const useContentsFacet = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["contentsFacet"],
        queryFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.COHORT_CONTENT.getFacet,
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useContentOptions = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["contentOptions", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.COHORT_CONTENT.getOptions}?${appendQueryParams({ params })}`,
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useCreateContent = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Post({
                path: API_CONSTANTS.COHORT_CONTENT.base,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}

export const useUpdateContent = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Put({
                path: `${API_CONSTANTS.COHORT_CONTENT.base}/${id}`,
                token,
                body,
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}

export const useDeleteContent = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.COHORT_CONTENT.base}/${id}`,
                token,
                toastError: true,
                toastMessage: true
            })
            return data
        },
    })
}