import React from 'react'
import { Box, SimpleGrid, Skeleton } from '@chakra-ui/react'

const FormFieldsSkeleton = ({ count = 3, columns = { base: 1, md: 3 }, spacing = 5, fieldHeight = "40px" }) => {
    return (
        <SimpleGrid columns={columns} spacing={spacing}>
            {count > 0 && Array(count).fill().map((item, index) => (
                <Box key={index}>
                    <Skeleton height="20px" maxW="120px" />
                    <Skeleton height={fieldHeight} mt={2} />
                </Box>
            ))}
        </SimpleGrid>
    )
}

export default FormFieldsSkeleton