import React from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../../components/Forms/FormDrawer'
import { Icon, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, useColorMode } from '@chakra-ui/react'
import FormInput from '../../../../components/Forms/FormInput'
import FormTextarea from '../../../../components/Forms/FormTextarea'
import FormSwitch from '../../../../components/Forms/FormSwitch'
import { useCreateCohort, useUpdateCohort } from '../../../../config/query/cohortQuery'
import { useUserOptions } from '../../../../config/query/userQuery'
import { STATUS, USER_TYPES } from '../../../../config/constants/enums'
import { makeSelectList } from '../../../../config/helpers/selectListHelper'
import FormSearchSelect from '../../../../components/Forms/FormSearchSelect'
import MediaTrigger from '../../../../components/MediaLibrary/MediaTrigger'
import MediaTriggerDropzone from '../../../../components/MediaLibrary/MediaTriggerDropzone'
import { useQueryClient } from '@tanstack/react-query'
import { useCourseOptions } from '../../../../config/query/courseQuery'
import APP_ICONS from '../../../../config/constants/icons'
import { isTabClean } from '../../../../config/helpers/errorHandler'
import { getColor, colorKeys } from '../../../../config/constants/appColors'
import RichTextEditor from '../../../../components/Forms/RichTextEditor'

const CohortForm = ({ disclosure, data }) => {
    const { colorMode } = useColorMode()
    const queryClient = useQueryClient()

    const teacherOptions = useUserOptions({ roles: USER_TYPES.Teacher })
    const courseOptions = useCourseOptions()

    const createCohortQuery = useCreateCohort()
    const updateCohortQuery = useUpdateCohort(data?.id)

    const imagesFields = ["headerMedia", "thumbnailMedia"]
    const informationFields = ["name", "description", "status", "price", "discount", "teacherId", "courseId"]

    const {
        handleSubmit,
        control,
        setValue,
        reset: resetForm,
        getValues: getFormValues,
        formState: { errors },
        watch,
    } = useForm({
        values: { ...data, status: (data?.id && data?.status === STATUS.INACTIVE) ? false : true },

    })

    const onSubmit = async (values) => {
        let formMutate = data?.id ? updateCohortQuery.mutateAsync : createCohortQuery.mutateAsync
        formMutate({
            ...values,
            status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
            price: parseFloat(values.price),
            discount: parseFloat(values.discount),
            headerMediaId: values.headerMedia.id,
            thumbnailMediaId: values.thumbnailMedia.id,
        })
            .then(() => {
                resetForm(Object.keys(getFormValues()).map((item) => ({ [item]: "" })))
                queryClient.invalidateQueries(["cohorts"])
                disclosure.onClose()
            })

    }

    const fields = watch()
    return (
        <FormDrawer
            title={data ? "Edit Cohort" : "Add Cohort"}
            disclosure={disclosure}
            isSubmitting={createCohortQuery.isLoading || updateCohortQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            containerProps={{ maxW: "75rem" }}
        >
            <Tabs defaultIndex={0}>
                <TabList>
                    <Tab color={!isTabClean({ fields: informationFields, errors }) ? getColor(colorKeys.danger, colorMode) : ""}>Information {!isTabClean({ fields: informationFields, errors }) && <Icon ml={2} as={APP_ICONS.WARNING} />}</Tab>
                    <Tab color={!isTabClean({ fields: imagesFields, errors }) ? getColor(colorKeys.danger, colorMode) : ""}>Images {!isTabClean({ fields: imagesFields, errors }) && <Icon ml={2} as={APP_ICONS.WARNING} />}</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                            <FormInput
                                label={"Name"}
                                control={control}
                                errors={errors}
                                id="name"
                                required={true}
                                placeholder="Enter cohort name"
                                rules={{
                                    minLength: {
                                        value: 3,
                                        message: "Name must be at least 3 characters"
                                    }
                                }}
                            />

                            <FormInput
                                label={"Price"}
                                type={"number"}
                                control={control}
                                errors={errors}
                                id="price"
                                required={true}
                                placeholder="Enter cohort price"
                            />

                            <FormInput
                                label={"Discount"}
                                type={"number"}
                                control={control}
                                errors={errors}
                                id="discount"
                                required={false}
                                placeholder="Enter cohort discount"
                            />

                            <FormInput
                                label={"Start Date"}
                                type={"date"}
                                control={control}
                                errors={errors}
                                id="startDate"
                                required={true}
                            />
                            <FormInput
                                label={"End Date"}
                                type={"date"}
                                control={control}
                                errors={errors}
                                id="endDate"
                                required={true}
                            />

                            <FormSearchSelect
                                id="courseId"
                                label="Course"
                                placeholder={'Select Course'}
                                required={true}
                                errors={errors}
                                control={control}
                                options={makeSelectList(courseOptions?.data)}
                            />

                            <FormSearchSelect
                                multiple={true}
                                id="teacherIds"
                                label="Teachers"
                                placeholder={'Select Teachers'}
                                required={true}
                                errors={errors}
                                control={control}
                                options={makeSelectList(teacherOptions?.data)}
                            />

                            <FormSwitch
                                label={"Status"}
                                control={control}
                                errors={errors}
                                id="status"
                            />

                        </SimpleGrid>
                        
                        <RichTextEditor
                            label={"Description"}
                            placeholder="Enter short description of the cohort..."
                            control={control}
                            errors={errors}
                            id="shortDescription"
                            containerProps={{
                                mt: 5
                            }}
                        />
                    </TabPanel>
                    <TabPanel>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                            <MediaTrigger
                                component={<MediaTriggerDropzone
                                    label={"Header Image"}
                                    value={fields?.headerMedia?.blobFileUrl}
                                    placeholder={"Pro Tip: Header image should be 2MB max and will be shown on the top of the page."}
                                    onRemove={(e) => {
                                        e.stopPropagation();
                                        setValue("headerMedia", null)
                                    }}
                                    error={errors?.headerMedia?.message}
                                />}
                                type={"IMAGE"}
                                label={"Header Image"}
                                value={fields?.headerMedia}
                                control={control}
                                errors={errors}
                                id="headerMedia"
                                required={true}
                            />

                            <MediaTrigger
                                component={<MediaTriggerDropzone
                                    label={"Thumbnail Image"}
                                    value={fields?.thumbnailMedia?.blobFileUrl} //to show thumbnail in dropzone
                                    placeholder={"Pro Tip: Header image should be 2MB max and will be shown on the top of the page."}
                                    onRemove={(e) => {
                                        e.stopPropagation();
                                        setValue("thumbnailMedia", null)
                                    }}
                                    error={errors?.thumbnailMedia?.message}
                                />}
                                type={"IMAGE"}
                                value={fields?.thumbnailMedia} //to tell media library which image is selected
                                control={control}
                                errors={errors}
                                id="thumbnailMedia"
                                label={"Thumbnail Image"}
                                required={true}
                            />

                        </SimpleGrid>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </FormDrawer>
    )
}

export default CohortForm

//"files": [
//    {
//        "title": "Google Link",
//        "fileType": 2,
//        "fileData": "https://www.google.com/"
//    }
//],