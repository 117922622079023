import React, { useState } from 'react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import APP_ICONS from '../../../../config/constants/icons'
import { useDeleteCohortVariantType, useCohortVariantTypes, useCohortVariantTypesFacet } from '../../../../config/query/cohortVariantTypesQuery'
import { formatDateTime, formatDateTimeFromNow } from '../../../../config/helpers/dateHelper'
import { getFilters } from '../../../../config/helpers/filterHelper'
import LabelValuePair from '../../../../components/BasicUI/LabelValuePair'
import TableInfoPopover from '../../../../components/BasicUI/Popovers/TableInfoPopover'
import StatusBadge from '../../../../components/BasicUI/Badges/StatusBadge'
import Chronology from '../../../../components/BasicUI/DataBoxes/Chronology'
import { makeInitialQueryObject } from '../../../../config/helpers/queryHelper'
import { useNavigate } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'

const CohortVariantsList = ({ onEditModal }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [query, setQuery] = useState(
    makeInitialQueryObject({
      currentQuery: true, injectParams: {
        pageNo: 1,
        pageSize: 20,
      }
    })
  )

  const cohortVariantTypeQuery = useCohortVariantTypes(query)
  const cohortVariantTypeFacetQuery = useCohortVariantTypesFacet()
  const deleteCohortVariantTypeQuery = useDeleteCohortVariantType()

  const handleDelete = (id) => {
    deleteCohortVariantTypeQuery.mutateAsync(id)
      .then((response) => {
        queryClient.invalidateQueries(["cohortVariantTypes", "cohortVariantTypeOptions"])
        console.log(`Cohort Variant Type deleted successfully: ${response}`)
      })
      .catch((error) => {
        console.log(`Error while deleting cohort variant: ${error}`)
      })
  }

  const handleQueryChange = (updatedQuery) => {
    setQuery(updatedQuery)
    navigate(`?${makeInitialQueryObject({
      injectParams: updatedQuery,
      prepareQueryString: true
    })}`)
  }

  return (
    <CustomTable
      containerProps={{ mt: 5 }}
      searchPlaceholder="Search cohort variants"
      filters={getFilters(cohortVariantTypeFacetQuery?.data)}
      head={[
        {
          title: "Name",
          extractor: "name",
          align: "left",
          isSortable: true,
          component: (item) => (
            <TableInfoPopover
              data={item}
              onEdit={(item) => onEditModal(item)}
              onDelete={(id) => handleDelete(id)}
              triggerOnClick={() => onEditModal(item)}
            >
              <LabelValuePair label="Description" value={item.description} />
              <LabelValuePair
                label="Status"
                value={true}
                valueComponent={
                  <StatusBadge value={item.status} />
                }
              />
              <LabelValuePair label="Created" value={formatDateTime(item.createdBy?.dateTime)} />
              <LabelValuePair label="Last Updated" value={formatDateTime(item.updateBy?.dateTime)} />
            </TableInfoPopover>
          )
        },
        {
          title: "Status",
          extractor: "status",
          isSortable: true,
          component: (item) => <StatusBadge value={item.status} />
        },
        {
          title: "Last Modified",
          extractor: "lastModified",
          component: (item) => <Chronology data={item} />
        },
        {
          title: "Actions",
          extractor: "actions"
        }
      ]}
      data={cohortVariantTypeQuery?.data?.results?.map((item) => {
        return {
          ...item,
          lastModified: item.updatedBy ? formatDateTimeFromNow(item.updatedBy?.dateTime) : formatDateTimeFromNow(item.createdBy?.dateTime),
          actions: [
            {
              title: "Edit",
              action: () => onEditModal(item),
              icon: APP_ICONS.EDIT,
              isEdit: true
            },
            {
              title: "Delete",
              action: handleDelete,
              icon: APP_ICONS.BIN,
              isDelete: true
            }
          ]
        }
      })}
      loading={cohortVariantTypeQuery?.isLoading}
      totalResults={cohortVariantTypeQuery?.data?.totalResults}
      totalPages={cohortVariantTypeQuery?.data?.totalPages}
      pageNo={query.pageNo}
      pageSize={query.pageSize}
      onQueryChange={(updatedQuery) => handleQueryChange({ ...query, ...updatedQuery })}
      query={query}
      onRefresh={() => cohortVariantTypeQuery.refetch()}
      isRefreshing={cohortVariantTypeQuery?.isFetching}
      sortBy={query?.sortBy}
      sortOrder={query?.sortOrder}
    />
  )
}

export default CohortVariantsList

