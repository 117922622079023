import { Box, Button, Flex, HStack, Heading, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack, useColorMode } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { saveAs } from 'file-saver'
import { useAutoSelectQuestions, useDownloadQuestionFile, useReadQuestionFile } from '../../../../../config/query/questionsQuery'
import QuestionSuggestionBox from '../../../../../components/BasicUI/DataBoxes/QuestionSuggestionBox'
import APP_ICONS from '../../../../../config/constants/icons'
import { useQuiz, useQuizQuestions, useQuizUpsertQuestions } from '../../../../../config/query/quizQuery'
import { useParams } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import NoResults from '../../../../../components/SVGComponents/NoResults'
import FormDropzone from '../../../../../components/Forms/FormDropzone'
import FormFileUpload from '../../../../../components/Forms/FormFileUpload'
import { colorKeys, getColor } from '../../../../../config/constants/appColors'

const ImportQuestions = ({ disclosure }) => {
    const queryClient = useQueryClient()
    const { colorMode } = useColorMode()
    const [questions, setQuestions] = useState([])

    const { quizId } = useParams()
    const readQuestionFileQuery = useReadQuestionFile()
    const downloadQuestionFileQuery = useDownloadQuestionFile()
    const quizQuestionsQuery = useQuizQuestions(quizId)
    const upsertQuestionsQuery = useQuizUpsertQuestions(quizId)

    const onDownloadFile = (e) => {
        e.stopPropagation();
        downloadQuestionFileQuery.mutateAsync().then((data) => {
            saveAs(data, "questions-sample-file.xlsx")
        })
    }

    const onChangeFile = (file) => {
        const formData = new FormData()
        formData.append("file", file)
        readQuestionFileQuery.mutateAsync(formData).then((data) => {
            setQuestions(data)
        })
    }

    const onAdd = (index) => {
        const newQuestions = [...questions]
        newQuestions[index].isAdded = true
        setQuestions(newQuestions)
    }

    const onRemove = (index) => {
        const newQuestions = [...questions]
        newQuestions[index].isAdded = false
        setQuestions(newQuestions)
    }

    const onRemoveFromList = (index) => {
        const newQuestions = [...questions]
        newQuestions.splice(index, 1)
        setQuestions(newQuestions)
    }

    const onAddAll = () => {
        const newQuestions = [...questions]
        newQuestions.forEach(item => item.isAdded = true)
        setQuestions(newQuestions)
    }

    const onRemoveAll = () => {
        const newQuestions = [...questions]
        newQuestions.forEach(item => item.isAdded = false)
        setQuestions(newQuestions)
    }

    const onSubmit = () => {
        const newQuestions = questions.filter(item => item.isAdded)
        upsertQuestionsQuery.mutateAsync(
            [
                ...quizQuestionsQuery.data,
                ...newQuestions
            ]
        ).then(() => {
            queryClient.invalidateQueries(["quizQuestions", quizId])
            disclosure.onClose()
        })
    }

    const allAdded = questions?.filter(item => item.isAdded).length === questions?.length

    return (
        <Modal isOpen={disclosure?.isOpen} onClose={disclosure?.onClose} isCentered={true}>
            <ModalOverlay />
            <ModalContent maxW="90rem" maxH={{ base: "unset", md: "calc(100vh - 50px)" }} overflow={"auto"}>
                <ModalHeader>Import Questions</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex justify="space-between" align="center" mb={5}>
                        <Text>Let's speed up the process by simply importing questions to the quiz.</Text>
                        <HStack spacing={2}>
                            <Button
                                colorScheme={allAdded ? "red" : "gray"}
                                size="sm"
                                onClick={allAdded ? onRemoveAll : onAddAll}
                                leftIcon={<Icon as={allAdded ? APP_ICONS.CLOSE : APP_ICONS.ADD} boxSize={allAdded ? "3" : "6"} />}
                                isDisabled={questions?.length === 0}
                            >
                                {allAdded ? "Remove all" : "Add all"}
                            </Button>
                            <Button
                                size="sm"
                                colorScheme="green"
                                onClick={onSubmit}
                                leftIcon={<Icon as={APP_ICONS.DONE} />}
                                isLoading={upsertQuestionsQuery.isLoading}
                                isDisabled={questions?.length === 0}
                            >
                                Finish
                            </Button>
                        </HStack>
                    </Flex>
                    {questions?.length === 0 &&
                        <VStack
                            w="full"
                            border={`2px dashed ${getColor(colorKeys.tableFilterBorder, colorMode)}`}
                            spacing={4} flexDir={"column"} justify={"center"} align={"center"} minH={"180px"}>
                            <Heading fontSize={"22px"}>Upload Excel File Here</Heading>
                            <Text mt="3px" fontSize={"14px"} fontWeight={"normal"}>Pro Tip: You can download the sample excel file from the download button below and fill it up.</Text>
                            <HStack spacing={2}>
                                <Button onClick={onDownloadFile} minW="200px">Download Sample File</Button>
                                <FormFileUpload
                                    onChange={onChangeFile}
                                    component={
                                        <Button
                                            minW="200px"
                                            colorScheme='green'
                                        >
                                            Upload File
                                        </Button>
                                    }
                                />
                            </HStack>
                        </VStack>
                    }

                    <VStack spacing={5} align={"stretch"}>
                        {questions?.map((item, index) =>
                            <QuestionSuggestionBox
                                isAdded={item.isAdded}
                                onAdd={() => onAdd(index)}
                                onRemove={() => onRemove(index)}
                                onRemoveFromList={() => onRemoveFromList(index)}
                                question={item}
                                key={item.id}
                            />
                        )}
                    </VStack>
                </ModalBody>

                <ModalFooter>
                    <HStack spacing={2}>
                        <Button colorScheme="green" onClick={onSubmit} isLoading={upsertQuestionsQuery.isLoading}>Finish</Button>
                        <Button colorScheme="gray" onClick={disclosure.onClose}>Cancel</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default ImportQuestions