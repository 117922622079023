import { Skeleton, Wrap, WrapItem } from '@chakra-ui/react'
import React from 'react'

const MediaLibrarySkeleton = ({ isLoading = true }) => {
    if (!isLoading) return null
    return (
        <Wrap flexWrap={"wrap"} spacing={5}>
            {new Array(20).fill(null).map((item, index) =>
                <WrapItem flexDir={"column"} border="1px solid #e2e8f0" w="200px" h="246px" p="12px" key={index}>
                    <Skeleton height="180px" width="full" />
                    <Skeleton height="20px" width="full" mt="10px" />
                </WrapItem>
            )}
        </Wrap>
    )
}

export default MediaLibrarySkeleton