import { Box, Heading, VStack, chakra } from '@chakra-ui/react'
import React from 'react'
import { useSelector } from 'react-redux'
import FormInput from '../../../../components/Forms/FormInput'
import FormButton from '../../../../components/Forms/FormButton'
import { useForm } from 'react-hook-form'
import { Put } from "../../../../config/api"
import API_CONSTANTS from '../../../../config/constants/api'
import { yupResolver } from '@hookform/resolvers/yup';
import { PASSWORD_REGEX } from '../../../../config/constants/regexConstants'
import * as Yup from 'yup'

const Security = () => {
    const { user, token } = useSelector(state => state.user)
    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: { ...user },
        resolver: yupResolver(Yup.object().shape({
            CurrentPassword: Yup.string().required("Current password is required"),
            NewPassword: Yup.string().required("New password is required")
                .min(8, "Password must have minimum eight characters")
                .matches(
                    PASSWORD_REGEX,
                    "Password must have at least one letter and one number"
                ),
            ConfirmPassword: Yup.string().required("Confirm password is required").oneOf([Yup.ref("NewPassword")], "Passwords must match."),
        }))
    })

    function onSubmit(values) {
        return new Promise((resolve, reject) => {
            Put({
                path: API_CONSTANTS.AUTH.updatePassword,
                token,
                body: values,
                toastError: true,
                toastMessage: true
            })
                .then(() => {
                    resolve()
                })
                .catch((error) => {
                    reject()
                })
        });
    }

    return (
        <Box p="40px">

            <chakra.form onSubmit={handleSubmit(onSubmit)}>
                <Heading fontSize={"20px"}>Change Password</Heading>

                <Box mt={4} maxW="500px">
                    <VStack spacing={4}>

                        <FormInput
                            id={"CurrentPassword"}
                            label="Current Password"
                            placeholder="Enter your current password"
                            required={true}
                            type="password"
                            errors={errors}
                            control={control}
                        />

                        <FormInput
                            id={"NewPassword"}
                            label="New Password"
                            placeholder="Set a new password"
                            required={true}
                            type="password"
                            errors={errors}
                            control={control}
                        />

                        <FormInput
                            id={"ConfirmPassword"}
                            label="Confirm Password"
                            placeholder="Confirm your new password"
                            required={true}
                            type="password"
                            errors={errors}
                            control={control}
                        />

                        <FormButton
                            mr="auto"
                            isLoading={isSubmitting}
                            type="submit"
                            containerStyles={{ textAlignLast: "right" }}
                        >
                            Save Changes
                        </FormButton>

                    </VStack>
                </Box>

            </chakra.form>

        </Box>
    )
}

export default Security