import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../../../../components/Forms/FormDrawer'
import { SimpleGrid, VStack } from '@chakra-ui/react'
import FormInput from '../../../../../../components/Forms/FormInput'
import FormSwitch from '../../../../../../components/Forms/FormSwitch'
import { useCreateCohortVariant, useUpdateCohortVariant } from '../../../../../../config/query/cohortVariantsQuery'
import { STATUS } from '../../../../../../config/constants/enums'
import { useQueryClient } from '@tanstack/react-query'

const VariantForm = ({ disclosure, data, cohortId }) => {
    const queryClient = useQueryClient()
    const createCohortVariantQuery = useCreateCohortVariant()
    const updateCohortVariantQuery = useUpdateCohortVariant(data?.id)

    const {
        handleSubmit,
        control,
        reset: resetForm,
        formState: { errors },
    } = useForm({
        values: { ...data, status: !(data?.id && data?.status === STATUS.INACTIVE) },
    })


    const onSubmit = (values) => {
        let formMutate = data?.id ? updateCohortVariantQuery.mutateAsync : createCohortVariantQuery.mutateAsync
        formMutate({
            name: values.name,
            cohortId,
            price: Number(values.price) || 0,
            discount: Number(values.discount) || 0,
            status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
        }).then(() => {
            resetForm({ status: true })
            disclosure.onClose()
            queryClient.invalidateQueries(["cohortVariants"])
        }).catch((error) => console.error(error))
    }

    useEffect(() => {
        if (!disclosure?.isOpen) {
            resetForm({ status: true })
        }
    }, [disclosure?.isOpen])

    return (
        <FormDrawer
            title={data ? "Edit Cohort Variant" : "Add Cohort Variant"}
            disclosure={disclosure}
            isSubmitting={createCohortVariantQuery.isLoading || updateCohortVariantQuery.isLoading}
            onSubmit={handleSubmit(onSubmit)}
            containerProps={{ maxW: "50rem" }}
        >
            <VStack align="stretch" spacing={5}>
                <FormInput
                    label={"Name"}
                    control={control}
                    errors={errors}
                    id="name"
                    required={true}
                    placeholder="Enter variant name"
                    rules={{
                        minLength: {
                            value: 3,
                            message: "Variant name must be at least 3 characters"
                        }
                    }}
                />
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>

                    <FormInput
                        label={"Fee"}
                        type={"number"}
                        control={control}
                        errors={errors}
                        id="price"
                        required={true}
                        placeholder="Enter variant fee"
                    />

                    <FormInput
                        label={"Discount"}
                        type={"number"}
                        control={control}
                        errors={errors}
                        id="discount"
                        required={false}
                        placeholder="Enter variant discount"
                    />
                </SimpleGrid>

                <FormSwitch
                    label={"Status"}
                    control={control}
                    errors={errors}
                    id="status"
                />

            </VStack>

        </FormDrawer>
    )
}

export default VariantForm