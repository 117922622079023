import React from 'react'
import { Flex, HStack, Image, Box, Heading, Text, useColorMode, IconButton, Icon } from '@chakra-ui/react'
import { colorKeys, getColor } from '../../config/constants/appColors'
import APP_ICONS from '../../config/constants/icons'
import AvatarNamesStack from '../BasicUI/DataBoxes/AvatarNamesStack'
import IMAGES from '../../config/constants/images'
import DeletePopover from '../BasicUI/DeletePopover'
import { formatDateTimeFromNow } from '../../config/helpers/dateHelper'

const QuizCard = ({ item, onDelete }) => {
  const { colorMode } = useColorMode()

  return (
    <Flex p="1" w="full" justify={"space-between"} _hover={{ bg: getColor(colorKeys.tableRowHoverBackground, colorMode) }} role='group'>
      <HStack spacing={3}>
        <Flex align="center" h="70px" w="80px" bg={getColor(colorKeys.lightestGray, colorMode)}>
          <Image h="40px" w="80px" src={IMAGES.QUIZ_ICON} objectFit={"contain"} />
        </Flex>
        <Box>
          <Heading mb="2px" fontSize={"15px"}>{item?.name}</Heading>
          <AvatarNamesStack data={item?.teachers} />
          <Text mt={"5px"} fontSize="12px">Updated {item.updatedBy?.dateTime ? formatDateTimeFromNow(item.updatedBy?.dateTime) : formatDateTimeFromNow(item.createdBy?.dateTime)}</Text>
        </Box>
      </HStack>

      <HStack mr="5" display={"none"} _groupHover={{ display: "flex" }}>
        <IconButton size="sm" icon={<Icon as={APP_ICONS.EDIT} />} />
        <DeletePopover onConfirm={() => onDelete(item.id)} type="quiz">
          <IconButton size="sm" icon={<Icon as={APP_ICONS.BIN} />} />
        </DeletePopover>
      </HStack>
    </Flex>
  )
}

export default QuizCard