import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { appendQueryParams } from "../helpers/queryHelper";;

export const useMeetings = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["meetings", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.MEETINGS.base}?${appendQueryParams({params})}`,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useMeetingsFacet = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["meetingsFacet"],
        queryFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.MEETINGS.getFacet,
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useCreateMeeting = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.MEETINGS.dataKeys)
            const { data } = await Post({
                path: API_CONSTANTS.MEETINGS.base,
                token,
                body
            })
            return data
        },
    })
}

export const useUpdateMeeting = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            body = prepareData(body, API_CONSTANTS.MEETINGS.dataKeys)
            const { data } = await Put({
                path: `${API_CONSTANTS.MEETINGS.base}/${id}`,
                token,
                body,
                toastError: true,
                toastMessage:true
            })
            return data
        },
    })
}

export const useDeleteMeeting = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.MEETINGS.base}/${id}`,
                token,
                toastError: true,
                toastMessage:true
            })
            return data
        },
    })
}