import React, { useState } from 'react'
import CustomTable from '../../../../components/BasicUI/CustomTable'
import { useCourses, useDeleteCourse, useCoursesFacet } from '../../../../config/query/courseQuery'
import { formatDateTime, formatDateTimeFromNow } from '../../../../config/helpers/dateHelper'
import APP_ICONS from '../../../../config/constants/icons'
import {
  Badge,
  chakra,
  HStack,
  Avatar,
  Flex
} from '@chakra-ui/react'
import { getFilters } from '../../../../config/helpers/filterHelper'
import TableInfoPopover from '../../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../../components/BasicUI/LabelValuePair'
import StatusBadge from '../../../../components/BasicUI/Badges/StatusBadge'
import Chronology from '../../../../components/BasicUI/DataBoxes/Chronology'

const CoursesList = ({ onEditModal }) => {

  const [query, setQuery] = useState({
    pageNo: 1,
    pageSize: 20,
  })

  const coursesQuery = useCourses(query)
  const courseFacetQuery = useCoursesFacet()
  const deleteCourseQuery = useDeleteCourse()

  const handleDelete = (id) => {
    deleteCourseQuery.mutateAsync(id)
      .then((response) => {
        coursesQuery.refetch()
        console.log(`Course deleted successfully: ${response}`)
      })
      .catch((error) => {
        console.log(`Error while deleting course: ${error}`)
      })
  }


  return (
    <CustomTable
      containerProps={{ mt: 5 }}
      searchPlaceholder="Search courses"
      filters={getFilters(courseFacetQuery?.data)}
      head={[
        {
          title: "Name",
          extractor: "name",
          align: "left",
          component: (item) => (
            <TableInfoPopover
              data={item}
              onEdit={(item) => onEditModal(item)}
              onDelete={(id) => handleDelete(id)}
              triggerOnClick={() => onEditModal(item)}
            >
              <LabelValuePair label="Description" value={item.description} />
              <LabelValuePair label="Qualification" value={item.qualificationName} />
              <LabelValuePair
                label="Status"
                value={true}
                valueComponent={
                  <StatusBadge value={item.status} />
                }
              />
              <LabelValuePair label="Created" value={formatDateTime(item.createdBy?.dateTime)} />
              <LabelValuePair label="Last Updated" value={formatDateTime(item.updateBy?.dateTime)} />
            </TableInfoPopover>
          )
        },
        { title: "Qualification", extractor: "qualificationName" },
        { title: "Status", extractor: "status", component: (item) => <StatusBadge value={item.status} /> },
        {
          title: "Last Modified",
          extractor: "lastModified",
          component: (item) => <Chronology data={item} />
        },
        { title: "Actions", extractor: "actions" }
      ]}
      data={coursesQuery?.data?.results?.map((item) => {
        return {
          ...item,
          lastModified: item.updateBy ? formatDateTimeFromNow(item.updatedAt?.dateTime) : formatDateTimeFromNow(item.createdBy?.dateTime),
          actions: [
            {
              title: "Edit",
              action: () => onEditModal(item),
              icon: APP_ICONS.EDIT,
              isEdit: true
            },
            {
              title: "Delete",
              action: handleDelete,
              icon: APP_ICONS.BIN,
              isDelete: true
            }
          ]
        }
      })}
      loading={coursesQuery?.isLoading}
      totalResults={coursesQuery?.data?.totalResults}
      totalPages={coursesQuery?.data?.totalPages}
      pageNo={query.pageNo}
      pageSize={query.pageSize}
      onQueryChange={(updatedQuery) => setQuery({ ...query, ...updatedQuery })}
      query={query}
      onRefresh={() => coursesQuery.refetch()}
      isRefreshing={coursesQuery?.isFetching}
    />
  )
}

export default CoursesList