import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, HStack, Icon, IconButton, Text, Tooltip, VStack, useColorMode, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import DeletePopover from '../BasicUI/DeletePopover'
import { colorKeys, getColor } from '../../config/constants/appColors'
import APP_ICONS from '../../config/constants/icons'
import { useContents } from '../../config/query/contentQuery'
import { useDeleteChapter } from '../../config/query/chapterQuery'
import ContentCard from './ContentCard'
import ContentSkeleton from '../Skeletons/ContentSkeleton'
import MenuDropdown from '../Forms/MenuDropdown'
import { COHORT_CONTENT_TYPES } from '../../config/constants/enums'
import LectureForm from '../../views/admin/Lectures/LectureForm'
import NotesForm from '../../views/admin/Syllabus/Notes/NotesForm'
import QuizForm from '../../views/admin/Quizzes/QuizForm'

const ChapterCard = ({ chapter, onAddContent, onDeleteContent,onEditChapter }) => {
    const { colorMode } = useColorMode()
    const disclosure = useDisclosure()
    const lectureDisclosure = useDisclosure()
    const notesDisclosure = useDisclosure()
    const quizDisclosure = useDisclosure()

    const [selectedContent, setSelectedContent] = useState(null)

    const deleteChapterQuery = useDeleteChapter()
    const contentQuery = useContents({
        chapters: chapter?.id,
        disabled: !disclosure.isOpen
    })

    const onDeleteChapter = (e, item) => {
        e.stopPropagation()
        deleteChapterQuery
            .mutateAsync(item?.id)
            .catch((error) => console.warn(error))
    }

    const handleAddContent = (type) => {
        switch (type) {
            case COHORT_CONTENT_TYPES.LECTURES:
                lectureDisclosure.onOpen()
                break;
            case COHORT_CONTENT_TYPES.NOTES:
                notesDisclosure.onOpen()
                break;
            case COHORT_CONTENT_TYPES.QUIZ:
                quizDisclosure.onOpen()
                break;
            default:
                break;
        }
    }

    return (
        <Box w="full" role='group'>
            <Flex
                onClick={disclosure.onToggle}
                cursor={"pointer"}
                userSelect={"none"}
                px="5"
                borderWidth={"1px"}
                mt={1}
                bg={"#eaeaea5e"}
                h="50px"
                w="full"
                align="center"
                justify={"space-between"}
            >
                <Text fontWeight="bold" color={getColor(colorKeys.secondaryText, colorMode)}>
                    {chapter.name}
                </Text>
                <HStack spacing={2} _groupHover={{ display: "flex" }} display="none">
                    <Tooltip label="Edit Chapter">
                        <IconButton onClick={(e) => onEditChapter(e, chapter)} size="sm" icon={<Icon as={APP_ICONS.EDIT} boxSize={"5"} />} />
                    </Tooltip>
                    <DeletePopover onConfirm={(e) => onDeleteChapter(e, chapter)} type="chapter">
                        <IconButton onClick={(e) => e.stopPropagation()} size="sm" icon={<Icon as={APP_ICONS.BIN} boxSize={"4"} />} />
                    </DeletePopover>
                    <MenuDropdown
                        label={"Add Content"}
                        onChange={handleAddContent}
                        options={[
                            {
                                label: "Lectures",
                                value: COHORT_CONTENT_TYPES.LECTURES
                            },
                            {
                                label: "Notes",
                                value: COHORT_CONTENT_TYPES.NOTES
                            },
                            {
                                label: "Quiz",
                                value: COHORT_CONTENT_TYPES.QUIZ
                            },
                        ]}
                        menuButtonProps={{ padding: 0 }}
                        customTrigger={<Tooltip label="Add Content">
                            <IconButton onClick={(e) => onAddContent(e, chapter)} size="sm" icon={<Icon as={APP_ICONS.ADD} boxSize={"6"} />} />
                        </Tooltip>}
                    />


                    <IconButton size="sm" icon={<Icon as={disclosure.isOpen ? APP_ICONS.UpChevron : APP_ICONS.DownChevron} />} />
                </HStack>
            </Flex>
            {disclosure.isOpen
                ? <>
                    <ContentSkeleton isLoading={contentQuery.isLoading} />
                    <Box p="5px" borderWidth={"1px"} borderTopWidth={0}>
                        <VStack align={"stretch"} spacing={2}>
                            {contentQuery.data?.results?.map((item) => (
                                <ContentCard content={item} onDelete={onDeleteContent} />
                            ))}
                        </VStack>
                    </Box>
                </>
                : null}

            <LectureForm
                data={selectedContent}
                cohortId={chapter?.cohortId}
                chapterId={chapter?.id}
                disclosure={lectureDisclosure}
            />
            <NotesForm
                data={selectedContent}
                cohortId={chapter?.cohortId}
                chapterId={chapter?.id}
                disclosure={notesDisclosure}
            />
            <QuizForm
                data={selectedContent}
                cohortId={chapter?.cohortId}
                chapterId={chapter?.id}
                disclosure={quizDisclosure}
            />
        </Box>
    )
}

export default ChapterCard