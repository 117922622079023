import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { registerLicense } from '@syncfusion/ej2-base';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { MultiSelectTheme } from 'chakra-multiselect'
import AppRouter from './config/routes';
import { getColor, colorKeys } from './config/constants/appColors';
import { persistor, store } from './config/redux/store';
import reportWebVitals from './reportWebVitals';
import "@fontsource/roboto";
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

registerLicense('ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRiW39dcnVRRGJdUkA=');


const root = ReactDOM.createRoot(document.getElementById('root'));

const colors = {
  primaryText: getColor(colorKeys.dark, 'light'),
  secondaryText: getColor(colorKeys.secondaryText, 'light'),
  headingText: getColor(colorKeys.dark, 'light'),
  primaryFill: getColor(colorKeys.primary, 'light'),
  secondaryFill: getColor(colorKeys.secondary, 'light'),
  danger: getColor(colorKeys.danger, 'light'),
  success: getColor(colorKeys.success, 'light'),
  warning: getColor(colorKeys.warning, 'light'),
  info: getColor(colorKeys.primary, 'light'),
};

const fonts = {
  heading: `'Roboto', sans-serif`,
  body: `'Roboto', sans-serif`,
}

const config = {
  initialColorMode: "light",
  useSystemColorMode: localStorage.getItem('systemColorMode') || false,
};

const theme = extendTheme({
  colors,
  fonts,
  config,
  components: {
    MultiSelect: MultiSelectTheme
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <AppRouter />
            <ReactQueryDevtools initialIsOpen={false} />
            <ToastContainer />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
