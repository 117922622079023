import { Modal, ModalOverlay, ModalHeader, useColorMode, Box, ModalBody, ModalContent, HStack, Heading, Flex, Button, Icon, IconButton, Progress, Wrap, WrapItem, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Link, Menu, MenuList, MenuItem, MenuButton, Text, useDisclosure } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { useCreateMultipleMedia, useMedia } from '../../config/query/mediaQuery'
import MediaItem from './MediaItem'
import { ALLOWED_FILE_TYPES, getFileGroup } from '../../config/constants/fileHelper'
import APP_ICONS from '../../config/constants/icons'
import FormFileUpload from '../Forms/FormFileUpload'
import { useQueryClient } from '@tanstack/react-query'
import VideoForm from "./VideoForm"
import FolderForm from "./FolderForm"
import { getColor, colorKeys } from "../../config/constants/appColors"
import MediaLibrarySkeleton from '../Skeletons/MediaLibrarySkeleton'

const MediaLibrary = ({ isOpen, onClose, onChange, fileType, value }) => {
    const { colorMode } = useColorMode()
    const queryClient = useQueryClient()
    const videoFormDisclosure = useDisclosure()
    const folderFormDisclosure = useDisclosure()
    const [selectedMedia, setSelectedMedia] = useState(null)
    const [progress, setProgress] = useState(0)
    const createMediaQuery = useCreateMultipleMedia(setProgress)

    const [query, setQuery] = useState({
        isTreeListing: true,
    })

    const mediaQuery = useMedia(query)

    //handing file upload
    const onChangeImage = (files) => {
        const formData = new FormData()
        Array.from(files).forEach((item, index) => {
            formData.append(`files[${index}].file`, item)
            formData.append(`files[${index}].name`, Date.now() + index)
            if (query.id) formData.append(`files[${index}].parentId`, query.id)
        })

        createMediaQuery.mutateAsync(formData).then((res) => {
            queryClient.invalidateQueries("media")
        }).catch((err) => {
            console.log(err, "error while uploading media")
        })
    }

    //handling back button click
    const handleBack = () => {
        setQuery({
            ...query,
            id: mediaQuery.data?.path?.at(-2)?.id || null,
        })
    }

    //changing selected media and parent id on value change
    useEffect(() => {
        if (!value)
            setQuery({
                ...query,
                id: null,
                ...(fileType ? { extensions: [...ALLOWED_FILE_TYPES[fileType], "folder"].join(',') } : {})
            })
        else
            setQuery({
                ...query,
                ...(value ? { id: value.parentId } : {}),
                ...(fileType ? { extensions: [...ALLOWED_FILE_TYPES[fileType], "folder"].join(',') } : {})
            })
        setSelectedMedia(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value?.id, value])

    return (
        <Modal size={"full"} isOpen={isOpen} onClose={onClose} isCentered={true}>
            <ModalOverlay />
            <ModalContent maxH={{ base: "unset", md: "calc(100vh - 50px)" }} overflow={"auto"}>
                <ModalHeader>

                    <Flex justifyContent={"space-between"} alignItems={"center"} w={"full"}>
                        <Box>
                            <Heading as={Link} _hover={{ textDecor: "underline" }} size={"md"} onClick={() => setQuery({
                                ...query,
                                id: null,
                            })}>Media Library</Heading>
                            <Breadcrumb separator={"/"} fontSize="14px" fontWeight="normal" >
                                {mediaQuery.data?.path?.map((item, index) => {
                                    if (item.name === "Root") return null
                                    return (
                                        <BreadcrumbItem key={index}>
                                            <BreadcrumbLink fontSize="14px" fontWeight="normal" onClick={() =>
                                                setQuery({
                                                    ...query,
                                                    id: item.id,
                                                })}
                                            >
                                                {item.name}
                                            </BreadcrumbLink>
                                        </BreadcrumbItem>
                                    )
                                })}
                            </Breadcrumb>
                        </Box>
                        <HStack spacing={2}>
                            <Button onClick={handleBack}
                                leftIcon={<Icon as={APP_ICONS.LeftChevron} />}
                                isDisabled={mediaQuery.data?.path?.length <= 1}
                            >
                                Back
                            </Button>

                            <Button
                                onClick={() => mediaQuery.refetch()}
                                isLoading={mediaQuery.isFetching}
                            >
                                Refresh
                            </Button>

                            <Button
                                leftIcon={<Icon as={APP_ICONS.ADD_FOLDER} boxSize="5" />}
                                onClick={() => folderFormDisclosure.onOpen()}
                            >
                                Add Folder
                            </Button>

                            <Menu>
                                <MenuButton as={Button} colorScheme='green' leftIcon={<Icon as={APP_ICONS.UPLOAD} />}>
                                    Upload
                                </MenuButton>
                                <MenuList>
                                    <MenuItem h="32px">
                                        <FormFileUpload
                                            multiple={true}
                                            id={"profilePicture"}
                                            onChange={onChangeImage}
                                            component={<Text fontWeight="normal" w="full" textAlign="left">Images</Text>}
                                            componentProps={{ variant: "ghost", w: "full", textAlign: "left" }}
                                            fileType={"ANY"}
                                        />
                                    </MenuItem>

                                    <MenuItem h="32px" onClick={() => videoFormDisclosure.onOpen()}>
                                        <Text fontSize="16px" color={getColor(colorKeys.dark, colorMode)} fontWeight="normal" w="full" textAlign="left">Video</Text>
                                    </MenuItem>
                                </MenuList>
                            </Menu>


                            <IconButton icon={<Icon boxSize="3" as={APP_ICONS.CLOSE} />} onClick={onClose} />
                        </HStack>
                    </Flex>

                    {createMediaQuery.isLoading && (
                        <Progress mt={5} hasStripe value={progress} />
                    )}
                </ModalHeader>

                <ModalBody>


                    <Wrap flexWrap={"wrap"} spacing={5}>
                        <MediaLibrarySkeleton isLoading={mediaQuery.isLoading} />
                        {mediaQuery.isError && mediaQuery.data?.items?.results.length === 0 && <div>Error fetching data</div>}
                        {mediaQuery.data?.items?.results?.map((media, index) => {
                            const fileGroup = getFileGroup(media.extension)
                            return (
                                <WrapItem p="4px" key={media.id}>
                                    <MediaItem
                                        key={index}
                                        media={media}
                                        isSelected={selectedMedia?.id === media.id}
                                        onClick={() => setSelectedMedia(media)}
                                        onDoubleClick={() => {
                                            if (fileGroup === "folder") {
                                                setQuery({
                                                    ...query,
                                                    id: media.id,
                                                })
                                                return;
                                            }
                                            onChange(media)
                                            onClose()
                                        }}
                                    />
                                </WrapItem>
                            )
                        })}
                    </Wrap>


                </ModalBody>

                <VideoForm disclosure={videoFormDisclosure} parentId={query?.id} />
                <FolderForm disclosure={folderFormDisclosure} parentId={query?.id} />

            </ModalContent>
        </Modal>
    )
}

export default MediaLibrary