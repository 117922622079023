import { Tab, TabList, TabPanel, TabPanels, Tabs, Flex, Button } from '@chakra-ui/react'
import React from 'react'

const CustomTabs = ({ head, body, tabsProps, tabListProps, tabProps, tabPanelsProps, tabPanelProps, tabEndAction }) => {
    return (
        <Tabs {...tabsProps}>
            <TabList {...tabListProps}>
                {head?.map((item, index) =>
                    <Tab key={index} {...tabProps}>{item.label || item}</Tab>
                )}
                {tabEndAction && (
                    <Flex justify={"end"} w="full" onClick={tabEndAction.action} {...tabEndAction.props}>
                        <Button isLoading={tabEndAction.isLoading} size="sm">{tabEndAction.label}</Button>
                    </Flex>
                )}
            </TabList>
            <TabPanels {...tabPanelsProps}>
                {body.map((item, index) =>
                    <TabPanel key={index} {...tabPanelProps}>
                        {item}
                    </TabPanel>
                )}
            </TabPanels>
        </Tabs>
    )
}

export default CustomTabs