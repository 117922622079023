import APP_ICONS from "./icons"

const emailsSubsiderOptions = [
    {
        icon: APP_ICONS.EXAMS_LIST,
        title: "Templates",
        link: "/admin/emails"
    },
    {
        icon: APP_ICONS.CATEGORIES_TAG,
        title: "Logs",
        link: "/admin/emails/logs"
    }
]

export default emailsSubsiderOptions