import React from 'react'
import CustomTable from '../../../../components/BasicUI/CustomTable'

const QuizArchiveList = () => {
    return (
        <CustomTable
            containerProps={{ mt: 5 }}
            head={["Title", "Cohort", "Chapter", "Category", "Duration", "Total Questions", "Actions"]}
            data={[]}
        />
    )
}

export default QuizArchiveList