import { Box, chakra, VStack, Heading, IconButton, Icon, useDisclosure, Flex, Tooltip } from '@chakra-ui/react'
import React, { useDebugValue } from 'react'
import { useForm } from 'react-hook-form'
import FormButton from '../../../../components/Forms/FormButton'
import FormInput from '../../../../components/Forms/FormInput'
import { EMAIL_REGEX } from '../../../../config/constants/regexConstants'
import FormSwitch from '../../../../components/Forms/FormSwitch'
import { useEmailSettings, useUpdateEmailSettings } from '../../../../config/query/settingQuery'
import FormFieldsSkeleton from '../../../../components/Skeletons/FormFieldsSkeleton'
import APP_ICONS from '../../../../config/constants/icons'


const EmailSettings = () => {
    const editDisclosure = useDisclosure()

    const emailSettingsQuery = useEmailSettings()
    const updateEmailSettingsQuery = useUpdateEmailSettings()

    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        values: emailSettingsQuery.data
    })

    function onSubmit(values) {
        updateEmailSettingsQuery.mutate(values)
    }
    return (
        <Box>
            <chakra.form onSubmit={handleSubmit(onSubmit)}>

                <Flex align={"center"} justify={"space-between"} maxW="500px">
                    <Heading fontSize={"20px"}>Email Settings</Heading>
                    <Tooltip label={editDisclosure.isOpen ? "Close" : "Edit"} aria-label="A tooltip">
                        <IconButton
                            icon={<Icon
                                boxSize={editDisclosure.isOpen ? "0.8em" : "1.25em"}
                                as={editDisclosure.isOpen ? APP_ICONS.CLOSE : APP_ICONS.EDIT}
                            />}
                            onClick={() => editDisclosure.onToggle()}
                        />
                    </Tooltip>
                </Flex>

                <Box mt={4} maxW="500px">

                    {emailSettingsQuery.isLoading
                        ? <FormFieldsSkeleton columns={1} count={6} />
                        : <VStack spacing={4} align={"stretch"}>

                            <FormInput
                                id={"name"}
                                label="Name"
                                placeholder="Enter your name"
                                required={true}
                                errors={errors}
                                control={control}
                                inputProps={{
                                    isDisabled: updateEmailSettingsQuery.isLoading || !editDisclosure.isOpen
                                }}
                            />

                            <FormInput
                                id={"email"}
                                label="Email"
                                placeholder="someone@example.com"
                                required={true}
                                errors={errors}
                                control={control}
                                rules={{
                                    pattern: {
                                        value: EMAIL_REGEX,
                                        message: "Invalid email address"
                                    }
                                }}
                                inputProps={{
                                    isDisabled: updateEmailSettingsQuery.isLoading || !editDisclosure.isOpen
                                }}
                            />

                            <FormInput
                                id={"password"}
                                label="Password"
                                placeholder="Enter email password"
                                required={true}
                                errors={errors}
                                control={control}
                                secure={true}
                                inputProps={{
                                    isDisabled: updateEmailSettingsQuery.isLoading || !editDisclosure.isOpen
                                }}
                            />

                            <FormInput
                                id={"host"}
                                label="SMTP Host"
                                placeholder="Enter smtp host"
                                required={true}
                                errors={errors}
                                control={control}
                                inputProps={{
                                    isDisabled: updateEmailSettingsQuery.isLoading || !editDisclosure.isOpen
                                }}
                            />

                            <FormInput
                                id={"port"}
                                label="SMTP Port"
                                placeholder="Enter smtp port"
                                required={true}
                                errors={errors}
                                control={control}
                                inputProps={{
                                    isDisabled: updateEmailSettingsQuery.isLoading || !editDisclosure.isOpen
                                }}
                            />

                            <FormSwitch
                                label={"SSL Enabled?"}
                                control={control}
                                errors={errors}
                                id="enableSsl"
                                inputProps={{
                                    isDisabled: updateEmailSettingsQuery.isLoading || !editDisclosure.isOpen
                                }}
                            />

                            {editDisclosure.isOpen && (
                                <FormButton
                                    mr="auto"
                                    isLoading={updateEmailSettingsQuery.isLoading}
                                    type="submit"
                                    containerStyles={{ textAlignLast: "right" }}
                                >
                                    Save Changes
                                </FormButton>
                            )}

                        </VStack>
                    }



                </Box>

            </chakra.form>
        </Box>
    )
}

export default EmailSettings