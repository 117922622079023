import { Box, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import CohortList from "./CohortList"
import CohortForm from "./CohortForm"
import APP_ICONS from '../../../../config/constants/icons'

const Cohorts = () => {
    const formDisclosure = useDisclosure()
    const [selectedCohort, setSelectedCohort] = React.useState(null)

    return (
        <Box>
            <Flex justify="space-between" align={"center"}>
                <Heading as="h2" fontSize={26} fontWeight="bold">Cohorts</Heading>
                <PrimaryButton
                    onClick={() => {
                        setSelectedCohort(null)
                        formDisclosure.onOpen()
                    }}
                    leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
                >
                    Create New Cohort
                </PrimaryButton>
            </Flex>
            <CohortList
                onEditModal={(data) => {
                    setSelectedCohort(data)
                    formDisclosure.onOpen()
                }}
            />
            <CohortForm disclosure={formDisclosure} data={selectedCohort} />
        </Box>
    )
}

export default Cohorts