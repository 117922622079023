import { Box, Flex, HStack, Image, useColorMode, IconButton } from '@chakra-ui/react'
import React from 'react'
import { getColor, colorKeys } from '../../../config/constants/appColors'
import LogoImage from "../../../assets/images/logo-evolv-lms.png"
import APP_ICONS from "../../../config/constants/icons"
import { Link } from 'react-router-dom'
import IMAGES from '../../../config/constants/images'

const Header = () => {
    const { colorMode, toggleColorMode } = useColorMode()
    const menu = [
        {
            title: "Features",
            link: "https://lms.evolv-systems.com"
        },
        {
            title: "Solutions",
            link: "https://lms.evolv-systems.com",
        },
        {
            title: "Pricing",
            link: "https://lms.evolv-systems.com"
        },
        {
            title: "Client Stories",
            link: "https://lms.evolv-systems.com"
        },
        {
            title: "About Us",
            link: "https://lms.evolv-systems.com",
        },
        {
            title: "Demo",
            link: "https://lms.evolv-systems.com",
        },
        {
            title: "Help",
            link: "https://lms.evolv-systems.com"
        }
    ]
    return (
        <Flex
            as="header"
            align="center"
            justify="space-between"
            w="full"
            px="4"
            minH="60px"
            bg={getColor(colorKeys.layoutHeaderBackground, colorMode)}
            h="14"
            pos="sticky"
            top="0"
            zIndex={99}
        >
            <Flex>
                <Box pr="15px">
                    <Image maxH="105px" objectFit={"contain"} src={IMAGES.LOGO} w="130px" h="auto" />
                </Box>

                <HStack display={{ base: "none", md: "flex" }}>
                    {menu.map((item, index) =>
                        <Link to={item.link} key={index}>
                            <Box m={"12px 0"} p="0px 15px" color="white" fontSize={"15px"} fontWeight="bold">
                                {item.title}
                            </Box>
                        </Link>
                    )}
                </HStack>
            </Flex>

            <HStack>
                <Box display={{ base: "none", md: "flex" }} to={"/start-free-trial"} as={Link} rounded={"full"} m={"12px 0"} p="0px 15px" color="white" bg={getColor(colorKeys.primaryButtonFill, colorMode)} fontSize={"15px"} fontWeight="bold" lineHeight={"36px"}>
                    Start Free Trial
                </Box>

                <IconButton size="sm" mr={4} rounded="full" boxSize="6" bg="transparent !important" color="white" onClick={toggleColorMode} as={colorMode === 'light' ? APP_ICONS.DarkMode : APP_ICONS.LighMode}>
                    Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
                </IconButton>

                <Box m={"12px 0"} p="0px 15px" color="white" fontSize={"15px"} fontWeight="bold">
                    Login
                </Box>
            </HStack>

        </Flex>
    )
}

export default Header