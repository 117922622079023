import React, { useState } from 'react'
import CustomTable from '../../../components/BasicUI/CustomTable'
import { useChapters, useChaptersFacet, useDeleteChapter } from '../../../config/query/chapterQuery'
import { formatDateTime, formatDateTimeFromNow } from '../../../config/helpers/dateHelper'
import APP_ICONS from '../../../config/constants/icons'
import { Avatar, Badge, Flex, HStack, chakra } from '@chakra-ui/react'
import StatusBadge from '../../../components/BasicUI/Badges/StatusBadge'
import TableInfoPopover from '../../../components/BasicUI/Popovers/TableInfoPopover'
import LabelValuePair from '../../../components/BasicUI/LabelValuePair'
import { getFilters } from '../../../config/helpers/filterHelper'
import Chronology from '../../../components/BasicUI/DataBoxes/Chronology'

const ChapterList = ({ onEditModal }) => {

    const [query, setQuery] = useState({
        pageNo: 1,
        pageSize: 20,
    })

    const chapterQuery = useChapters(query)
    const chapterFacetQuery = useChaptersFacet()
    const deleteChapterQuery = useDeleteChapter()

    const handleDelete = (id) => {
        deleteChapterQuery.mutateAsync(id)
            .then((response) => {
                chapterQuery.refetch()
                console.log(`Chapter deleted successfully: ${response}`)
            })
            .catch((error) => {
                console.log(`Error while deleting chapter: ${error}`)
            })
    }

    return (
        <CustomTable
            containerProps={{ mt: 5 }}
            searchPlaceholder="Search chapters"
            filters={getFilters(chapterFacetQuery?.data)}
            head={[
                {
                    title: "Name",
                    extractor: "name",
                    align: "left",
                    component: (item) => (
                        <TableInfoPopover
                            data={item}
                            onEdit={(item) => onEditModal(item)}
                            onDelete={(id) => handleDelete(id)}
                            triggerOnClick={() => onEditModal(item)}
                        >
                            <LabelValuePair label="Cohort" value={item.cohortName} />
                            <LabelValuePair label="Course" value={item.courseName} />
                            <LabelValuePair label="Description" value={item.description} />
                            <LabelValuePair
                                label="Status"
                                value={true}
                                valueComponent={
                                    <StatusBadge value={item.status} />
                                }
                            />
                            <LabelValuePair label="Created" value={formatDateTime(item.createdBy?.dateTime)} />
                            <LabelValuePair label="Last Updated" value={formatDateTime(item.updateBy?.dateTime)} />
                        </TableInfoPopover>
                    )
                },
                { title: "Cohort", extractor: "cohortName" },
                { title: "Is Public", extractor: "isPublicValue" },
                { title: "Status", extractor: "status", component: (item) => <StatusBadge value={item.status} /> },
                {
                    title: "Last Modified",
                    extractor: "lastModified",
                    component: (item) => (<Chronology data={item} />)
                },
                { title: "Actions", extractor: "actions" }
            ]}
            data={chapterQuery?.data?.results?.map((item) => {
                return {
                    ...item,
                    isPublicValue: item.isPublic ? "Yes" : "No",
                    lastModified: item.updatedBy?.dateTime ? formatDateTimeFromNow(item.updatedBy?.dateTime) : formatDateTimeFromNow(item.createdBy?.dateTime),
                    actions: [
                        {
                            title: "Edit",
                            action: () => onEditModal(item),
                            icon: APP_ICONS.EDIT,
                            isEdit: true
                        },
                        {
                            title: "Delete",
                            action: handleDelete,
                            icon: APP_ICONS.BIN,
                            isDelete: true
                        }
                    ]
                }
            })}
            loading={chapterQuery?.isLoading}
            totalResults={chapterQuery?.data?.totalResults}
            totalPages={chapterQuery?.data?.totalPages}
            pageNo={query?.pageNo}
            pageSize={query?.pageSize}
            onQueryChange={(updatedQuery) => setQuery({ ...query, ...updatedQuery })}
            query={query}
            onRefresh={() => chapterQuery.refetch()}
            isRefreshing={chapterQuery?.isFetching}
        />
    )
}

export default ChapterList