import React from 'react'
import { IconButton, Icon } from '@chakra-ui/react'

const FormEmbededButton = ({ onClick, error, icon }) => {
    return (
        <IconButton
            onClick={onClick}
            roundedLeft={"none"}
            icon={<Icon as={icon} />}
            height="42px"
            top="-1px"
            style={{
                ...(error && { borderTop: "2px solid red", borderBottom: "2px solid red", borderRight: "2px solid red" }),
            }}
        />
    )
}

export default FormEmbededButton