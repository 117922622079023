import { Box, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import QualificationList from "./QualificationList"
import QualificationForm from "./QualificationForm"
import APP_ICONS from '../../../../config/constants/icons'

const Qualifications = () => {
  const formDisclosure = useDisclosure()
  const [selectedQualification, setSelectedQualification] = React.useState(null)

  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">Qualifications</Heading>
        <PrimaryButton
          onClick={() => {
            setSelectedQualification(null)
            formDisclosure.onOpen()
          }}
          leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
        >
          Create New Qualification
        </PrimaryButton>
      </Flex>
      <QualificationList
        onEditModal={(data) => {
          setSelectedQualification(data)
          formDisclosure.onOpen()
        }}
      />
      <QualificationForm disclosure={formDisclosure} data={selectedQualification} />
    </Box>
  )
}

export default Qualifications