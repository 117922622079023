import { Flex, Image } from "@chakra-ui/react"
import IMAGES from "../constants/images"

export const getPlatformIcon = (device) => {
    let os = device.platformName?.toLowerCase()
    let image = null
    if (os?.includes("android")) {
        image = IMAGES.ANDROID
    }
    else if (os?.includes("ios")) {
        image = IMAGES.IOS
    }
    else if (os?.includes("windows")) {
        image = IMAGES.WINDOWS
    }
    else if (os?.includes("mac")) {
        image = IMAGES.MAC
    }
    else if (os?.includes("linux")) {
        image = IMAGES.LINUX
    }
    return image
}

export const getPlatform = (device) => {
    const image = getPlatformIcon(device)
    if (image) {
        return <Flex align={"center"}><Image w="32px" h="32px" mr="3" objectFit={"contain"} src={image} /> {device.platformName} {device.platformVersion} {device.platformProcessor} {device?.deviceType}</Flex>;
    }
    else {
        return <Flex align={"center"}>{device.platformName}</Flex>;
    }
}

export const getBrowserIcon = (device) => {
    let browser = device.browserName?.toLowerCase()
    let image = null
    if (browser?.includes("opera")) {
        image = IMAGES.OPERA
    }
    else if (browser?.includes("chrome")) {
        image = IMAGES.CHROME
    }
    else if (browser?.includes("firefox")) {
        image = IMAGES.FIREFOX
    }
    else if (browser?.includes("safari")) {
        image = IMAGES.SAFARI
    }
    else if (browser?.includes("edge")) {
        image = IMAGES.EDGE
    }
    else if (browser?.includes("ie")) {
        image = IMAGES.IE
    }
    return image
}

export const getBrowser = (device) => {
    const image = getBrowserIcon(device)
    if (image) {
        return <Flex align={"center"}><Image w="32px" h="32px" mr="3" objectFit={"contain"} src={image} /> {device.browserName} {device.browserVersion}</Flex>;
    }
    else {
        return <Flex align={"center"}>{device.browserName}</Flex>;
    }
}