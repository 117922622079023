import React from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../../components/Forms/FormDrawer'
import { SimpleGrid } from '@chakra-ui/react'
import FormInput from '../../../../components/Forms/FormInput'
import FormSwitch from '../../../../components/Forms/FormSwitch'
import { useCreateQualification, useUpdateQualification } from '../../../../config/query/qualificationQuery'
import { STATUS } from '../../../../config/constants/enums'
import { useQueryClient } from '@tanstack/react-query'

const QualificationForm = ({ disclosure, data }) => {
  const queryClient = useQueryClient()
  const createQualificationQuery = useCreateQualification()
  const updateQualificationQuery = useUpdateQualification(data?.id)

  const {
    handleSubmit,
    control,
    reset: resetForm,
    getValues: getFormValues,
    formState: { errors },
  } = useForm({
    values: { ...data, status: (data?.id && data?.status === STATUS.INACTIVE) ? false : true },
  })


  const onSubmit = (values) => {
    let formMutate = data?.id ? updateQualificationQuery.mutateAsync : createQualificationQuery.mutateAsync
    formMutate({
      name: values.name,
      status: values.status ? STATUS.ACTIVE : STATUS.INACTIVE,
    })
      .then(() => {
        resetForm(Object.keys(getFormValues()).map((item) => ({ [item]: "" })))
        disclosure.onClose()
        queryClient.invalidateQueries(["qualifications"])
      })
  }

  return (
    <FormDrawer
      title={data ? "Edit Qualification" : "Add Qualification"}
      disclosure={disclosure}
      isSubmitting={createQualificationQuery.isLoading || updateQualificationQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
      containerProps={{ maxW: "50rem" }}
    >
      <SimpleGrid columns={{ base: 1, md: 1 }} spacing={5}>
        <FormInput
          label={"Name"}
          control={control}
          errors={errors}
          id="name"
          required={true}
          placeholder="Enter qualification name"
          rules={{
            minLength: {
              value: 3,
              message: "Title must be at least 3 characters"
            }
          }}
        />

        <FormSwitch
          label={"Status"}
          control={control}
          errors={errors}
          id="status"
        />

      </SimpleGrid>

    </FormDrawer>
  )
}

export default QualificationForm