import React from 'react'
import { useForm } from 'react-hook-form'
import FormDrawer from '../../../../components/Forms/FormDrawer'
import { Box, SimpleGrid } from '@chakra-ui/react'
import FormInput from '../../../../components/Forms/FormInput'
import FormTextarea from '../../../../components/Forms/FormTextarea'
import FormSwitch from '../../../../components/Forms/FormSwitch'
import { useCreateEmailTemplate, useUpdateEmailTemplate } from '../../../../config/query/emailTemplatesQuery'
import { useQueryClient } from '@tanstack/react-query'
import RichTextEditor from '../../../../components/Forms/RichTextEditor'

const TemplateForm = ({ disclosure, data }) => {
  const queryClient = useQueryClient()
  const createEmailTemplateQuery = useCreateEmailTemplate()
  const updateEmailTemplateQuery = useUpdateEmailTemplate(data?.id)

  const {
    handleSubmit,
    control,
    setValue,
    reset: resetForm,
    getValues: getFormValues,
    formState: { errors },
  } = useForm({
    values: {
      ...data,
      status: (data?.id && data?.status === 2) ? false : true,
    },
  })

  const onSubmit = (values) => {
    let formMutate = data?.id ? updateEmailTemplateQuery.mutateAsync : createEmailTemplateQuery.mutateAsync

    formMutate({
      ...values,
      status: values.status ? 1 : 2,
    })
      .then((response) => {
        resetForm(Object.keys(getFormValues()).map((item) => ({ [item]: "" })))
        queryClient.invalidateQueries(["emailTemplates"])
        disclosure.onClose()
      })
  }

  return (
    <FormDrawer
      title={data?.id ? "Edit Email Template" : "Create Email Template"}
      disclosure={disclosure}
      isSubmitting={createEmailTemplateQuery.isLoading || updateEmailTemplateQuery.isLoading}
      onSubmit={handleSubmit(onSubmit)}
    >
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>

        <FormInput
          label={"Name"}
          control={control}
          errors={errors}
          id="name"
          required={true}
          placeholder="Enter template name"
          rules={{
            minLength: {
              value: 3,
              message: "Name must be at least 3 characters"
            }
          }}
        />

        <FormSwitch
          label={"Status"}
          control={control}
          errors={errors}
          id="status"
        />

      </SimpleGrid>


      <Box mt={5}>
        <RichTextEditor
          control={control}
          errors={errors}
          id="htmlTemplate"
          required={true}
          label="HTML Template"
          rules={{
            required: "Content is required"
          }}
          placeholder='Enter HTML Template..'
        />
      </Box>
    </FormDrawer>
  )
}

export default TemplateForm