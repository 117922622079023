import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { CONTENT_TYPE } from "../constants";
import { appendQueryParams } from "../helpers/queryHelper";;

export const useQuestions = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["quizs-questions", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.EXAM_QUESTIONS.base}?${appendQueryParams({ params })}`,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useQuestion = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["quiz-question", id],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.EXAM_QUESTIONS.base}/${id}`,
                token,
                toastError: true
            })
            return data
        },
    })
}

export const useAutoSelectQuestions = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (query) => {
            const { data } = await Get({
                path: `${API_CONSTANTS.EXAM_QUESTIONS.autoSelect}?${appendQueryParams(query)}`,
                token,
                toastSuccess: true,
                toastError: true,
            })
            return data
        }
    })
}

export const useQuestionOptions = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["quiz-question-options", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.EXAM_QUESTIONS.getOptions}?${appendQueryParams({ params })}`,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useQuestionFacet = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["quiz-question-facet"],
        queryFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.EXAM_QUESTIONS.getFacet,
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useCreateQuestion = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Post({
                path: API_CONSTANTS.EXAM_QUESTIONS.base,
                token,
                body,
                toastSuccess: true,
                toastError: true,
            })
            return data
        },
    })
}

export const useUpdateQuestion = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Put({
                path: API_CONSTANTS.EXAM_QUESTIONS.base,
                token,
                body,
                toastSuccess: true,
                toastError: true,
            })
            return data
        },
    })
}

export const useDeleteQuestion = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.EXAM_QUESTIONS.base}/${id}`,
                token,
                toastSuccess: true,
                toastError: true,
            })
            return data
        },
    })
}

export const useReadQuestionFile = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (body) => {
            const { data } = await Post({
                path: API_CONSTANTS.EXAM_QUESTIONS.readFile,
                token,
                body,
                contentType: CONTENT_TYPE.FORM_DATA,
                toastSuccess: true,
                toastError: true,
            })
            return data
        },
    })
}

export const useDownloadQuestionFile = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.EXAM_QUESTIONS.downloadFile,
                token,
                toastSuccess: true,
                toastError: true,
            })
            return data
        },
    })
}