import APP_ICONS from "./icons"

const binSubsiderOptions = [
    {
        icon: APP_ICONS.EXAMS_LIST,
        title: "Bin",
        link: "/admin/bin"
    }
]

export default binSubsiderOptions