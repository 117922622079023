export const CONTENT_TYPE = {
    JSON: "application/json",
    FORM_DATA: "multipart/form-data",
}
export const DEFAULT_DATETIME_FORMAT = "DD/MMM/YYYY hh:mm A"
export const DEFAULT_DATE_FORMAT = "DD/MMM/YYYY"
export const DEFAULT_TIME_FORMAT = "hh:mm A"

export const DEFAULT_HIDDEN_SIDEBAR_PAGES = [
    "/admin/dashboard",
    "/admin/media",
    "/admin/chats",
    "/admin/chats/:chatId"
]

export const DEFAULT_BOX_BG_ROUTES = [
    "/admin/dashboard",
]

export const MILISECONDS = {
    SECOND: 1000,
    MINUTE: 1000 * 60,
    HOUR: 1000 * 60 * 60,
    MAX: 1000 * 60 * 60 * 24 * 7
}