import { Box, Flex, Grid, GridItem, Heading, Icon, Image, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../components/BasicUI/Buttons/PrimaryButton'
import ChatList from "./ChatList"
import ChatForm from "./ChatForm"
import APP_ICONS from '../../../config/constants/icons'
import ChatBox from './ChatBox'
import { Outlet, useParams } from 'react-router'
import NoResults from '../../../components/SVGComponents/NoResults'

const Chats = () => {
  const { chatId } = useParams()
  return (
    <Flex w="full">
      <Box maxW="400px" w="full">
        <ChatList />
      </Box>

      {chatId ? (
        <Box
          w="full"
          ml={5}
        >
          {/* <ChatBox /> */}
          <Outlet />
        </Box>
      ) : (
        <Flex
          w="full"
          ml={5}
          justify={"center"}
          align={"center"}
          maxW={"550px"}
          m="auto"
        >
          <NoResults />
        </Flex>
      )}

    </Flex>
  )
}

export default Chats