import { Box, Card, CardBody, CardHeader, Flex, SimpleGrid, Skeleton, VStack, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { colorKeys, getColor } from '../../config/constants/appColors'

const VariantListSkeleton = ({ isLoading }) => {

    const { colorMode } = useColorMode()

    if (!isLoading) return null
    return (
        <SimpleGrid spacing={5} columns={{ base: 1, md: 3, lg: 4 }}>
            {new Array(5).fill(null).map((item, index) => (
                <Card key={index}>
                    <CardHeader display={"flex"} py="1" alignItems={"center"} h="48px" bg={getColor(colorKeys.lightestGray, colorMode)}>
                        <Flex justify="space-between" align={"center"}>
                            <Skeleton height="20px" width="100px" />
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <VStack spacing="2" align="stretch">
                            <VStack align="stretch" spacing={1}>
                                <Skeleton height="20px" width="100px" />
                                <Skeleton height="20px" width="250px" />
                            </VStack>


                            <VStack align="stretch" spacing={1}>
                                <Skeleton height="20px" width="100px" />
                                <Skeleton height="20px" width="150px" />
                            </VStack>


                        </VStack>
                    </CardBody>
                </Card>
            ))}
        </SimpleGrid>
    )
}

export default VariantListSkeleton