import React from 'react'
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error";
import Dashboard from "../../views/admin/Dashboard"
import AdminLayout from '../../components/BasicUI/AdminLayout';
import Components from '../../views/admin/Components';
import ClientLayout from '../../components/BasicUI/ClientLayout';
import Login from '../../views/auth/Login';
import Signup from '../../views/auth/Signup';
import AuthLayout from '../../components/BasicUI/AuthLayout';
import OperationLayout from '../../components/BasicUI/OperationLayout';
import Quizzes from '../../views/admin/Quizzes';
import QuizCategories from '../../views/admin/Quizzes/QuizCategories';
import QuizArchive from '../../views/admin/Quizzes/QuizArchive';
import Courses from '../../views/admin/Syllabus/Courses';
import Cohorts from '../../views/admin/Syllabus/Cohorts';
import Qualifications from '../../views/admin/Syllabus/Qualifications';
import Lectures from '../../views/admin/Lectures';
import Meetings from '../../views/admin/Lectures/Meetings';
import Users from '../../views/admin/Users';
import Roles from '../../views/admin/Users/Roles';
import Templates from "../../views/admin/Emails/Templates"
import EmailLogs from "../../views/admin/Emails/EmailLogs"
import Reports from '../../views/admin/Reports';
import Questions from '../../views/admin/Quizzes/Questions';
import Results from '../../views/admin/Quizzes/Results';
import Chapters from '../../views/admin/Syllabus';
import Bin from '../../views/admin/RecycleBin';
import Settings from '../../views/admin/Settings';
import Profile from '../../views/admin/Settings/Profile';
import Security from '../../views/admin/Settings/Security';
import Integrations from '../../views/admin/Settings/Integrations';
import Dump from '../../views/admin/Dump';
import quizSubSiderOptions from "../../config/constants/quizSubsiderOptions"
import syllabusSubsiderOptions from '../constants/syllabusSubsiderOptions';
import lecturesSubsiderOptions from '../constants/lecturesSubsiderOptions';
import usersSubsiderOptions from '../constants/usersSubsiderOptions';
import emailsSubsiderOptions from '../constants/emailsSubsiderOptions';
import reportsSubsiderOptions from '../constants/reportsSubsiderOptions';
import binSubsiderOptions from '../constants/binSubsiderOptions';
import settingsSubsiderOptions from '../constants/settingsSubsiderOptions';
import Notes from '../../views/admin/Syllabus/Notes';
import Chats from '../../views/admin/Chats';
import ChatBox from '../../views/admin/Chats/ChatBox';
import Media from '../../views/admin/Media';
import QuizDetails from '../../views/admin/Quizzes/QuizDetails';
import CohortDetails from '../../views/admin/Syllabus/Cohorts/CohortDetails';
import CohortVariantsTypes from '../../views/admin/Syllabus/CohrotVariantTypes';

const router = createBrowserRouter([
    {
        path: "/",
        element: <ClientLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Navigate to={"/auth/login"} replace />
            }
        ]
    },
    {
        path: "/auth",
        element: <AuthLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Navigate to={"/auth/login"} replace />
            },
            {
                path: "login",
                element: <Login />
            },
            {
                path: "signup",
                element: <Signup />
            }
        ]
    },
    {
        path: "/dump",
        element: <Dump />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/admin",
        element: <AdminLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Navigate to={"/admin/dashboard"} replace />
            },
            {
                path: "dashboard",
                element: <Dashboard />,
            },
            {
                path: "components",
                element: <Components />
            },
            {
                path: "exams",
                element: <OperationLayout
                    title={"Exams"}
                    sidebarOptions={quizSubSiderOptions}
                />,
                children: [
                    {
                        index: true,
                        element: <Quizzes />
                    },
                    {
                        path: "quizzes/:quizId",
                        element: <QuizDetails />
                    },
                    {
                        path: "quizzes/questions",
                        element: <Questions />
                    },
                    {
                        path: "results",
                        element: <Results />
                    },
                    {
                        path: "categories",
                        element: <QuizCategories />
                    },
                    {
                        path: "archive",
                        element: <QuizArchive />
                    }
                ]
            },
            {
                path: "syllabus",
                element: <OperationLayout
                    title={"Syllabus"}
                    sidebarOptions={syllabusSubsiderOptions}
                />,
                children: [
                    {
                        index: true,
                        element: <Cohorts />
                    },
                    {
                        path: "cohorts/:cohortId",
                        element: <CohortDetails />
                    },
                    {
                        path: "cohort-variant-types",
                        element: <CohortVariantsTypes />
                    },
                    {
                        path: "chapters",
                        element: <Chapters />
                    },
                    {
                        path: "courses",
                        element: <Courses />
                    },
                    {
                        path: "qualifications",
                        element: <Qualifications />
                    },
                    {
                        path: "notes",
                        element: <Notes />
                    },
                ]
            },
            {
                path: "lectures",
                element: <OperationLayout
                    title={"Lectures"}
                    sidebarOptions={lecturesSubsiderOptions}
                />,
                children: [
                    {
                        index: true,
                        element: <Lectures />
                    },
                    {
                        path: "meetings",
                        element: <Meetings />
                    }
                ]
            },
            {
                path: "users",
                element: <OperationLayout
                    title="Users"
                    sidebarOptions={usersSubsiderOptions}
                />,
                children: [
                    {
                        index: true,
                        element: <Users />
                    },
                    {
                        path: "roles",
                        element: <Roles />
                    },
                ]
            },
            {
                path: "chats",
                element: <Chats />,
                children: [
                    {
                        path: ":chatId",
                        element: <ChatBox />
                    }
                ]
            },
            {
                path: "media",
                element: <Media />
            },
            {
                path: "emails",
                element: <OperationLayout
                    title="Emails"
                    sidebarOptions={emailsSubsiderOptions}
                />,
                children: [
                    {
                        index: true,
                        element: <Templates />
                    },
                    {
                        path: "logs",
                        element: <EmailLogs />
                    },
                ]
            },
            {
                path: "reports",
                element: <OperationLayout
                    title="Reports"
                    sidebarOptions={reportsSubsiderOptions}
                />,
                children: [
                    {
                        index: true,
                        element: <Reports />
                    }
                ]
            },
            {
                path: "bin",
                element: <OperationLayout
                    title="Bin"
                    sidebarOptions={binSubsiderOptions}
                />,
                children: [
                    {
                        index: true,
                        element: <Bin />
                    }
                ]
            },
            {
                path: "settings",
                element: <OperationLayout
                    title="Settings"
                    sidebarOptions={settingsSubsiderOptions}
                />,
                children: [
                    {
                        index: true,
                        element: <Profile />
                    },
                    {
                        path: "configurations",
                        element: <Settings />
                    },
                    {
                        path: "security",
                        element: <Security />
                    },
                    {
                        path: "integrations",
                        element: <Integrations />
                    },
                ]
            },
        ]
    },

]);

const AppRouter = () => <RouterProvider router={router} />

export default AppRouter 