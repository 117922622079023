import React from 'react'
import { Box, VStack, SimpleGrid, Wrap, Text, HStack, Heading, Icon, Flex, Button } from '@chakra-ui/react'
import LabelValuePair from '../../../../../components/BasicUI/LabelValuePair'
import { useParams } from 'react-router'
import { useQuiz } from '../../../../../config/query/quizQuery'
import AvatarStack from '../../../../../components/BasicUI/DataBoxes/AvatarStack'
import StatusBadge from '../../../../../components/BasicUI/Badges/StatusBadge'
import APP_ICONS from '../../../../../config/constants/icons'

const QuizInformation = () => {
    const { quizId } = useParams()
    const quizQuery = useQuiz(quizId)

    const onEdit = () => {

    }

    return (
        <Box w="full">
            <Flex justify="space-between" align={"center"}>
                <Box>
                    <Heading as="h2" fontSize={"22px"} fontWeight="bold">Quiz Details</Heading>
                </Box>
                <HStack spacing={5}>
                    <Button onClick={onEdit} rightIcon={<Icon boxSize="6" as={APP_ICONS.EDIT} />}>Edit Quiz</Button>
                </HStack>
            </Flex>
            <VStack spacing={5} align="stretch" mt={5}>
                <SimpleGrid spacing={5} columns={{ base: 1, md: 4 }}>
                    <LabelValuePair label="Name" value={quizQuery.data?.name} />
                    <LabelValuePair label="Duration" value={quizQuery.data?.quizz?.duration} />
                    <LabelValuePair label="Max Attempts" value={quizQuery.data?.quizz?.maxAttempts} />
                    <LabelValuePair label="Passing Percentage" value={quizQuery.data?.quizz?.passingPercentage} />
                    <LabelValuePair label="Cohort Name" value={quizQuery.data?.cohortName} />
                    <LabelValuePair label="Chapter Name" value={quizQuery.data?.chapterName} />
                    <LabelValuePair label="Teachers"
                        value={quizQuery.data?.name}
                        valueComponent={quizQuery.data?.teachers?.length
                            ? <AvatarStack containerProps={{ justify: "start" }} data={quizQuery.data?.teachers} />
                            : <Text fontSize="14px">N/A</Text>}
                    />
                    <LabelValuePair label="Status"
                        value={quizQuery.data?.name}
                        valueComponent={<StatusBadge value={quizQuery.data?.status} />}
                    />
                </SimpleGrid>

            </VStack>
        </Box>

    )
}

export default QuizInformation

// "cohortName": "Malachi Parkss",
// "chapterName": "adac",
// "courseName": null,
// "courseId": null,
// "qualificationName": null,
// "qualificationId": null,
// "readStatus": "NotStarted",
// "cohortId": "642132282e725ca7e478653e",
// "chapterId": "6421f230ba647186a01e9425",
// "order": 0,
// "quizz": {
//     "duration": 50,
//     "maxAttempts": 1,
//     "description": null,
//     "passingPercentage": 50,
//     "questionIds": []
// },
// "type": "Quizz",
// "teacherIds": [
//     "649b5e964171f6a46244d156"
// ],
// "teachers": [
//     {
//         "id": "649b5e964171f6a46244d156",
//         "name": "Sir Ashar",
//         "profilePicture": null,
//         "dateTime": "2023-07-19T13:22:50.577Z"
//     }
// ],
// "id": "64b7e3aac52ecbf970376e2c",
// "name": "quiz 1",
// "status": 1,
// "createdBy": {
//     "id": "6378b683b76f56dfffde82e2",
//     "name": "Shehzad Ahmed",
//     "profilePicture": "https://evolvlmsdev.blob.core.windows.net/public/artt/profilepicture_64b292f5d5190aa4b6b42251.png",
//     "dateTime": "2023-07-19T13:22:50.588Z"
// },