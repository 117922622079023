import { Box, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import TemplateList from "./TemplateList"
import TemplateForm from "./TemplateForm"
import APP_ICONS from '../../../../config/constants/icons'

const Templates = () => {
  const formDisclosure = useDisclosure()
  const [selectedTemplate, setSelectedTemplate] = React.useState(null)

  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">Email Templates</Heading>
        <PrimaryButton onClick={() => {
          formDisclosure.onOpen()
          setSelectedTemplate(null)
        }}
          leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
        >
          Create New Template
        </PrimaryButton>
      </Flex>
      <TemplateList
        onEditModal={(data) => {
          setSelectedTemplate(data)
          formDisclosure.onOpen()
        }}
      />
      <TemplateForm
        disclosure={formDisclosure}
        data={selectedTemplate}
      />
    </Box>
  )
}

export default Templates