import { Box, chakra, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, VStack, } from '@chakra-ui/react'
import React from 'react'
import { useForm } from 'react-hook-form'
import FormButton from '../../../../components/Forms/FormButton'
import FormInput from '../../../../components/Forms/FormInput'
import FormPhoneInput from '../../../../components/Forms/FormPhoneInput'
import FormTextarea from '../../../../components/Forms/FormTextarea'
import { EMAIL_REGEX, PHONE_REGEX } from '../../../../config/constants/regexConstants'
import EmailSettings from './EmailSettings'
import VimeoSettings from './VimeoSettings'
import ZoomSettings from './ZoomSettings'
import BlobStorageSettings from './BlobStorageSettings'

const Integrations = () => {
    return (
        <Tabs isLazy={true}>
            <TabList mb='1em'>
                <Tab>Email</Tab>
                <Tab>Zoom</Tab>
                <Tab>Vimeo</Tab>
                <Tab>Azure Blob Storage</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <EmailSettings />
                </TabPanel>
                <TabPanel>
                    <ZoomSettings />
                </TabPanel>
                <TabPanel>
                    <VimeoSettings />
                </TabPanel>
                <TabPanel>
                    <BlobStorageSettings />
                </TabPanel>
            </TabPanels>
        </Tabs>

    )
}

export default Integrations