import {
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Delete, Get, Post, Put } from "../api";
import API_CONSTANTS from "../constants/api";
import { prepareData } from "../helpers/apiHelper";
import { appendQueryParams } from "../helpers/queryHelper";;

export const useQuizResults = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["quiz-results", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.EXAM_questionsQueryRESULTS.base}?${appendQueryParams({ params })}`,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useQuizResult = (id) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["quiz-result", id],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.EXAM_RESULTS.base}/${id}`,
                token,
                toastError: true
            })
            return data
        },
    })
}

export const useQuizResultOptions = (params) => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["quiz-result-options", params],
        queryFn: async () => {
            const { data } = await Get({
                path: `${API_CONSTANTS.EXAM_RESULTS.getOptions}?${appendQueryParams({ params })}`,
                token,
                toastError: true
            })
            return data
        },
        keepPreviousData: true
    })
}

export const useQuizResultFacet = () => {
    const token = useSelector((state) => state.user?.token)
    return useQuery({
        queryKey: ["quizs-results-facet"],
        queryFn: async () => {
            const { data } = await Get({
                path: API_CONSTANTS.EXAM_RESULTS.getFacet,
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useDeleteQuizResult = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (id) => {
            const { data } = await Delete({
                path: `${API_CONSTANTS.EXAM_RESULTS.base}/${id}`,
                token,
                toastError: true,
            })
            return data
        },
    })
}

export const useCreateQuizResult = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (data) => {
            const { data: responseData } = await Post({
                path: API_CONSTANTS.EXAM_RESULTS.base,
                data: prepareData(data),
                token,
                toastError: true,
            })
            return responseData
        },
    })
}

export const useUpdateQuizResult = () => {
    const token = useSelector((state) => state.user?.token)
    return useMutation({
        mutationFn: async (data) => {
            const { data: responseData } = await Put({
                path: `${API_CONSTANTS.EXAM_RESULTS.base}/${data.id}`,
                data: prepareData(data),
                token,
                toastError: true,
            })
            return responseData
        },
    })
}