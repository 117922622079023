import { Box, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import PrimaryButton from '../../../../components/BasicUI/Buttons/PrimaryButton'
import NotesList from "./NotesList"
import NotesForm from "./NotesForm"
import APP_ICONS from '../../../../config/constants/icons'

const Notes = () => {
  const formDisclosure = useDisclosure()
  const [selectedNotes, setSelectedNotes] = React.useState(null)

  return (
    <Box>
      <Flex justify="space-between" align={"center"}>
        <Heading as="h2" fontSize={26} fontWeight="bold">Notes</Heading>
        <PrimaryButton
          onClick={() => {
            setSelectedNotes(null)
            formDisclosure.onOpen()
          }}
          leftIcon={<Icon boxSize={6} as={APP_ICONS.ADD} />}
        >
          Create New Notes
        </PrimaryButton>
      </Flex>
      <NotesList
        onEditModal={(data) => {
          setSelectedNotes(data)
          formDisclosure.onOpen()
        }}
      />
      <NotesForm disclosure={formDisclosure} data={selectedNotes} />
    </Box>
  )
}

export default Notes